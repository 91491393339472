.hero-search-blocks {

  > ul {
    @include list-unstyled;
    box-shadow: 5px 8.66px 9px 1px rgba(0, 0, 0, .05);

    @include breakpoint($tablet) {
      display: flex;
      margin: 0 auto;
      max-width: 68.8rem;
    }

    @include breakpoint($desktop) {
      max-width: 110.8rem;
    }

    > li {
      width: 100%;

      @include breakpoint($tablet) {
        width: 50%;
      }
    }
  }
}

.hero-search-block {
  $self: &;
  background-color: $white;
  margin-bottom: 0;
  padding: $spacing-unit--md $spacing-unit 0;
  text-align: left;

  &:after {
    clear: both;
    content: ' ';
    display: table;
  }

  @include breakpoint($tablet) {
    padding: $spacing-unit--mdl $spacing-unit--mdl 0;
  }

  @include breakpoint($desktop) {
    padding: $spacing-unit--l $spacing-unit--lml 0;
  }

  h2 {
    line-height: 1.1;
    margin: 0 0 $spacing-unit;

    @include breakpoint($tablet) {
      font-size: 2.2rem;
    }

    @include breakpoint($desktop) {
      font-size: 3rem;
    }
  }

  p {
    @include font-size(14, 20);
    margin: 0;

    @include breakpoint($tablet) {
      @include font-size(12, 18);
    }

    @include breakpoint($desktop) {
      @include font-size(15, 20);
    }
  }

  &__items {
    @include list-unstyled();

    > li {
      &:not(:last-child) {
        border-bottom: 1px solid $gray;
        margin-bottom: $spacing-unit;
      }
    }
  }

  &__description {
    display: flex;
    margin: $spacing-unit 0 $spacing-unit--l;

    p {
      flex: 1 1 auto;
      padding-right: $spacing-unit--md;
    }
  }

  &__cta {
    flex: 0 0 3rem;
    justify-self: flex-end;

    .button {
      align-items: center;
      display: flex;
      height: 3rem;
      justify-content: center;
      min-width: 0;
      padding: 0;
      width: 3rem;
    }
  }

  &__links {
    @include list-inline();
    @include font-size(13, 15);
    margin-top: $spacing-unit;

    @include breakpoint($tablet) {
      @include font-size(12, 14);
    }

    @include breakpoint($desktop) {
      @include font-size(14, 16);
    }

    > li {
      display: inline-flex;
      margin: $spacing-unit--sm 0;

      a {
        padding: 0 $spacing-unit--sm;

        @include breakpoint($tablet) {
          white-space: nowrap;
        }

        @include breakpoint($desktop) {
          padding: 0 $spacing-unit;
          white-space: normal;
        }
      }

      &:not(:last-child) {
        border-right: 1px solid rgba($blue-dark, .5);

        > a {
          display: block;
        }
      }
    }

    a {
      color: $blue-dark;
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  &__search {
    margin: 0 -#{spacing-unit--half};

    @include breakpoint($tablet) {
      margin: 0 -#{$spacing-unit};
    }

    label {
      opacity: 0;
      margin: 0 0 $spacing-unit--half;
      transform: translateY(10px);
      transition: all .2s ease-in;
      will-change: opacity, transform;

      &.active {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .search-input {
      height: 4.6rem;

      input,
      ::placeholder {
        color: $blue-dark;
        font-size: 1.4rem;
        opacity: 1;
      }

      input {
        border-color: $white;
        padding: 0 $spacing-unit;
      }

      input,
      button {
        height: 100%;
      }
    }
  }

  &--search {
    background-color: $blue-light-alt;
    padding: $spacing-unit $spacing-unit $spacing-unit--l;

    @include breakpoint($tablet) {
      padding: $spacing-unit--mdl;
    }

    @include breakpoint($desktop) {
      padding: $spacing-unit--l $spacing-unit--lml $spacing-unit;
    }

    h2,
    .hero-search-block__description {
      display: none;

      @include breakpoint($tablet) {
        display: block;
      }
    }

    #{$self} {
      &__description {
        margin-bottom: $spacing-unit;
      }
    }
  }
}
