.dropdown {
  $self: &;
  position: relative;

  &:not(:last-of-type) {
    margin: 0 0 $spacing-unit;
  }

  ::-webkit-scrollbar {
    width: .5rem;
  }

  ::-webkit-scrollbar-track {
    background-color: $white;
    border-radius: 0;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $blue-dark;
    border-radius: 0;
    height: 50%;
  }

  ::-webkit-scrollbar-thumb:window-inactive {
    background-color: rgba($blue-dark, .2);
  }

  &__toggle {
    background-color: $white;
    border: 1px solid $white;
    box-shadow: none;
    color: $blue-dark;
    display: block;
    font-size: 1.6rem;
    line-height: 1;
    outline-color: transparent;
    padding: 1.2rem $spacing-unit;
    text-align: left;
    transform: rotate(0deg);
    width: 100%;

    &:focus {
      outline: 2px solid $blue;
    }

    &__inner {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: space-between;
      width: 100%;
    }

    .text {
      display: block;
      flex: 1;
    }

    .icon {
      transition: all $linkTransition;
    }
  }

  input[type="text"].dropdown__typeahead {
    border: 1px solid $gray-separator;
    width: 100%;

    &:focus {
      outline: 2px solid $blue;
    }
  }

  .dropdown-menu {
    box-shadow: 2.121px 2.121px 8px 0px rgba(64, 64, 64, .09);
    left: 0;
    max-height: 0;
    max-width: 100%;
    min-width: 28rem;
    opacity: 0;
    overflow-y: scroll;
    position: absolute;
    top: calc(100% + 2px);
    transition: opacity .1s, max-height .18s cubic-bezier(1, 0, 0, 1.3), visibility 0s .2s;
    visibility: hidden;
    width: 100%;
    z-index: index($globalIndex, dropdownMenu);

    &.overflow {
      min-width: 14rem;
    }

    &__inner {
      background-color: $white;
      padding: $spacing-unit--md $spacing-unit;
    }
  }

  &[disabled] {
    opacity: .6;
    pointer-events: none;
  }

  &--open {
    #{$self} {
      &__toggle {
        .icon {
          transform: rotate(180deg);
        }
      }
    }

    .dropdown-menu {
      opacity: 1;
      max-height: 40rem;
      transition: opacity .1s, max-height .18s cubic-bezier(1, 0, 0, 1.3);
      visibility: visible;
    }
  }

  &--sm {
    .dropdown-menu {
      min-width: 0;

      &__inner {
        padding: $spacing-unit $spacing-unit--xs;
      }
    }
  }
}
