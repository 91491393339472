.main-content {
  $self: &;
  $sidebarW: 325px;
  margin: auto;

  @mixin sidebarTablet {
    @include breakpoint($tablet $desktop--max) {
      @content;
    }
  }

  &__with-sidebar {
    @include breakpoint($desktop) {
      display: grid;
      grid-gap: 0 $spacing-unit--xl;
      grid-template-columns: [content] 3fr [sidebar] 1fr;
      grid-template-columns: [content] minmax(auto, 860px) [sidebar] minmax(310px, 1fr);
      -ms-grid-columns: 3fr 1fr;
      grid-template-rows: auto 1fr;

      .content {
        min-height: 0;
        min-width: 0;
        padding-left: $spacing-unit--xl;
      }

      .sidebar {
        margin-left: auto;
        max-width: 400px;
        width: 100%;
      }
    }
  }

  .content {
    grid-row: 1/3;
    margin-bottom: $spacing-unit--l;
    margin-right: $spacing-unit--l;

    @supports (grid-gap: 3rem) {
      margin-right: unset;
    }
  }

  .sidebar {
    grid-column: sidebar;

    &__top {
      grid-row-start: 1;
      grid-column-start: 2;
    }
    &__bottom {
      grid-row-start: 2;
      grid-column-start: 2;
    }

    &__bottom {
      @include sidebarTablet() {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
      }

      > * {
        @include sidebarTablet() {
          flex: 1 0 100%;
        }
      }

      // On tablet, the spotlight cards are in a 2 col grid
      .spotlight-card {
        @include sidebarTablet() {
          display: block;
          flex: 1 0 40%;
          margin: 1%;
          width: 40%;

          &:nth-child(even) {
            margin-right: 0;
          }

          &:nth-child(odd) {
            margin-left: 0;
          }
        }

        &__inner {
          @include sidebarTablet() {
            height: 100%;
          }
        }

        &__content {
          @include sidebarTablet() {
            display: flex;
            flex-flow: column nowrap;
            height: 100%;
            justify-content: space-between;
          }
        }

        &__footer {
          @include sidebarTablet() {
            margin-top: 0;
          }
        }
      }
    }
  }
}

.main-content--listing {
  $self: &;
  $sidebarW: 337px;
  $largeGap: 6rem;

  @include breakpoint($desktop) {
    display: grid;
    grid-gap: 0 $spacing-unit--l;
    grid-template-columns: [sidebar] minmax(25%, $sidebarW) [content] calc(100% - #{$sidebarW} - #{$spacing-unit--l});
    -ms-grid-columns: 35% 65%;
    grid-template-rows: auto 1fr;
  }

  @include breakpoint($large-desktop) {
    grid-gap: 0 $largeGap;
    grid-template-columns: [sidebar] minmax(25%, $sidebarW) [content] calc(100% - #{$sidebarW} - #{$largeGap});
    grid-template-columns: [sidebar] 25% [content] calc(75% - #{$largeGap});
  }

  .sidebar {
    grid-column: sidebar;
    grid-column-start: 1;
    grid-row: 1/3;
    margin-right: $spacing-unit--l;

    @include breakpoint($large-desktop) {
      margin-right: $largeGap;
    }

    @supports (grid-gap: 3rem) {
      margin-right: unset;
    }
  }

  .hero {
    grid-column: content;
    grid-column-start: 2;
    grid-row: 1/1;
  }

  .content {
    grid-column: content;
    grid-column-start: 2;
    grid-row: 2/3;
    max-width: 800px;
  }
}

