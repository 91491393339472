.mobile-nav {
  $self: &;
  overflow: hidden;

  &__search-placeholder {
    height: 0;
    transition: all .4s ease;
  }

  &__wrapper {
    height: 100%;
    overflow: hidden;
    width: 100%;
  }

  &__container {
    display: flex;
    height: 100%;
    position: relative;
    transition: transform .4s;
    will-change: transform;
  }

  &__page {
    background-color: $white;
    flex-shrink: 0;
    height: 100%;
    overflow-y: auto;
    transition: visibility 0s .4s;
    visibility: hidden;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    will-change: visibility;

    &--active {
      transition: none;
      visibility: visible;

      #{$self} {
        &__main {
          opacity: 1;
          transform: translate3d(0, 0, 0);
          transition: all .2s .5s;
        }
      }
    }
  }

  &__double-page {
    display: flex;
    flex-shrink: 0;
    height: 100%;
    position: relative;
    width: 200%;

    > div {
      width: 50%;
    }
  }

  &__separator {
    border-color: $blue-menuSep;

    &--dark {
      border-color: $blue-darker;
    }
  }

  &__back {
    padding: $spacing-unit 0;

    button {
      appearance: none;
      background: 0;
      border: 0;
      cursor: pointer;
      font-size: 1.2rem;
      font-weight: 500;
      text-decoration: none;

      .icon {
        margin-right: $spacing-unit--half;
      }
    }

    &--light {
      background-color: $white;

      button {
        color: $blue-medium;

        &:focus {
          color: $blue-dark;
        }
      }
    }

    &--dark {
      background-color: $blue-darker;

      button {
        color: $white;

        &:focus {
          color: $blue;
        }
      }
    }

    &--large {
      button {
        font-size: 1.6rem;
      }
    }
  }

  &__heading {
    @include font-size(26, 27);
    display: block;
    margin: 3rem 0;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }

    .fonts-loaded & {
      font-family: $font-heading;
      font-weight: 300;
    }
  }

  &__body {
    background-color: $white;
  }

  &__main {
    opacity: 0;
    padding: $spacing-unit 0;
    transform: translate3d(-20px, 0, 0);
    transition: all 0s .4s;
    will-change: opacity, transform;
  }

  &__logo {
    padding-top: $spacing-unit;

    img {
      max-width: 200px;
    }
  }

  &__main-inner {
    margin: -#{$spacing-unit} auto;
  }

  &__footer {
    background-color: $blue-darker;
    padding: $spacing-unit 0;
  }

  &__footer-inner {
    margin: -#{$spacing-unit} auto;
  }

  &__social {
    padding: $spacing-unit 0 $spacing-unit--md;
  }
}
