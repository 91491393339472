.listing-item {
  $self: &;

  p {
    font-size: 1.6rem;
    font-weight: 200;

    @include breakpoint($desktop) {
      font-size: 1.8rem;
    }

    &.meta {
      margin: 0;
    }

    &.text-center {
      text-align: center;
    }
  }

  a {
    text-decoration: none;
  }

  &__eyelash {
    color: $blue-medium;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: .25rem;
    margin: 0 0 $spacing-unit--md;
    text-transform: uppercase;
  }

  &__title {
    font-size: 2rem;
    margin: $spacing-unit--md 0 $spacing-unit;

    @include breakpoint($desktop) {
      font-size: 2.2rem;
    }
  }

  &__eyelash,
  &__title {
    a {
      color: $blue-medium;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  &__blocks {
    overflow: hidden;
    width: 100%;
  }

  &__block {
    :first-child {
      margin-top: 0;
    }

    :last-child {
      margin-bottom: 0;
    }
  }

  &__tags {
    @include list-inline();
  }

  &__tag {
    @include font-size(16, 25);
    color: $blue-gray;
    font-weight: 300;

    > a {
      color: $blue-gray;
      text-decoration: none;

      &:focus,
      &:hover {
        color: $blue-gray;
        text-decoration: underline;
      }
    }
  }

  &__note {
    font-style: italic;

    em {
      font-style: normal;
    }
  }

  .hidden-desktop {
    @include breakpoint($desktop) {
      display: none;
    }
  }

  .hidden-tablet-down {
    @include breakpoint(0 $tablet--max) {
      display: none;
    }
  }

  .hidden-tablet-up {
    @include breakpoint($tablet) {
      display: none;
    }
  }

  .hidden-mobile {
    display: none;

    @include breakpoint($desktop) {
      display: inherit;
    }
  }

  &--event {
    $borderColor:rgba(38, 57, 79, .102);
    border: 1px solid $borderColor;
    padding: 0 $spacing-unit--md;

    @include breakpoint($tablet) {
      padding: 0 $spacing-unit--l;
    }

    @include breakpoint($desktop) {
      padding: 0 $spacing-unit--mdl;
    }

    .profile-block {
      margin-right: 0;
      max-width: none;

      &__image {
        max-width: 82px;
      }
    }

    p {
      font-size: 1.6rem;

      @include breakpoint($desktop) {
        font-size: 1.4rem;
      }

      @include breakpoint($large-desktop) {
        font-size: 1.8rem;
      }
    }

    #{$self} {
      &__title {
        @at-root .fonts-loaded & {
          font-family: $font-heading;
        }
      }
      &__location {
        font-weight: 300;
      }
      &__blocks {
        .row {
          *[class^='col-']:not(:only-child) {
            margin: .7rem 0;
          }

          *[class^='col-']:not(:last-child) {
            border-right: 1px solid $borderColor;
          }

          &:not(:last-child) {
            border-bottom: 1px solid $borderColor;
          }
        }
      }
      &__block {
        padding: $spacing-unit--md 0;

        @include breakpoint($tablet) {
          padding: $spacing-unit--l 0;
        }

        @include breakpoint($desktop) {
          padding: $spacing-unit--mdl 0;
        }
      }
    }
  }

  &--program {
    border-bottom: 1px solid $gray-alt;
    padding: $spacing-unit 0;

    @include breakpoint($tablet) {
      padding: $spacing-unit--md 0;
    }

    #{$self} {
      &__eyelash {
        margin-bottom: $spacing-unit--sm;

        @include breakpoint($desktop) {
          margin-bottom: $spacing-unit--ss;
        }

        span {
          margin-right: $spacing-unit--sm;
        }
      }

      &__title {
        margin-bottom: $spacing-unit--half;
        margin-top: $spacing-unit--sm;

        @at-root .fonts-loaded & {
          font-family: $font-heading;
        }

        @include breakpoint($desktop) {
          margin-top: $spacing-unit--ss;
        }
      }

      &__description {
        margin: $spacing-unit--half 0 0;
      }

      &__ctas {
        @include list-inline();
        flex-wrap: wrap;
        margin-top: $spacing-unit;

        @include breakpoint($tablet) {
          margin-top: $spacing-unit--md;
        }

        > li {
          line-height: 1;
          margin-bottom: $spacing-unit--half;

          &:not(:last-child) {
            border-right: 1px solid $gray;
            margin-right: $spacing-unit;
            padding-right: $spacing-unit;
          }
        }
      }

      &__cta {
        &:after {
          padding-left: $spacing-unit;
        }
      }
    }

    &.featured {
      background-color: $white;
      border-bottom: 7px solid $yellow-light;
      padding: $spacing-unit--l $spacing-unit--mdl $spacing-unit--mdl;

      @include breakpoint($desktop) {
        padding: $spacing-unit--l $spacing-unit--l $spacing-unit--mdl;
      }

      #{$self} {
        &__ctas {
          > li {
            &:not(:last-child) {
              border-right-color: $gray-dark;
            }
          }
        }
      }
    }
  }

  &--condition {
    border-bottom: 1px solid $gray-alt;
    padding: $spacing-unit 0;

    @include breakpoint($tablet) {
      padding: $spacing-unit--md 0;
    }

    #{$self} {
      &__eyelash {
        margin-bottom: $spacing-unit--sm;

        @include breakpoint($desktop) {
          margin-bottom: $spacing-unit;
        }

        span {
          margin-right: $spacing-unit--sm;
        }
      }

      &__title {
        margin-bottom: $spacing-unit--half;
        margin-top: $spacing-unit--sm;

        @include breakpoint($desktop) {
          margin-top: $spacing-unit;
        }
      }

      &__description {
        margin: $spacing-unit--half 0 $spacing-unit;
      }
    }
  }

  &--media-coverage {
    border-bottom: 1px solid $gray-alt;
    padding: $spacing-unit 0 $spacing-unit--md;

    @include breakpoint($desktop) {
      padding: $spacing-unit 0 $spacing-unit--mdl;
    }

    #{$self} {
      &__eyelash {
        color: $blue-medium;
        margin: 0 0 $spacing-unit--ss;

        @include breakpoint($desktop) {
          margin: 0 0 $spacing-unit;
        }

        span {
          margin-right: $spacing-unit--half;

          &.date {
            color: $blue-dark;
          }
        }
      }

      &__title {
        align-items: center;
        display: flex;
        font-size: 1.7rem;
        margin: $spacing-unit--ss 0 $spacing-unit;

        @at-root .fonts-loaded & {
          font-family: $font-heading;
        }

        .icon {
          font-size: 1.4rem;
          margin-left: $spacing-unit--sm;
        }

        @include breakpoint($desktop) {
          font-size: 2.2rem;
          margin: $spacing-unit 0 $spacing-unit;
        }
      }

      &__description {
        margin: $spacing-unit 0;
      }
    }
  }

  &--page {
    border-bottom: 1px solid $gray-alt;
    padding: $spacing-unit 0;

    @include breakpoint($tablet) {
      padding: $spacing-unit--md 0;
    }


    #{$self} {
      &__title {
        margin: 0 0 $spacing-unit;

        @include breakpoint($desktop) {
          margin: 0 0 $spacing-unit--md;
        }
      }

      &__description {
        margin: $spacing-unit 0;

        @include breakpoint($desktop) {
          margin: $spacing-unit--md 0 $spacing-unit;
        }
      }
    }
  }

  &--profile {
    @extend %clearfix;
    border-bottom: 1px solid $gray-alt;
    margin: $spacing-unit 0 0;
    padding: $spacing-unit 0 $spacing-unit--l;

    $imageWMobile: 90px;
    $contentSpacingMobile: 24px;

    $imageWDesktop: 120px;
    $contentSpacingDesktop: 50px;

    @include breakpoint($tablet) {
      padding-bottom: $spacing-unit--xl;
    }

    @include breakpoint($desktop) {
      margin: $spacing-unit--ss 0 0;
      padding: $spacing-unit--l 0 $spacing-unit--ll;
    }

    @mixin tabletContent() {
      float: right;
      margin-left: $contentSpacingMobile;
      width: calc(100% - #{$contentSpacingMobile + $imageWMobile});
    }

    @mixin desktopContent() {
      float: right;
      margin-left: $contentSpacingDesktop;
      width: calc(100% - #{$contentSpacingDesktop + $imageWDesktop});
    }

    #{$self} {
      &__image {
        width: $imageWMobile;

        @include breakpoint($tablet) {
          float: left;
        }

        @include breakpoint($desktop) {
          margin-top: $spacing-unit--ss;
          width: $imageWDesktop;
        }
      }
      &__image-inner {
        padding-bottom: 100%;
        position: relative;
      }
      &__title {
        color: $blue-medium;
        margin: $spacing-unit--md 0 $spacing-unit--xs;

        @at-root .fonts-loaded & {
          font-family: $font-heading;
        }

        @include breakpoint($tablet) {
          @include tabletContent();
          margin-top: 0;
        }

        @include breakpoint($desktop) {
          @include desktopContent();
          margin: 0 0 $spacing-unit--sm;
        }
      }
      &__eyelash {
        color: $blue-dark;
        display: block;
        font-weight: 700;
        margin: $spacing-unit--xs 0 $spacing-unit;

        .icon {
          color: $blue-menuSep;
          margin-right: $spacing-unit--xs;
        }

        @include breakpoint($tablet) {
          @include tabletContent();
        }

        @include breakpoint($desktop) {
          @include desktopContent();
          margin: 0 0 $spacing-unit;
        }
      }
      &__meta {
        @include font-size(16, 25);
        color: $blue-dark;
        font-weight: 400;
        margin: $spacing-unit 0;

        @include breakpoint($tablet) {
          float: left;
          margin: $spacing-unit--md 0 0;
          width: 100%;
        }

        @include breakpoint($desktop) {
          @include desktopContent();
          margin: 0 0 $spacing-unit--md;
        }

        p {
          margin: 0;
        }
      }
      &__tags {
        display: none;
        margin: $spacing-unit--md 0 0;

        @include breakpoint($tablet) {
          display: flex;
          flex-flow: row wrap;
          float: left;
        }

        @include breakpoint($desktop) {
          @include desktopContent();
          margin: 0;
        }

        > li {
          @include font-size(15, 22);
          margin-bottom: 0;
          margin-right: $spacing-unit--xs;
        }
      }
      &__tags-link {
        display: inline;
      }
      &__button {
        display: block;
        margin: $spacing-unit--md 0 0;

        @include breakpoint($tablet) {
          display: none;
          float: left;
        }
      }
    }
  }
  &--location {
    &:not(:last-child) {
      border-bottom: 1px solid $gray-alt;
    }

    @include breakpoint($tablet) {
      padding: $spacing-unit--l 0 $spacing-unit--ll;
    }

    &--featured {
      border: 1px solid $gray-alt;
      margin: 0 0 $spacing-unit--ll;

      @include breakpoint($tablet) {
        padding-left: $spacing-unit--l;
        padding-right: $spacing-unit--l;
      }

      @include breakpoint($desktop) {
        margin: 0 -#{$spacing-unit} $spacing-unit--ll;
        width: calc(100% + #{$spacing-unit});
      }

      @include breakpoint($large-desktop) {
        margin: 0 -#{$spacing-unit--l} $spacing-unit--ll;
        width: calc(100% + #{$spacing-unit--l * 2});
      }

      .listing-item--location__content {
        padding: $spacing-unit $spacing-unit--mdl $spacing-unit--mdl;

        @include breakpoint($tablet) {
          padding: 0;
        }
      }
    }

    &__header {
      @extend %location-listing-heading;
      margin-bottom: 0;
      margin-left: .5rem;
      margin-top: .5rem;

      @include breakpoint($tablet) {
        margin-bottom: $spacing-unit--md;
        margin-left: 0;
      }
    }

    &__content {
      padding: $spacing-unit--md;

      @include breakpoint($tablet) {
        padding: 0;
      }
    }

    &__contact-info {
      @include font-size(16, 25);
      margin: $spacing-unit--md 0;

      p {
        font-weight: 400;
        margin: 0 0 $spacing-unit--md;

        @include breakpoint($tablet) {
          margin: 0;
        }

        span {
          display: block;

          @include breakpoint($tablet) {
            display: inline-block;
          }

          &:not(:last-child) {
            margin-right: .2rem;
          }
        }

        &.phone {
          a {
            @extend .no-click-hover;
            color: $blue-medium;
            font-weight: 700;

            @media (hover: hover) {
              color: $blue-dark;
              font-weight: 300;
            }
          }
        }

        &.contact-items {
          @include font-size(16, 22);
        }
      }
    }

    &__link {
      @include font-size(16, 18);
      margin: $spacing-unit--l 0 $spacing-unit;

      @include breakpoint($tablet) {
        margin: $spacing-unit--md 0 0;
      }

      .button {
        width: 100%;
      }

      a:not(.button) {
        color: $blue-medium;
        font-weight: 400;
      }
    }

    &__info-link {
      display: flex;
      font-size: 1.6rem;
      margin-top: $spacing-unit;

      .icon {
        margin-right: 1rem;
      }
    }

    &__image {
      @include breakpoint($tablet) {
        display: block;
        margin-left: auto;
        max-width: 220px;
      }

      img {
        height: 0;
        padding-bottom: 56.25%;
        position: relative;

        &.lazyloaded {
          height: auto;
          padding-bottom: 0;
        }
      }

      figcaption {
        margin-top: $spacing-unit;
        font-style: normal;

        @include breakpoint($tablet) {
          margin-top: $spacing-unit--ss;
        }

        a {
          @include font-size(16, 18);

          &:not(.button) {
            font-weight: 400;
            margin-lefT: auto;
            text-align: right;
            width: 100%;

            @include breakpoint($tablet) {
              display: block;
            }

            .icon {
              margin-right: .5rem;
            }
          }

          &.button {
            min-width: 140px;
          }
        }
      }
    }

    #{$self} {
      &__eyelash {
        margin: 0 0 $spacing-unit;
      }
      &__title {
        align-items: center;
        display: flex;
        font-weight: 400;
        margin: 0 0 $spacing-unit--md;

        @at-root .fonts-loaded & {
          font-family: $font-heading;
        }

        a {
          font-weight: 500;
        }

        .icon {
          font-size: 1.4rem;
          line-height: 1;
          margin-left: .5rem;

          &:first-child {
            margin-left: $spacing-unit;
          }
        }
      }
      &__tags {
        flex-flow: row wrap;
        margin: $spacing-unit--l 0;

        @include breakpoint($tablet) {
          margin: $spacing-unit--md 0;
        }
      }
      &__tag {
        @include font-size(16, 25);
        margin-bottom: 0;

        &:not(:last-child) {
          margin-right: .2rem;
          &:after {
            content: ',';
          }
        }
      }
    }
  }
  &--home-article {
    padding: 0;

    #{$self} {
      &__eyelash {
        margin-bottom: $spacing-unit--ss;

        span {
          color: $blue-medium;
          font-size: 1rem;
          font-weight: 700;
          margin-right: $spacing-unit--ss;

          &.date {
            color: $blue-gray;
            font-weight: 500;
          }
        }
      }

      &__title {
        font-size: 1.6rem;
        margin: $spacing-unit--ss 0 $spacing-unit--md;

        @include breakpoint($tablet) {
          margin: $spacing-unit--ss 0 $spacing-unit--l;
        }

        @include breakpoint($desktop) {
          font-size: 1.8rem;
        }

        a {
          align-items: center;
          display: flex;

          &:focus,
          &:hover {
            .text {
              text-decoration: underline;
            }
          }

          // IE 10/11 fix for text not wrapping onto next line
          // <span> overflows flex parent and doesn't wrap
          .text,
          .icon {
            display: block;
            flex: 0 1 auto;
            max-width: 100%;
          }

          .text {
            color: $blue-gray;
            font-weight: 300;
            padding-right: $spacing-unit--l;

            @include breakpoint($tablet) {
              padding-right: $spacing-unit--xs;
            }
          }

          .icon {
            color: $black;

            @include breakpoint($desktop) {
              display: none;
            }
          }
        }
      }
    }
  }
  &--research {
    #{$self} {
      &__title {
        font-size: 1.7rem;

        @include breakpoint($desktop) {
          font-size: 2rem;
        }

        .icon {
          font-size: .8em;
        }
      }

      &__description {
        margin: $spacing-unit--ss 0;
      }

      &__note {
        margin: $spacing-unit--ss 0;
      }
    }
  }
}
