.skip {
  height: 4rem;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 0;
  transform: translate3d(0, -100%, 0);
  width: 100%;
  z-index: index($globalIndex, skipLink);

  li {
    height: 100%;
    margin: 0;
    position: absolute;
    width: 100%;
  }

  a {
    background-color: $blue-light;
    color: $black;
    display: block;
    font-size: 1.6rem;
    font-weight: 400;
    height: 100%;
    left: 0;
    line-height: 2;
    opacity: 0;
    position: absolute;
    text-decoration: none;
    transition: all .2s ease-in-out;
    top: 0;
    width: 100%;

    &:active,
    &:focus {
      display: block;
      opacity: 1;
      outline: none;
      padding: .25em 1em;
      transform: translate3d(0, 100%, 0);
    }
  }
}
