.homepage-featured-pages {
  margin: $spacing-unit 0;

  &__inner {
    :nth-child(2),
    :nth-child(3),
    :nth-child(4) {
      p {
        max-width: 320px;
      }
    }

    @include breakpoint($tablet) {
      display: grid;
      grid-gap: $spacing-unit--ss;
      grid-template-columns: 40% 60%;
      -ms-grid-columns: 50% 50%;
      grid-template-rows: auto;

      :nth-child(1) {
        grid-row: 1/3;
      }

      :nth-child(2) {
        grid-column: 2/2;
        grid-row: 1/1;

        p {
          max-width: 380px;
        }
      }

      :nth-child(3) {
        grid-column: 2/2;
        grid-row: 2/2;

        p {
          max-width: 320px;
        }
      }

      :nth-child(4) {
        grid-column: 1/3;
        grid-row: 3/3;

        p {
          max-width: 700px;
        }
      }
    }

    @include breakpoint($desktop) {
      grid-gap: 0;
      grid-template-columns: 1fr 1fr 1fr;
      -ms-grid-columns: 1fr 1fr 1fr;
      grid-template-rows: auto;

      :nth-child(1) {
        grid-row: 1/3;
      }

      :nth-child(2) {
        grid-column: 2/2;
        grid-row: 1/1;

        p {
          max-width: 470px;
        }
      }

      :nth-child(3) {
        grid-column: 3/3;
        grid-row: 1/1;

        p {
          max-width: 400px;
        }
      }

      :nth-child(4) {
        grid-column: 2/4;
        grid-row: 2/2;

        p {
          max-width: 857px;
        }
      }
    }
  }

  &__image {
    display: none;

    @include breakpoint($tablet) {
      display: block;
    }

    @include breakpoint($desktop) {
      padding-right: $spacing-unit--ss;
    }
  }

  &__image-inner {
    height: 100%;
    overflow: hidden;
    position: relative;

    img {
      object-fit: cover;
    }
  }

  &__page {
    padding: $spacing-unit--l $spacing-unit--l $spacing-unit--lml;
    text-align: center;

    @include breakpoint($large-tablet) {
      padding: $spacing-unit--lml $spacing-unit $spacing-unit--ll;
    }

    @include breakpoint($large-desktop) {
      padding: $spacing-unit--xl $spacing-unit--l;
    }

    p {
      font-size: 1.5rem;
      letter-spacing: -.2px;
      margin: 0 auto;

      @at-root .fonts-loaded & {
        font-family: $font-heading;
      }

      @include breakpoint($large-tablet) {
        font-size: 2rem;
      }

      @include breakpoint($large-desktop) {
        font-size: 2.5rem;
      }
    }

    hr {
      border-color: $blue;
      opacity: 1;
      margin: $spacing-unit auto $spacing-unit--md;
      width: 11px;
    }

    a.button {
      display: inline-block;
      margin: 0 auto;
      min-width: 124px;

      @include breakpoint($tablet) {
        min-width: 132px;
      }

      @include breakpoint($desktop) {
        display: none;
      }
    }

    a:not(.button) {
      @extend %arrow-link;
      color: $blue-medium;
      display: none;
      font-size: 1.6rem;

      @include breakpoint($desktop) {
        display: inline-block;
      }

      &:focus,
      &:hover {
        color: $blue-dark;
      }
    }

    &--green {
      background-color: $yellow-light;

      hr {
        border-color: $blue-dark;
      }

      a.button {
        border-color: $white;

        &:focus,
        &:hover {
          background-color: $white;
          border-color: $white;
          color: $blue-medium;
        }
      }

      a:not(.button) {
        color: $blue-gray;

        &:focus,
        &:hover {
          color: $black;
        }
      }
    }

    &--gray {
      background-color: $gray-alt2;
    }
  }
}
