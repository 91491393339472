.sharing {
  $self: &;
  display: none;

  @at-root .at-ready & {
    display: block;
  }

  @mixin sharingIcon() {
    @include font-size(12, 12);
    align-items: center;
    background-color: transparent;
    border: 1px solid $blue;
    border-radius: 50%;
    color: $blue-dark;
    display: flex;
    height: 2.4em;
    justify-content: center;
    width: 2.4em;
  }

  &__services {
    @include list-unstyled();
    display: flex;

    > li {
      cursor: pointer;
      margin-bottom: 0;

      &:hover,
      &:focus,
      &:focus-within {
        a {
          background-color: $blue-dark;
          border-color: $blue-dark;
          color: $white;
        }
      }
    }
  }

  &__icon {
    @include sharingIcon();

    .addthis_share & {
      pointer-events: none;
    }

    .addthis_share &--more {
      pointer-events: initial;

      &:focus {
        background-color: $white;
        color: $blue-dark;
      }
    }
  }

  &--micro {
    #{$self} {
      &__services {
        > li {
          &:not(:last-of-type) {
            margin-right: $spacing-unit--half;
          }
        }
      }

      &__icon {
        font-size: 1.2rem;
      }
    }
  }

  &--hero {
    height: auto;

    #{$self} {
      &__inner {
        background-color: transparent;
        max-width: none;
        position: relative;
        right: auto;
      }

      &__services {
        @include margin-control(true, false, '');
        align-items: center;
        flex-direction: column;

        > li {
          @include margin-control(true, false, 'half');
          display: block;
          margin-right: auto;
        }

        > li:last-child {
          margin-bottom: 0;
        }
      }

      &__icon {
        background-color: $white;
        border-color: $blue;
        border-radius: 50%;
        color: $blue-dark;
        margin: auto;
      }
    }
  }

  &--breadcrumbs {
    height: 100%;
    position: relative;

    #{$self} {
      &__inner {
        background-color: $blue-dark;
        height: 100%;
        position: absolute;
        right: 0;
      }

      &__services {
        align-items: center;
        height: 100%;

        > li {
          display: none;
          margin-right: $spacing-unit--sm;

          &:hover,
          &:focus,
          &:focus-within {
            a {
              border-color: $white;
            }
          }
        }

        > li:last-child {
          display: block;
          margin-right: 0;
        }
      }

      &__icon {
        border-color: $gray;
        border-radius: .2rem;
        color: $white;
        font-size: 1.4rem;
      }
    }
  }

  &--breadcrumbs-open {
    #{$self} {
      &__services {
        > li {
          display: block;
        }
      }
    }
  }

  &--footer {
    display: block;

    #{$self} {
      &__services {
        > li:not(:last-child) {
          margin-right: $spacing-unit--half;
        }

        > li {
          &:hover {
            #{$self}__icon {
              border-color: $white;
              color: $white;
            }
          }
        }
      }

      &__icon {
        font-size: 1.4rem;
        border-color: $blue-light;
        color: $blue-light;
        transition: all $linkTransition;
      }
    }
  }

  &--mobile-nav {
    #{$self} {
      &__services {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        > li {
          margin: $spacing-unit--half;
        }
      }

      &__icon {
        align-items: center;
        border: 1px solid $blue-light-2;
        color: $blue-light-2;
        display: flex;
        font-size: 1.6rem;
        height: 3rem;
        justify-content: center;
        line-height: 1;
        transition: all $linkTransition;
        width: 3rem;
        will-change: border-color, color;

        &:focus {
          color: $blue;
          border-color: $blue;
        }
      }
    }
  }

  // START sharing.size
  &--small {
    #{$self}__services {
      > li {
        &:not(:last-of-type) {
          margin-right: $spacing-unit--sm;
        }
      }
    }

    #{$self}__icon {
      font-size: 1.3rem;
    }
  }

  &--xs {
    #{$self} {
      &__services {
        > li {
          &:not(:last-of-type) {
            margin-right: $spacing-unit--sm;
          }
        }
      }

      &__icon {
        font-size: 1rem;
      }
    }
  }
  // END sharing.size

  // START sharing.align
  &--align-right {
    #{$self} {
      &__services {
        justify-content: flex-end;
      }
    }
  }

  &--align-center {
    #{$self} {
      &__services {
        justify-content: center;
      }
    }
  }
  // END sharing.align

  // START sharing.modifiers
  &.bg-white {
    #{$self} {
      &__icon {
        background-color: $white;
      }

      &__services {
        > li {
          &:hover,
          &:focus,
          &:focus-within {
            a {
              background-color: $blue-dark;
            }
          }
        }
      }
    }
  }
  // END sharing.modifiers
}
