.filters {
  margin: 0 auto;

  ::placeholder {
    color: $blue-dark;
    font-weight: 300;
    opacity: 1;
  }

  .button-group {
    margin-top: $spacing-unit--l;
  }

  input[type='text'] {
    border: 0;
  }

  .button,
  input[type='submit'] {
    width: 100%;
  }

  // This is used for groups of inputs/controls
  fieldset,
  .fieldset {
    border: none;
    display: block;
    padding: 0;
  }

  label,
  legend {
    font-weight: 500;
    background-color: transparent;
    display: block;
    margin: 0 0 .5em;
    padding: 0;

    .fonts-loaded & {
      font-family: $font-body;
    }
  }

  legend {
    @include font-size(13, 16);
    margin-bottom: 1em;
  }

  label {
    @include font-size(12, 16);
  }

  &__inner {
    padding: $spacing-unit--l $spacing-unit--md + .5rem;

    > fieldset,
    .fieldset {
      // Add spacing to each of the children, execpt for the last
      &:not(:last-child) {
        margin: 0 0 $spacing-unit--md;
      }

      &.small-spacing {
        margin-bottom: $spacing-unit;
      }
    }
  }

  &__row {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    margin: -#{$spacing-unit--ss};
  }

  &__col {
    flex-basis: 0;
    flex-grow: 1;
    padding: $spacing-unit--ss;

    &--separator {
      flex-grow: .25;
      padding-left: 0;
      padding-right: 0;
      text-align: center;

      span {
        @include font-size(10, 18);
        display: block;
        font-weight: 500;
      }
    }
  }

  .button-group {
    .button:not(:last-child) {
      margin: 0 0 $spacing-unit;
    }
  }

  &--has-blue {
    background-color: $blue-light-alt;
    color: $blue-dark;

    .search-input {
      input {
        &:focus {
          outline: 2px solid $blue;
        }
      }
    }
  }

  &--has-purple {
    background-color: $purple-light;
    color: $white;
  }
}
