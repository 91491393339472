.tags {
  @extend .related-links;
  border-bottom: none;

  ul {
    border-bottom: none;

    > li {
      @include font-size(16, 25);
      display: inline-block;
      margin: 0;

      @include breakpoint($desktop) {
        @include font-size(15, 18);
      }

      &:not(:last-child) {
        a {
          display: block;

          &:after {
            content: ', ';
          }
        }
      }
    }
  }
}
