.page-trail {
  @include list-unstyled();
  $self: &;
  display: inline-block;
  white-space: nowrap;

  &--collapsed {
    #{$self}__expander {
      display: inline-block;
      visibility: visible;
    }
  }

  &--expanded {
    overflow: visible;
    white-space: initial;

    #{$self}__expander {
      display: none;
      visibility: hidden;
    }
  }

  &__item,
  &__expander {
    @include font-size(12, 12);
    font-weight: 400;
  }

  &__item {
    display: inline-block;
    margin-bottom: 0;
  }

  &__expander {
    display: none;
    visibility: hidden;
  }

  &__link {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    @include breakpoint(max-width $desktop) {
      &:focus {
        outline-color: $white;
        outline-offset: 2px;
      }
    }
  }

  &__separator {
    margin: 0 .4rem;
  }

  &__link,
  &__separator {
    text-decoration: none;

    @include breakpoint($desktop) {
      color: $blue-medium;

      &:hover {
        color: $black;
      }
    }
  }

  &__icon {
    margin-right: $spacing-unit--sm;
  }

  &__link,
  &__separator,
  &__icon {
    color: $white;

    @include breakpoint($desktop) {
      color: $blue-medium;

      &:hover {
        color: $black;
      }
    }
  }
}
