@mixin heroGraphic($color: $blue) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='9.13' width='61' viewBox='0 0 61 9.13'%3E%3Cpath d='M59.87 6.84A1.14 1.14 0 1 1 61 5.7a1.14 1.14 0 0 1-1.13 1.14zm-4.52 0a1.14 1.14 0 1 1 1.13-1.14 1.14 1.14 0 0 1-1.13 1.14zm-4.52 0A1.14 1.14 0 1 1 52 5.7a1.14 1.14 0 0 1-1.17 1.14zm-4.52 0a1.14 1.14 0 1 1 1.14-1.14 1.13 1.13 0 0 1-1.14 1.14zm-4.51 0a1.14 1.14 0 1 1 1.13-1.14 1.13 1.13 0 0 1-1.13 1.14zm-4.52 0a1.14 1.14 0 1 1 1.13-1.14 1.13 1.13 0 0 1-1.13 1.14zm-4.52 0a1.14 1.14 0 1 1 1.13-1.14 1.13 1.13 0 0 1-1.13 1.14zm-4.52 0a1.14 1.14 0 1 1 1.13-1.14 1.14 1.14 0 0 1-1.13 1.14zm-4.52 0a1.14 1.14 0 1 1 1.13-1.14 1.14 1.14 0 0 1-1.13 1.14zm-4.52 0a1.14 1.14 0 1 1 1.14-1.14 1.14 1.14 0 0 1-1.14 1.14zm-4.51 0a1.14 1.14 0 1 1 1.12-1.14 1.13 1.13 0 0 1-1.12 1.14zm-4.52 0A1.14 1.14 0 1 1 11.3 5.7a1.13 1.13 0 0 1-1.13 1.14zm-4.52 0A1.14 1.14 0 1 1 6.78 5.7a1.14 1.14 0 0 1-1.13 1.14zM.33 4.9a1.13 1.13 0 0 1 .8-.34 1.14 1.14 0 0 1 0 2.28 1.14 1.14 0 0 1-.8-1.94M59.87 2.33A1.14 1.14 0 1 1 61 1.19a1.14 1.14 0 0 1-1.13 1.14zm-4.52 0a1.14 1.14 0 1 1 1.13-1.14 1.14 1.14 0 0 1-1.13 1.14zm-4.52 0A1.14 1.14 0 1 1 52 1.19a1.14 1.14 0 0 1-1.17 1.14zm-4.52 0a1.14 1.14 0 1 1 1.13-1.14 1.14 1.14 0 0 1-1.13 1.14zm-4.52 0a1.13 1.13 0 0 1-1.12-1.14 1.13 1.13 0 1 1 2.25 0 1.13 1.13 0 0 1-1.13 1.14zm-4.51 0a1.13 1.13 0 0 1-1.13-1.14 1.13 1.13 0 1 1 1.13 1.14zm-4.52 0a1.13 1.13 0 0 1-1.13-1.14 1.13 1.13 0 1 1 1.13 1.14zm-4.52 0a1.13 1.13 0 0 1-1.13-1.14 1.13 1.13 0 1 1 1.13 1.14zm-4.52 0a1.14 1.14 0 1 1 1.13-1.14 1.14 1.14 0 0 1-1.13 1.14zm-4.52 0a1.14 1.14 0 1 1 1.13-1.14 1.14 1.14 0 0 1-1.13 1.14zm-4.52 0a1.14 1.14 0 1 1 1.13-1.14 1.14 1.14 0 0 1-1.13 1.14zm-4.52 0a1.14 1.14 0 1 1 1.13-1.14 1.14 1.14 0 0 1-1.13 1.14zm-4.51 0a1.13 1.13 0 0 1-1.13-1.14 1.13 1.13 0 1 1 1.13 1.14zm-4.52 0A1.13 1.13 0 0 1 0 1.19a1.13 1.13 0 1 1 1.13 1.14z' style='fill:#{$color};fill-rule:evenodd'/%3E%3C/svg%3E");
  background-position: 50% 0%;
  background-repeat: repeat-y;
  background-size: contain;
}

@mixin make-dots($dotColor, $backgroundColor, $size, $spacing) {
  background: linear-gradient(90deg, $backgroundColor ($spacing - $size), transparent 1%) center, linear-gradient($backgroundColor ($spacing - $size), transparent 1%) center, $dotColor;
  background-size: $spacing $spacing;
}
