.back-to-top {
  align-items: center;
  background-color: $blue-light;
  border-radius: 50%;
  bottom: $spacing-unit;
  box-shadow: 2px 4px 5px 0px rgba($black, .2);
  color: $blue-dark;
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  font-weight: 700;
  height: 4.5rem;
  justify-content: center;
  letter-spacing: .02rem;
  line-height: 1.3;
  opacity: 0;
  position: fixed;
  right: $spacing-unit;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transform: translate3d(0, $spacing-unit, 0);
  transition-duration: .2s;
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(.73, .06, .75, .98);
  width: 4.5rem;
  z-index: index($globalIndex, backToTop);

  .icon {
    font-size: 1.2rem;
  }

  &--active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: all .2s cubic-bezier(.73, .06, .75, .98);
  }

  &:hover {
    background-color: $yellow-light;
  }
}
