.article-component {
  @include margin-control(false, false);
  $self: &;
  padding: $spacing-unit--l 0 0;
  border-top: .1rem solid $gray-alt;

  &:first-child,
  .divider-alpha + & {
    border-top: 0;
    padding-top: 0;
  }

  &__featured {
    @include font-size(12, 12);
    background-color: $blue-lightest;
    color: $blue-dark;
    display: table;
    font-weight: 400;
    margin-bottom: $spacing-unit;
    margin-top: -.2rem;
    padding: $spacing-unit--half $spacing-unit;
    white-space: nowrap;

    @include breakpoint($desktop) {
      margin-bottom: $spacing-unit--l;
    }
  }

  &__meta {
    ul {
      @include list-unstyled();
    }

    li {
      @include font-size(11, 23);
      color: $blue-dark;
      display: inline-block;
      font-weight: 500;
      letter-spacing: .2rem;
      margin: 0 $spacing-unit--sm 0 0;
      text-transform: uppercase;
      vertical-align: top;

      @include breakpoint($desktop) {
        @include font-size(10, 22);
        margin: 0 $spacing-unit--sm $spacing-unit--sm 0;
      }

      // List Item bullet.
      & + li {
        &:before {
          content: '\2022';
          display: inline-block;
          margin-right: .5em;
        }
      }
    }

    .category,
    .eyelash {
      font-weight: 700;
    }

    > a {
      color: $blue-medium;
      text-decoration: none;

      span {
        font-weight: 700;
      }

      &:hover {
        color: $blue-dark;
      }
    }

    > span {
      font-weight: 500;
    }
  }

  &__title {
    @include font-size(17, 20);
    margin-bottom: $spacing-unit--half;
    margin-top: 0;

    .fonts-loaded & {
      font-weight: 500;
    }

    @include breakpoint($desktop) {
      @include font-size(18, 22);
      margin-bottom: $spacing-unit;
    }

    a {
      text-decoration: none;

      &:hover {
        color: $blue-dark;
      }
    }

    .icon {
      @include font-size(14, 14);
      margin-left: $spacing-unit * .6;

      @include breakpoint($desktop) {
        @include font-size(16, 16);
      }
    }
  }

  &__description {
    @include font-size(16, 22);
    margin: $spacing-unit--half 0 0 0;

    @include breakpoint($desktop) {
      @include font-size(18, 25);
      margin: $spacing-unit 0 0 0;
    }
  }

  &__button {
    display: inline-flex;
    font-size: 1.4rem;
    margin-top: $spacing-unit--md;
    min-width: 1.22rem;
    padding: $spacing-unit--half $spacing-unit--md;

    @include breakpoint($desktop) {
      font-size: 1.6rem;
      padding: $spacing-unit / 1.2 $spacing-unit--l;
    }

    &--mobile {
      @include breakpoint($desktop) {
        display: none;
      }
    }
  }

  &__button-icon {
    display: none;

    @include breakpoint($desktop) {
      display: block;
    }
  }

  &__image {
    max-width: 71.5rem;
    position: relative;
    padding-bottom: 56.25%;

    @include breakpoint($desktop) {
      max-width: none;
    }
  }

  &__video-trigger {
    align-items: center;
    background-color: $blue-medium;
    bottom: 0;
    cursor: pointer;
    display: flex;
    height: 4.4rem;
    justify-content: center;
    left: 0;
    position: absolute;
    transition: background-color $linkTransition;
    width: 4.4rem;

    &:hover {
      background-color: $blue-gray;
    }

    .icon {
      @include font-size(24, 24);
      color: $white;
    }
  }

  &--listing {
    margin: $spacing-unit--l 0;

    &.article-component--featured {
      @include breakpoint($desktop) {
        border: .2rem solid $gray-alt;
        padding: 0 $spacing-unit--l $spacing-unit--l;
      }

      #{$self} {
        &__meta {
          display: flex;

          @include breakpoint($desktop) {
            display: block;
          }
        }

        &__featured {
          margin-right: $spacing-unit--md;
        }
      }
    }
  }

  &--news {
    padding: $spacing-unit 0;

    #{$self} {
      &__meta {
        li {
          @include breakpoint($desktop) {
            margin: 0 $spacing-unit--half $spacing-unit--half 0;
          }
        }
      }

      &__title {
        .fonts-loaded & {
          font-family: $font-body;
          font-weight: 400;
        }

        a {
          color: $blue-dark;
        }

        @include breakpoint($desktop) {
          @include font-size(18, 25);
        }
      }
    }
  }

  &--featured-newsroom {
    border: none;
    display: flex;
    flex-direction: column;
    padding: 0;

    @include breakpoint($desktop) {
      align-items: center;
      flex-direction: row;
    }

    #{$self} {
      &__image {
        order: 2;
        padding: 0;

        @include breakpoint($desktop) {
          order: 1;
          width: 50%;
        }
      }

      &__image-inner {
        position: relative;
        padding-bottom: 56.25%;
      }

      &__article {
        margin-bottom: $spacing-unit--md;
        order: 1;

        @include breakpoint($desktop) {
          background-color: $white;
          margin-bottom: 0;
          margin-left: $spacing-unit--l * -1;
          max-width: calc(50% + #{$spacing-unit--l});
          padding: $spacing-unit--l $spacing-unit--xl $spacing-unit--ll;
          order: 2;
          z-index: 1;
        }
      }

      &__meta {
        &:after {
          content: ' ';
          display: table;
          clear: both;
        }

        ul {
          float: left;

          @include breakpoint($desktop) {
            float: none;
          }
        }
      }

      &__featured {
        display: block;
        float: left;
        margin-right: $spacing-unit;

        @include breakpoint($desktop) {
          display: none;
          float: none;
        }
      }

      &__title {
        @include font-size(22, 30);
        margin-bottom: $spacing-unit;
      }

      &__description {
        margin-top: $spacing-unit;
      }
    }
  }

  &--image-card {
    border: none;
    height: 100%;
    max-width: 40.6rem;
    padding-bottom: 0;

    #{$self} {
      &__image {
        margin-bottom: $spacing-unit / 1.2;

        @include breakpoint($desktop) {
          margin-bottom: $spacing-unit--md;
        }
      }

      &__meta {
        ul {
          li {
            margin: 0 $spacing-unit--half $spacing-unit--half 0;

            @include breakpoint($desktop) {
              margin: 0 $spacing-unit--half $spacing-unit--half 0;
            }
          }
        }
      }

      &__title {
        margin-bottom: $spacing-unit--half;

        @include breakpoint($desktop) {
          margin-bottom: $spacing-unit / 1.2;
        }
      }

      &__description {
        margin-top: $spacing-unit / 1.2;

        @include breakpoint($desktop) {
          margin-top: $spacing-unit / 1.2;
        }
      }
    }
  }

  &--related-content {
    margin: 0;

    #{$self} {
      &__meta {
        li {
          @include font-size(11, 15);

          a {
            color: $blue-dark;
          }
        }
      }

      &__title {
        .fonts-loaded & {
          font-family: $font-body;
          font-weight: 400;
        }

        @include breakpoint($desktop) {
          @include font-size(22, 28);
          margin-bottom: $spacing-unit--half;
        }
      }
      &__description {
        @include breakpoint($desktop) {
          margin-top: $spacing-unit--half;
        }
      }
    }
  }
}
