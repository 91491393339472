.badge-slide {
  margin: 0 auto;
  max-width: 100%;
  overflow: hidden;
  width: 19.6rem;

  a {
    text-decoration: none;

    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }

  &__image {
    align-items: center;
    display: flex;
    height: 11.7rem;
    justify-content: center;
    margin: 0 auto $spacing-unit--md;
    width: 100%;

    img {
      max-height: 100%;
      width: auto;
    }
  }

  &__description {
    @include font-size(15, 20);
    color: $blue-gray;
    font-weight: 300;
    text-align: center;

    p {
      font-weight: 300;
    }
  }
}
