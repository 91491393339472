// =======================================
//    #CORE/HTML - HEADING ELEMENTS
// =======================================

h1,
h2,
h3,
h5,
h6 {
  font-family: $systemSerif;
  font-weight: 400;
  letter-spacing: initial;

  .fonts-loaded & {
    font-family: $font-heading;
    font-weight: 300;
  }
}

h4,
.subheading {
  .fonts-loaded & {
    font-family: $font-body;
    font-weight: 500;
  }
}

h1 {
  font-size: 2.6rem;

  @include breakpoint($tablet) {
    font-size: 5rem;
  }
}

h2 {
  font-size: 2.2rem;

  @include breakpoint($tablet) {
    font-size: 3rem;
  }
}

h3 {
  font-size: 2rem;

  @include breakpoint($tablet) {
    font-size: 2.4rem;
  }
}

h4 {
  font-size: 1.8rem;

  @include breakpoint($tablet) {
    font-size: 2.2rem;
  }
}

h5 {
  font-size: 1.6rem;

  @include breakpoint($tablet) {
    font-size: 2rem;
  }
}

h6 {
  font-size: 1.4rem;

  @include breakpoint($tablet) {
    font-size: 1.8rem;
  }
}

%location-listing-heading {
  align-items: center;
  display: flex;
  font-size: 2.2rem;
  font-weight: 500;

  @include breakpoint($desktop) {
    font-size: 3rem;
  }

  .icon {
    color: $blue;
    font-size: 2em;
    line-height: 1;

    @include breakpoint($desktop) {
      font-size: 1.2em;
    }
  }

  .text {
    color: $blue-dark;
    margin-left: 1.2rem;
  }
}
