@include font-face('mgh-icons', '../fonts/mgh-icons/mgh-icons', normal, normal);

$font-icon: 'mgh-icons';

[class^='mgh-icons-'],
[class*=' mgh-icons-'] {
  font-weight: normal;
  font-variant: normal;
  font-style: normal;
  text-transform: none;
  line-height: 1;

  // Better Font Rendering =========== //
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .fonts-loaded & {
    font-family: 'mgh-icons' !important;
  }
}

%mgh-icons {
  .fonts-loaded & {
    font-family: $font-icon !important;
  }
}

@mixin mghIcon($name, $content, $fallback: false) {
  %mgh-icons-#{$name},
  .mgh-icons-#{$name}:before {
    @if ($fallback) {
      content: $fallback;
    } @else {
      content: none;
      display: none;
    }

    .fonts-loaded & {
      content: $content;
      display: initial;
    }
  }
}

@include mghIcon(angle-right, '\e902', '\3e');
@include mghIcon(map-marker, '\e901');
