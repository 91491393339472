.main-nav {
  $self: &;
  $searchBtnWidth: 6rem;
  $searchBtnHeight: 5.8rem;

  &__items {
    @include list-unstyled();
    display: flex;
  }

  &__item {
    display: flex;
    flex: 1 1 auto;
    margin-bottom: 0;
    max-width: 190px;
    place-items: center;

    &:last-child {
      padding-right: 0;
    }

    &--search {
      align-items: center;
      display: flex;
      flex: 0 1 180px;
      justify-self: flex-end;
      position: relative;
    }

    .no-js &:hover,
    &:focus-within,
    &.menu-open {
      #{$self} {
        &__link {
          &:before {
            opacity: 1;
            transform: translate3d(0, -50%, 0) scaleY(1);
          }
        }
      }
    }

    .no-js &:hover {
      #{$self} {
        &__mega-menu {
          height: auto;
          opacity: 1;
          pointer-events: auto;
          transition: none;
        }
        &__mega-menu-inner {
          background-color: $gray;
          opacity: 1;
          transform: none;
          transition: none;
          visibility: visible;
        }
      }
    }
  }

  &__link {
    @include font-size(16, 18);
    background-color: transparent;
    border: 0;
    color: $blue-dark;
    display: block;
    font-weight: 500;
    padding: 4.2rem $spacing-unit;
    position: relative;
    text-align: left;
    text-decoration: none;
    white-space: normal;

    &:focus,
    &:hover {
      color: $blue-medium;
    }

    &:focus {
      outline: 1px dotted;
    }

    &:before {
      background-color: $blue;
      content: ' ';
      display: block;
      height: calc(100% - 8rem);
      left: 1px;
      opacity: 0;
      position: absolute;
      top: 50%;
      transform: translate3d(0, -50%, 0) scaleY(0);
      transform-origin: 50% 50%;
      transition: opacity .1s ease-in-out, transform .2s ease-in-out;
      width: 2px;
      will-change: opacity, transform;
    }

    &--search {
      align-items: center;
      background-color: $gray;
      border: 0;
      display: flex;
      justify-content: space-around;
      padding: 0;
      transition: all $linkTransition;
      width: 100%;

      .text,
      .icon {
        align-items: center;
        display: flex;
        height: $searchBtnHeight;
        justify-content: center;
        transition: all $linkTransition;
      }

      .text {
        flex: 1;
        padding: $spacing-unit;
      }

      .icon {
        background-color: $yellow-light;
        flex: 0 0 $searchBtnWidth;
      }

      &:before {
        content: none;
      }

      &:hover,
      &:focus {
        background-color: $gray-alt;

        .icon {
          background-color: $yellow;
          transform: none;
        }
      }
    }
  }

  &__search-box {
    height: $searchBtnHeight;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: all $linkTransition, max-width .15s, visibility 0s $linkTransition;
    visibility: hidden;
    width: 100%;
    will-change: opacity, visibility, transition;

    @mixin inputText() {
      @include font-size(16, 18);
      color: $blue-dark;
      font-weight: 500;
      opacity: 1;
    }

    ::placeholder {
      @include inputText();
    }

    form {
      display: block;
      height: 100%;
      width: 100%;
    }

    label {
      height: 0;
      margin: 0;
      overflow: hidden;
    }

    .search-input {
      height: 100%;

      input {
        @include inputText();
        background-color: $gray;
        border-color: $grey;
        height: 100%;
        padding: $spacing-unit--md $spacing-unit--mdl;
      }

      &--has-addon {
        input {
          margin-right: 0;
        }

        button {
          align-items: center;
          border: 1px solid $yellow-light;
          display: flex;
          flex: 0 0 $searchBtnWidth;
          font-size: 1.6rem;
          height: 100%;
          justify-content: center;
          line-height: 5;

          &:hover {
            border-color: $yellow;
          }
        }
      }
    }

    &--open {
      opacity: 1;
      transition: all .3s ease-in-out, max-width .15s .15s, visibility 0s;
      visibility: visible;
    }
  }

  &__mega-menu {
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: index($globalIndex, dropdownMenu);
  }

  &__mega-menu-inner {
    min-height: 6rem;
    padding: $spacing-unit--xl 0;
    position: relative;

    @include breakpoint($large-desktop) {
      padding: $spacing-unit--xl 6rem;
    }

    &:before {
      @include heroGraphic($blue);
      background-repeat: repeat;
      background-size: 36px 6px;
      height: 6rem;
      left: -6rem;
      position: absolute;
      top: 0;
      width: 6rem;
      z-index: index($globalIndex, heroGraphic);

      @include breakpoint($large-desktop) {
        content: ' ';
      }
    }

    .row {
      div[class^='col-'] {
        &:not(:first-child) {
          border-left: 1px solid $gray-alt;
        }
      }
    }
  }
}
