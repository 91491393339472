.mghfc {
  main .button--solid,
  .carousel .swiper-button-next,
  .carousel .swiper-button-prev,
  .carousel-controls .carousel-controls__display,
  .hero-image__caption-trigger,
  .hero-image__video,
  .pagination__page-count,
  .hero-image__caption {
    background-color: $purple;
  }

  .pagination__item--next,
  .pagination__item--previous {
    @include breakpoint($desktop) {
      background-color: $purple;
    }
  }

  .carousel-controls.carousel-controls--joint {
    .swiper-button-next,
    .swiper-button-prev {
      background-color: $white;
    }
  }

  blockquote > :first-child,
  blockquote > :last-child {
    &:after,
    &:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='37'%3E%3Cpath fill='%23592c6d' fill-rule='evenodd' d='M13.025 35.959c.698-.398 1.399-1.2 2.1-2.4l1.95-3.3v-1.5l-4.05-2.7c-1.8-1.099-3.052-2.299-3.75-3.6-.701-1.298-1.05-2.7-1.05-4.2 0-3.6 1.35-6.75 4.05-9.45 2.7-2.7 6.499-4.699 11.4-6v-2.7c-5.002 1.102-9.202 2.951-12.6 5.55-3.401 2.602-5.951 5.475-7.65 8.625-1.702 3.15-2.55 6.026-2.55 8.625 0 3.202.6 5.7 1.8 7.5 1.2 1.8 2.899 3.551 5.1 5.25.698.401 1.275.652 1.725.75.45.101.923.15 1.425.15.698 0 1.399-.199 2.1-.6zm26.25 0c.698-.398 1.399-1.2 2.1-2.4l1.95-3.3v-1.5l-4.05-2.7c-1.8-1.099-3.052-2.299-3.75-3.6-.701-1.298-1.05-2.7-1.05-4.2 0-3.6 1.35-6.75 4.05-9.45 2.7-2.7 6.499-4.699 11.4-6v-2.7c-5.002 1.102-9.202 2.951-12.6 5.55-3.401 2.602-5.951 5.475-7.65 8.625-1.702 3.15-2.55 6.026-2.55 8.625 0 3.202.6 5.7 1.8 7.5 1.2 1.8 2.899 3.551 5.1 5.25.698.401 1.275.652 1.725.75.45.101.923.15 1.425.15.698 0 1.399-.199 2.1-.6z'/%3E%3C/svg%3E");
    }
  }

  main .button,
  .button--filters-display {
    border-color: $purple;

    &:active,
    &:focus,
    &:hover {
      background-color: $lavender;
      color: $purple;
    }
  }

  main .button:not(.button--solid) {
    color: $purple;
  }

  .hero-area--general.has-image {
    .hero-buttons .button {
      .icon,
      &:before {
        background-color: rgba($purple, .3);
      }

      .icon,
      .text {
        border-color: $white;
        color: $blue-dark;
      }
    }
  }

  .hero-content__graphic {
    @include breakpoint($large-desktop) {
      @include heroGraphic($purple);
    }
  }

  .hero-image__caption-trigger,
  .hero-image__video {
    @include breakpoint($desktop) {
      border-radius: 50%;
      height: $spacing-unit--l;
      width: $spacing-unit--l;
      margin: .5rem;
    }

    .icon {
      font-size: 1.6rem;
    }

    &:active,
    &:focus,
    &:hover {
      .icon {
        color: $purple;
      }
    }
  }

  .social-follow {
    background-color: #0076a8;
    color: $white;
    overflow: hidden;
    position: relative;

    @include breakpoint(1100px) {
      .social-follow__text,
      .sharing__inner {
        position: relative;
        z-index: 1;
      }

      .sharing {
        position: relative;

        &:after {
          background-color: rgba(0, 0, 0, .25);
          border-radius: 0 50% 50% 0;
          content: '';
          display: block;
          height: 450px;
          position: absolute;
          right: -6rem;
          top: 50%;
          transform: translate3d(0,-50%, 0);
          width: 100vw;
          z-index: 0;
        }
      }
    }
  }

  .hero-content__title-separator {
    @include heroGraphic($purple);
    background-size: 61px;
  }
}
