.homepage-pillars {
  margin: $spacing-unit--lml 0;

  @include breakpoint($tablet) {
    margin: $spacing-unit--ll 0;
  }

  @include breakpoint($desktop) {
    margin: $spacing-unit--xl 0;
  }

  h2 {
    font-size: 2rem;
    font-weight: 300;
    margin-bottom: $spacing-unit--md;
    text-align: center;

    @include breakpoint($tablet) {
      margin-bottom: $spacing-unit--l;
    }

    @include breakpoint($desktop) {
      font-size: 3rem;
    }
  }

  &__pillar {
    margin: 0 auto $spacing-unit--l;
    max-width: 220px;
    text-align: center;

    @include breakpoint($tablet) {
      margin: 0 auto;
      max-width: 175px;
    }

    @include breakpoint($desktop) {
      max-width: 270px;
    }

    .image {
      height: 60px;
      overflow: hidden;
      margin: 0 auto $spacing-unit--md;
      position: relative;
      width: 60px;

      @include breakpoint($tablet) {
        margin: 0 auto $spacing-unit;
      }

      @include breakpoint($desktop) {
        height: 80px;
        margin: 0 auto $spacing-unit--l;
        width: 80px;
      }
    }

    p {
      font-size: 1.6rem;
      margin: 0  0 $spacing-unit--ss;

      @include breakpoint($tablet) {
        font-size: 1.3rem;
        margin: 0  0 $spacing-unit;
      }

      @include breakpoint($desktop) {
        font-size: 2rem;
        margin: 0  0 $spacing-unit--md;
      }
    }

    .button {
      font-size: 1.4rem;
      min-width: 140px;

      @include breakpoint($tablet) {
        font-size: 1.1rem;
        min-width: 135px;
      }

      @include breakpoint($desktop) {
        font-size: 1.6rem;
        min-width: 185px;
      }
    }
  }
}
