.read-more {
  $self: &;
  position: relative;

  &__content {
    overflow: hidden;
  }

  &__toggle-wrap {
    align-items: flex-end;
    bottom: 0;
    background: linear-gradient(to bottom, rgba($white, 0), #fff 65%);
    display: none;
    height: 10rem;
    left: 0;
    position: absolute;
    width: 100%;
    font-weight: 500;

    button {
      text-decoration: none;

      &:focus,
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__toggle {
    background-color: $white;
  }

  &.collapsed,
  &.expanded {
    #{$self} {
      &__toggle-wrap {
        display: flex;
      }
    }
  }

  &.collapsed {
    // Collapse sizes
    &[data-collapse='full'] {
      #{$self} {
        &__content {
          max-height: 100vh;
        }
      }
    }
    &[data-collapse='large'] {
      #{$self} {
        &__content {
          max-height: 75vh;
        }
      }
    }
    &[data-collapse='medium'] {
      #{$self} {
        &__content {
          max-height: 50vh;
        }
      }
    }
    &[data-collapse='small'] {
      #{$self} {
        &__content {
          max-height: 25vh;
        }
      }
    }
  }

  .no-js &,
  &.expanded {
    #{$self} {
      &__content {
        max-height: none;
        overflow: visible;
      }

      &__toggle-wrap {
        height: auto;
        position: relative;
      }
    }
  }

  .no-js & {
    #{$self} {
      &__toggle-wrap {
        display: none;
      }
    }
  }
}
