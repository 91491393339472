.filters-display {
  background-color: $blue-light-alt;
  margin: $spacing-unit--ss 0 $spacing-unit--l;

  @include breakpoint($desktop) {
    margin-top: $spacing-unit;
  }

  &__inner {
    padding: $spacing-unit $spacing-unit--l;
  }

  ul {
    @include list-unstyled();
    display: flex;
    flex-wrap: wrap;

    li {
      margin: $spacing-unit--half $spacing-unit--mdl $spacing-unit--half 0;
    }
  }

  .button {
    min-width: 16.9rem;

    .icon {
      display: block;
    }
  }
}
