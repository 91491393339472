// =======================================
//    #UTILITY - HIDDEN
//
// To be used on links (etc) that should not
// display their text, ex. a icon button.
//
// https://a11yproject.com/posts/how-to-hide-content/
// =======================================

@mixin hidden {
  clip: rect(0px, 0px, 0px, 0px);
  height: 0px;
  overflow: hidden;
  position: absolute !important;
  width: 0px;

  a:focus,
  input:focus,
  button:focus {
    height: auto;
    position: static;
    width: auto;
  }
}

.hidden,
.sr-only {
  @include hidden;
}
