.global-footer {
  @extend %font-smoothing;
  background-color: $blue-dark;
  color: $white;
  font-size: 1.4rem;
  padding: $spacing-unit--l 0;

  @include breakpoint($tablet) {
    padding: $spacing-unit--ll 0;
  }

  @include breakpoint($desktop) {
    padding: $spacing-unit--xl 0;
  }

  &__container {
    margin: auto;
    max-width: 1200px;
  }

  .logo,
  &__address {
    margin-bottom: $spacing-unit--l;
  }

  &__nav {
    @include breakpoint($tablet) {
      margin-bottom: $spacing-unit--l;
    }
  }

  &__address {
    strong {
      font-weight: 500;
    }

    a {
      color: $white;
      font-weight: 400;
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  .sharing--footer {
    margin: $spacing-unit--mdl 0 $spacing-unit--mdl;

    @include breakpoint($tablet) {
      margin: $spacing-unit--l 0 0;
    }
  }

  &__link,
  &__link--primary {
    margin-bottom: $spacing-unit--ss;

    @include breakpoint($tablet) {
      margin-bottom: $spacing-unit--half;
    }

    > a {
      @extend %arrow-link;
      color: $blue-light;
      font-weight: 700;
      font-size: 1.2rem;
      letter-spacing: .25em;
      text-decoration: none;
      text-transform: uppercase;
      transition-property: background-color, color;

      &:hover,
      &:focus {
        color: $white;
      }
    }
  }

  &__link--primary {
    @include breakpoint($tablet) {
      padding: $spacing-unit--half 0;
    }

    @include breakpoint($desktop) {
      padding-bottom: $spacing-unit;
      padding-top: $spacing-unit--half;
      border-top: 1px solid rgba($white, .25);
    }

    p {
      line-height: 1.4;
      margin: 0;
    }
  }

  &__link--secondary,
  &__link--tertiary {
    a {
      color: $white;
      text-decoration: none;

      &:hover,
      &:focus {
        color: $blue-light;
        text-decoration: underline;
      }
    }
  }

  &__link--secondary {
    line-height: 1.4;
    margin-bottom: $spacing-unit;

    a {
      font-weight: 500;
    }
  }

  &__link--tertiary {
    font-size: .9em;
    font-weight: 300;
    padding-left: $spacing-unit;
  }

  &__link--utility {
    margin-right: $spacing-unit--md;
    width: 100%;
    line-height: 1.3;

    @include breakpoint($tablet) {
      width: auto;
    }

    @include breakpoint($desktop) {
      margin-right: $spacing-unit;
    }

    a {
      border-bottom: 1px solid transparent;
      color: $white;
      font-weight: 700;
      transition-property: background-color, color;

      &:hover,
      &:focus,
      &:active {
        color: $blue-light;
      }

      @include breakpoint($tablet) {
        color: $blue-light;

        &:hover,
        &:focus,
        &:active {
          color: $white;
        }
      }
    }
  }

  &__link-description {
    display: none;
    margin-top: $spacing-unit--sm;

    @include breakpoint($desktop) {
      display: block;
    }
  }

  &__nav {
    ul {
      @include list-unstyled;

      ul {
        margin-top: $spacing-unit--sm;
      }
    }
  }

  &__link--columns {
    column-count: 3;

    > * {
      break-inside: avoid;
    }
  }

  .logo {
    margin-left: 0;
    width: 100%;

    img {
      height: 60px;
      transition: transform .2s ease-in-out, filter .2s ease-in-out;

      @include breakpoint($tablet) {
        height: 25px;
      }

      @include breakpoint($desktop) {
        height: 50px;
      }
    }

    a {
      display: block;

      &:hover,
      &:focus {
        img {
          filter: invert(.5) sepia(1) hue-rotate(150deg) brightness(1.3);
          transform: translateY(2px);
        }
      }
    }
  }

  .logo-row {
    border-bottom: 1px solid rgba($white, .25);
    border-top: 1px solid rgba($white, .25);
    margin: $spacing-unit--l 0;

    .logo {
      margin: $spacing-unit--mdl 0;
      max-width: 200px;

      @include breakpoint($tablet) {
        flex: 1 1 200px;
        margin: $spacing-unit--l auto;

        img {
          margin: auto;
        }
      }

      @include breakpoint($desktop) {
        text-align: center;
      }
    }

    svg {
      max-height: 40px;
      max-width: 200px;
    }
  }

  .logos {
    @include list-unstyled;

    @include breakpoint($tablet) {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
  }

  .copy-row {
    display: flex;
    flex-wrap: wrap;

    @include breakpoint($desktop) {
      flex-wrap: nowrap;
    }
  }

  .copyright {
    width: 100%;

    @include breakpoint($desktop) {
      margin-right: $spacing-unit--l;
      width: auto;
    }

    p {
      margin-top: 0;
    }
  }

  &__nav--utility {
    flex: 1;
    width: 100%;

    ul {
      display: flex;
      flex-flow: column nowrap;
      width: auto;

      @include breakpoint($tablet) {
        flex-flow: row nowrap;
      }

      @include breakpoint($desktop) {
        justify-content: flex-end;
      }
    }

    a {
      @include breakpoint($tablet) {
        &:after {
          content: none;
        }
      }
    }
  }

  .hidden-mobile {
    @include breakpoint(0 $tablet) {
      display: none;
    }
  }

  .hidden-tablet {
    @include breakpoint($tablet $desktop) {
      display: none;
    }
  }

  .hidden-desktop {
    @include breakpoint($desktop) {
      display: none;
    }
  }
}
