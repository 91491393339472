.page-scroll {
  height: .5rem;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: index($globalIndex, pageScroll);

  &__progress {
    background-color: $blue-light;
    height: 100%;
    transform: translate3d(-100%, 0, 0);
    transition: transform .3s cubic-bezier(.12, .69, 1, 1.03);
    width: 100%;
    will-change: transform;
  }
}
