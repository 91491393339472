.hamburger {
  $self: &;
  background-color: transparent;
  border: 3px solid transparent;
  color: inherit;
  cursor: pointer;
  // display: inline-block;
  margin: 0;
  overflow: visible;
  padding: .5rem;
  position: relative;
  text-transform: none;
  transition: opacity .15s linear;

  @mixin burgerLine() {
    background-color: $blue-medium;
    border-radius: 4px;
    height: .3rem;
    left: .3rem;
    position: absolute;
    transition: transform .15s ease;
    width: 3.4rem;
  }

  &:hover {
    opacity: .7;
  }

  &:focus {
    border-color: rgba($blue-dark, .5);
    outline: none;
  }

  &__box {
    align-items: center;
    display: flex;
    height: 24px;
    justify-content: center;
    margin-top: 0;
    position: relative;
    transition: height .15s ease;
    width: 40px;
  }

  &__inner {
    @include burgerLine();
    display: block;
    margin-top: -2px;
    top: 2px;

    &:before,
    &:after {
      @include burgerLine();
      content: ' ';
      display: block;
      left: 0;
    }

    &:before {
      top: 10px;
      transition-duration: .15s;
      transition-property: transform, opacity;
      transition-timing-function: ease;
    }

    &:after {
      top: 20px;
    }
  }

  &__text {
    @include font-size(12, 20);
    bottom: 0;
    color: $black-light;
    display: block;
    height: 20px;
    left: 0;
    opacity: 0;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    transform: translate3d(0, 30px, 0);
    transition: all .15s ease;
    width: 100%;
    will-change: color, opacity, transform;
  }

  &--active {
    &:hover {
      opacity: .7;
    }

    #{$self} {
      &__inner,
      &__inner:before,
      &__inner:after {
        background-color: $blue-text;
      }
      &__inner {
        transform: translate3d(0, 10px, 0) rotate(45deg);

        &:before {
          opacity: 0;
          transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
        }

        &:after {
          transform: translate3d(0, -20px, 0) rotate(-90deg);
        }
      }
      &__text {
        color: $blue-text;
        opacity: 1;
        transform: translate3d(0, 20px, 0);
      }
    }
  }
}
