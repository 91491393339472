.global-alert {
  $self: &;

  @include font-size(14, 24);
  padding: $spacing-unit--ss 0;

  @include breakpoint($desktop) {
    @include font-size(16, 25);
  }

  p {
    margin: 0;
    text-align: center;

    .title,
    .description {
      display: block;


      @include breakpoint($tablet) {
        display: inline;
      }
    }

    .title {
      font-weight: 700;
      margin-right: 1rem;
      padding-right: 1rem;
      position: relative;

      &:after {
        font-size: 1.4em;
        font-weight: 700;
        line-height: 1.2;
        position: absolute;
        right: -.3rem;

        @include breakpoint($tablet) {
          content: '\00b7';
        }
      }
    }

    .description {
      margin-right: .5rem;
      padding-right: .5rem;
    }
  }

  &__link {
    @extend %arrow-link;
    white-space: nowrap;
  }

  &--high {
    background-color: $red;
    color: $white;

    #{$self} {
      &__link {
        color: $white;

        &:focus,
        &:hover {
          color: darken($white, 10%);
        }
      }
    }
  }

  &--moderate {
    background-color: $yellow;

    #{$self} {
      &__link {
        color: $black;
        position: relative;
      }
    }
  }
}
