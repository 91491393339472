.hero-image {
  $self: &;
  position: relative;

  &__image {
    height: 100%;
    padding-bottom: 56.25%; // default 16:9
    position: relative;
    width: 100%;

    img {
      object-fit: cover;
    }

    &--mobile {
      display: block;
      height: auto;

      @include breakpoint($tablet) {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include breakpoint($tablet) {
        display: block;
      }
    }
  }

  &__helper {
    &--open {
      #{$self} {
        &__caption {
          opacity: 1;
          transition: all .3s ease-in-out;
          transform: translate3d(0, 0, 0);
          visibility: visible;
        }
        &__caption-trigger {
          .icon {
            &--close {
              display: block;
            }

            &--open {
              display: none;
            }
          }
        }
      }
    }
  }

  &__caption {
    @include font-size(12, 16);
    background-color: $black-light;
    bottom: 2.2rem;
    color: $white;
    font-weight: 100;
    opacity: 0;
    padding: $spacing-unit--sm;
    position: absolute;
    right: 0;
    transition: all .3s ease-in-out, visibility 0s .3s;
    visibility: hidden;
    width: 100%;
    z-index: index($globalIndex, heroCaption);

    @include breakpoint($tablet) {
      @include font-size(14, 20);
      bottom: 0;
      padding: $spacing-unit--md;
      padding-right: $spacing-unit--l;
    }

    @include breakpoint($desktop) {
      width: 100%;
    }
  }

  &__video,
  &__caption-trigger {
    align-items: center;
    background-color: $black-light;
    border: none;
    bottom: 0;
    color: $white;
    cursor: pointer;
    display: flex;
    height: 2.2rem;
    justify-content: center;
    min-width: 0;
    padding: 0;
    position: absolute;
    right: 0;
    width: 2.2rem;
    z-index: index($globalIndex, heroCaptionToggle);

    @include breakpoint($tablet) {
      height: 3rem;
      width: 3rem;
    }

    @include breakpoint($desktop) {
      height: 3.9rem;
      width: 3.9rem;
    }

    .icon {
      font-size: 1.2rem;

      @include breakpoint($tablet) {
        font-size: 1.8rem;
      }

      @include breakpoint($desktop) {
        font-size: 2.2rem;
      }

      &--close {
        display: none;
      }
    }

    &:active,
    &:focus,
    &:hover {
      background-color: $white;
      color: $blue-dark;
      box-shadow: none;
    }
  }
}
