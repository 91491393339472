.table {
  margin: $spacing-unit--l 0;

  @include breakpoint($tablet) {
    margin: $spacing-unit--ll 0;
  }

  h3 {
    @include font-size(16, 25);
    font-weight: 500;
    margin: 0;

    @include breakpoint($tablet) {
      @include font-size(16, 22);
      margin-bottom: $spacing-unit;
    }

    @include breakpoint($desktop) {
      @include font-size(16, 20);
    }
  }

  p {
    @include font-size(15, 25);
    font-weight: 300;
    margin: 0;

    @include breakpoint($tablet) {
      @include font-size(16, 20);
    }

    @include breakpoint($desktop) {
      @include font-size(15, 25);
    }
  }

  &__row {
    display: flex;
    flex-flow: column nowrap;

    &:not(:last-child) {
      margin-bottom: $spacing-unit--ss;

      @include breakpoint($tablet) {
        margin-bottom: .4rem;
      }
    }

    @include breakpoint($tablet) {
      flex-flow: row nowrap;
    }

    &:nth-child(odd) {
      .table__column {
        background-color: $brown-table;
      }
    }

    &:nth-child(even) {
      .table__column {
        background-color: $white;
      }
    }
  }

  &__column {
    padding: $spacing-unit--ss $spacing-unit;

    &:first-child {
      flex: .75;

      @include breakpoint($desktop) {
        flex: .5;
      }
    }

    &:not(:first-child) {
      flex: 1;
    }

    &:not(:last-child) {
      margin-right: .4rem;

      @include breakpoint($desktop) {
        margin-right: .5rem;
      }
    }

    @include breakpoint($tablet) {
      padding: $spacing-unit 0;

      > p {
        margin: 0 auto;
        max-width: 60%;
      }

      > h3 {
        margin: 0 auto;
        max-width: 75%;
      }
    }

    @include breakpoint($desktop) {
      padding: $spacing-unit--mdl $spacing-unit--l;

      > p {
        max-width: 70%;
      }

      > h3 {
        max-width: 85%;
      }
    }
  }
}
