.carousel {
  $self: &;
  $btnSizeMobile: 4.2rem;
  $btnSizeDesktop: 4.8rem;

  *[aria-disabled='false'] {
    cursor: pointer;
  }
  .swiper-button {
    @include font-size(16, 16);
    align-items: center;
    appearance: none;
    background-color: $blue-dark;
    background-image: none;
    border: 0;
    color: $white;
    display: flex;
    height: $btnSizeMobile;
    justify-content: center;
    margin-top: -($btnSizeMobile / 2);
    width: $btnSizeMobile;
    z-index: index($globalIndex, carouselNav);

    @include breakpoint($desktop) {
      @include font-size(18, 18);
      height: $btnSizeDesktop;
      margin-top: -($btnSizeDesktop / 2);
      width: $btnSizeDesktop;
    }
  }

  .swiper-wrapper {
    height: auto;
  }

  &--badge-slider {
    $badgeSlideImageHeight: 11.7rem; // Taken from 2_pieces/badge-slide.scss .badge-slide__image
    position: relative;

    .swiper-container {
      position: initial;
      width: calc(100% - #{$btnSizeMobile * 2 + $spacing-unit});

      @include breakpoint($desktop) {
        width: calc(100% - #{$btnSizeDesktop * 2 + $spacing-unit});
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      top: $badgeSlideImageHeight / 2;
    }

    .swiper-button-next {
      right: -($spacing-unit);

      @include breakpoint($tablet) {
        right: -($spacing-unit);
      }

      @include breakpoint($large-tablet) {
        right: 0;
      }
    }

    .swiper-button-prev {
      left: -($spacing-unit);

      @include breakpoint($tablet) {
        left: -($spacing-unit);
      }

      @include breakpoint($large-tablet) {
        left: 0;
      }
    }
  }

  &--related-content {
    .swiper-container,
    .swiper-wrapper {
      position: initial;
    }

    .carousel-navigation {
      display: none;
    }

    .swiper-slide:not(:only-child) {
      .article-component {
        @include breakpoint($tablet) {
          position: relative;
          margin-bottom: $spacing-unit--l;

          &:after {
            content: ' ';
            background-color: $gray-separator;
            position: absolute;
            height: 100%;
            right: -4rem;
            top: 0;
            width: .1rem;
          }
        }

        @include breakpoint($desktop) {
          &:after {
            right: -8rem;
          }
        }
      }
    }

    &--multiple-slides {
      // .swiper-slide:not(:only-child) {
      //   .article-component {
      //     @include breakpoint($tablet) {
      //       position: relative;

      //       &:after {
      //         content: ' ';
      //         background-color: $gray-separator;
      //         position: absolute;
      //         height: 100%;
      //         right: -4rem;
      //         top: 0;
      //         width: .1rem;
      //       }
      //     }

      //     @include breakpoint($desktop) {
      //       &:after {
      //         right: -8rem;
      //       }
      //     }
      //   }
      // }

      .carousel-navigation {
        display: flex;
      }
    }
  }
}
