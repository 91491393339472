.wysiwyg {
  @extend %clearfix;

  [class^='align'] {
    width: 100%;
    margin-bottom: $spacing-unit--l;
    margin-top: 0;
    max-width: 100%;

    @include breakpoint($tablet) {
      width: auto;
    }
  }

  .align {
    @include breakpoint($tablet) {
      &-left,
      &-right {
        max-width: 50%;
      }

      &-left {
        float: left;
        margin-right: $spacing-unit;
        padding-right: $spacing-unit;
      }

      &-right {
        float: right;
        margin-left: $spacing-unit;
        padding-left: $spacing-unit;
      }

      &-center {
        margin: auto;
      }
    }
  }

  h2,
  h3,
  h4 {
    margin-bottom: .75em;
    margin-top: $spacing-unit--l;

    @include breakpoint($tablet) {
      margin-top: $spacing-unit--ll;
    }
  }

  @at-root .main-content .content & {
    .blockquote {
      @include breakpoint($desktop) {
        margin-left: -#{$spacing-unit--xl};
      }
    }
  }
}
