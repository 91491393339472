.badge-slider {
  background-color: $gray;
  padding: $spacing-unit--md 0 $spacing-unit--l;

  @include breakpoint($tablet) {
    padding: $spacing-unit--md 0 $spacing-unit--xl;
  }

  @include breakpoint($desktop) {
    padding: $spacing-unit--l 0 $spacing-unit--xxl;
  }

  @mixin badgeSliderContainer {
    margin: 0 auto;
    max-width: none;

    @include breakpoint($large-tablet) {
      max-width: 99.5rem;
    }
  }

  &__header {
    @include badgeSliderContainer();
  }

  &__carousel {
    .swiper-container {
      @include badgeSliderContainer();
    }
  }
}
