.list-alpha {
  display: flex;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 700;
  list-style: none;
  margin: 0;
  overflow-x: auto;
  padding: 0;
  position: relative;
  text-transform: uppercase;

  li {
    flex: 1 1 auto;

    a {
      align-items: center;
      background-color: #fff;
      color: $blue-text;
      display: flex;
      height: 40px;
      line-height: 2;
      min-width: 40px;
      outline: none;
      justify-content: center;
      text-align: center;
      text-decoration: none;

      @include breakpoint($desktop) {
        height: auto;
        min-width: 2em;
      }

      &.all {
        background-color: $yellow;
        padding: 0 .5em;
        white-space: nowrap;
      }

      &:hover,
      &:focus {
        background-color: $blue-text;
        color: #fff;
      }

      &[disabled] {
        color: #757575;
        pointer-events: none;
      }
    }
  }
}
