.carousel-controls {
  $self: &;
  $navigation-child-width--mobile: calc(100% / 3);
  position: unset;
  visibility: hidden;

  &.active {
    visibility: visible;
  }

  &.carousel-controls--joint {
    bottom: 0;
    display: flex;
    height: 4.2rem;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: index($globalIndex, carouselNav);

    @include breakpoint($tablet) {
      display: inline-flex;
      width: auto; // IE 10 fallback
      width: max-content;
    }

    @include breakpoint($desktop) {
      height: 4.8rem;
    }

    #{$self} {
      &__display {
        @include font-size(12, 12);
        align-items: center;
        background-color: $blue-dark;
        color: $white;
        display: flex;
        font-weight: 700;
        justify-content: center;
        padding: 0 .5em;
        position: relative;
        width: $navigation-child-width--mobile;
        white-space: nowrap;
        z-index: index($globalIndex, carouselNav);

        @include breakpoint($tablet) {
          padding: 0 $spacing-unit;
          width: auto;
        }

        > span {
          margin: 0 .5rem;
        }
      }
    }

    .swiper-button {
      background-color: $white;
      border: 0;
      color: $blue-dark;
      left: 0;
      margin-top: 0;
      position: relative;
      right: 0;
      top: 0;
      width: $navigation-child-width--mobile;

      &:focus {
        outline: none;
      }

      &:focus,
      &:hover {
        background-color: $blue;
        color: $white;
      }

      @include breakpoint($tablet) {
        @include font-size(20, 20);
        width: 5.7rem;
      }

      &.swiper-button-disabled {
        background-color: $white;
        color: rgba($blue-dark, .3);
        opacity: 1;
      }
    }

    .swiper-button-prev {
      &:after {
        background-color: $gray-alt;
        content: ' ';
        height: 60%;
        position: absolute;
        right: 0;
        top: 20%;
        width: .1rem;

        @include breakpoint($tablet) {
          width: .1rem;
        }
      }
    }

    // Fixing bug where pointer events were going through the button and being picked up by controls on embedded videos
    .swiper-button-disabled {
      pointer-events: initial;
    }

    &.has-cta {
      #{$self} {
        &__display {
          background-color: transparent;
          color: $blue-dark;
        }
      }

      .swiper-button {
        background-color: $gray;
        color: $blue-dark;
      }
    }
  }

  &--two-column {
    opacity: 1;
    transition: opacity $linkTransition;

    &.transitioning {
      opacity: 0;
      transition: none;
    }
  }
}
