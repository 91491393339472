.card-section {
  $self: &;

  &.row > [class^='col'],
  &.row > [class*='col'] {
    display: flex;
    flex-flow: row wrap;
  }

  &__inner {
    padding: $spacing-unit--md;

    @include breakpoint($desktop) {
      padding: $spacing-unit--l 0;
    }
  }

  &--3-across {
    margin: $spacing-unit--l 0;

    @include breakpoint($desktop) {
      margin: $spacing-unit--ll 0 $spacing-unit--ll;
    }

    .component-header {
      margin: 0 auto $spacing-unit--l;
      max-width: 979px;
      text-align: left;

      @include breakpoint($desktop) {
        margin: 0 auto $spacing-unit--l + 1;
        text-align: center;
      }

      h2 {
        margin-top: 0;
      }
    }

    #{$self} {
      &__inner {
        padding: 0;

      }
    }
  }

  &--gray {
    background-color: $gray;
  }

  &--gray-desktop {
    @include breakpoint($desktop) {
      background-color: $gray;
      padding: $spacing-unit--l 0;
    }
  }

  &--4-across {
    margin: $spacing-unit--l 0;

    @include breakpoint($desktop) {
      margin: $spacing-unit--ll 0 $spacing-unit--ll;
    }

    .component-header {
      margin-bottom: $spacing-unit--l;
      text-align: left;

      @include breakpoint($tablet) {
        text-align: center;
      }

      h2 {
        margin-top: 0;
      }
    }

    .card {
      &__title {
        @include font-size(18, 30);

        .fonts-loaded & {
          font-family: $font-body;
          font-weight: 400;
        }

        @include breakpoint($desktop) {
          @include font-size(21, 32);
        }
      }

      &__description {
        @include font-size(16, 25);

        @include breakpoint($desktop) {
          @include font-size(16, 25);
        }
      }
    }

    #{$self} {
      &__inner {
        @include breakpoint($desktop) {
          padding-left: $spacing-unit--ll;
          padding-right: $spacing-unit--ll;
        }
      }
    }
  }
}
