.mobile-search {
  $self: &;

  &__trigger {
    background-color: transparent;
    border: 3px solid transparent;
    color: $blue-medium;
    display: block;
    font-size: 1.8rem;
    transition: color .15s ease;
    width: 50px;
    will-change: coior;

    .icon-open,
    .icon-close {
      align-items: center;
      justify-content: center;
    }

    .icon-close {
      display: flex;
      height: 0;
      overflow: hidden;
      visibility: hidden;

      .hamburger {
        &__text {
          opacity: 0;
          transform: translate3d(0, 30px, 0);
          transition: all .15s ease, visibility 0s, height 0s;
        }
      }
    }

    .icon-open {
      display: flex;
      font-size: 2.4rem;
      visibility: visible;
    }

    &:focus {
      border-color: rgba($blue-dark, .5);
      outline: none;
    }
  }

  &__popover {
    background-color: $blue-light-alt;
    box-shadow: 2.5px 4.33px 10px 0px rgba($black, .2);
    left: 0;
    opacity: 0;
    padding: $spacing-unit;
    position: absolute;
    top: 100%;
    transform: rotateX(-30deg);
    transform-origin: 50% -50px;
    transition: all $linkTransition, visibility 0s $linkTransition;
    visibility: hidden;
    width: 100%;
    will-change: opacity, transform, visibility;
    z-index: index($globalIndex, mobileSearch);
  }

  &__search-box {
    @mixin inputText() {
      @include font-size(16, 18);
      color: $blue-dark;
      font-weight: 500;
      opacity: 1;
    }

    ::placeholder {
      @include inputText();
    }

    form {
      display: block;
      height: 100%;
      width: 100%;
    }

    label {
      height: 0;
      margin: 0;
      overflow: hidden;
    }

    .search-input {
      height: 100%;

      input {
        @include inputText();
        background-color: $gray;
        border-color: $grey;
        padding: $spacing-unit $spacing-unit--mdl;
      }

      &--has-addon {
        input {
          margin-right: 0;
        }

        button {
          border: 1px solid $blue-dark;
          flex: 0 0 4.4rem;
          height: 100%;
          line-height: 4.2;

          &:hover,
          &:focus {
            border-color: $blue-text;
          }
        }
      }
    }
  }

  &--open {
    #{$self} {
      &__trigger {
        .icon-close {
          height: auto;
          overflow: visible;
          visibility: visible;

          .hamburger {
            &__text {
              opacity: 1;
              transform: translate3d(0, 20px, 0);
            }
          }
        }

        .icon-open {
          height: 0;
          overflow: hidden;
          visibility: hidden;
        }
      }
      &__popover {
        opacity: 1;
        transform: none;
        transition: all $linkTransition;
        visibility: visible;
      }
    }
  }
}
