// =======================================
//    #CORE/HTML - FORMS
// =======================================

fieldset {
  border-width: 1px;
  margin: $spacing-unit--l 0;
  padding: $spacing-unit--l;
}

legend {
  background-color: $white;
  font-size: 2rem;
  padding: .25em .5em;

  .fonts-loaded & {
    font-family: $font-heading;
  }
}

button,
input,
select,
textarea {
  @include font-size(16, 24);
  font-weight: inherit;
}

::placeholder {
  color: $blue-gray;
}

input[type='submit'],
input[type='radio'],
input[type='checkbox'] {
  width: auto;
}

textarea {
  width: 100%;
}

label {
  display: block;
  font-size: .8em;
  font-weight: 400;
  line-height: 1.25;
  margin: 0 0 .5em;
}

input[type='date'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='url'],
input[type='week'],
textarea {
  background-color: $white;
  border: 1px solid palette(gray, a11y);
  box-shadow: none;
  color: $blue-dark;
  font-size: 1.6rem;
  line-height: 1;
  margin-bottom: $spacing-unit;
  padding: .5em 1em;

  &:focus {
    outline-color: rgba($blue-dark, .5);
    outline-style: solid;
    outline-width: 2px;
  }
}

button[type='reset'],
button[type='submit'],
input[type='reset'],
input[type='submit'] {
  background-color: $blue-dark;
  border-radius: 0;
  border: 1px solid transparent;
  color: $white;
  cursor: pointer;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1;
  min-width: 200px;
  padding: .75em 1.5em;
  text-align: center;
  text-decoration: none;
  transition: .15s ease-in-out;
  transition-property: color, background-color, border-color, box-shadow;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &:hover,
  &:focus,
  &:active {
    background-color: $blue-text;
    border-color: $blue-text;
    color: $white;
    outline: 0;
  }
}

input[disabled],
button[disabled],
select[disabled],
textarea[disabled] {
  cursor: not-allowed;
  opacity: .6;
}

input[type=radio],
input[type=checkbox] {
  appearance: none;
  border: 1px solid;
  color: palette(gray, a11y);
  height: .75em;
  width: .75em;

  &:focus {
    color: $blue-dark;
    outline-color: $blue-gray;
    outline-style: solid;
    outline-width: 2px;
  }

  &:checked {
    background-color: currentColor;
    color: $blue-dark;
  }
}

input[type=radio] {
  border-radius: 50%;
}

input[type='color'],
input[type='color']::-webkit-color-swatch-wrapper {
  height: 3rem;
  padding: 0;
  width: 6rem;
}

input[type='color']::-webkit-color-swatch {
  border: 0;
}

select {
  margin-bottom: $spacing-unit;
}

// Modified from https://github.com/filamentgroup/select-css
.custom-select {
  display: inline-block;
  position: relative;
  margin: 1.5rem;
  margin-left: 0;

  label {
    position: absolute;
    top: -1.5rem;
  }

  select {
    appearance: none;
    background-color: $white;
    border-radius: 0;
    border: 1px solid $gray-light;
    box-shadow: none;
    box-sizing: border-box;
    color: $blue-dark;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.2;
    margin: 0;
    max-width: 100%;
    min-width: 200px;
    padding: .5em 2em .5em 1em;

    &::-ms-expand {
      display: none;
    }

    &:focus {
      outline-color: rgba($blue-dark, .5);
      outline-style: solid;
      outline-width: 3px;

      + .arrow {
        border-left: 3px solid rgba($blue-dark, .25);
      }
    }
  }

  option {
    font-weight: normal;
  }

  .arrow {
    align-items: center;
    border-left: 1px solid $gray-light;
    display: flex;
    height: 100%;
    justify-content: center;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 2em;
  }
}

.form-group,
.wFormContainer .wForm .oneChoice {
  align-items: center;
  display: inline-flex;
  margin: 0 1.5rem 1.5rem 0;

  label {
    font-size: 1.4rem;
    font-weight: 400;
    letter-spacing: initial;
    line-height: 1.2;
    margin: 0 1em;
    order: 1;
    text-transform: none;
  }
}

// =======================================
// Input type reference
//
// input[type='button']
// input[type='checkbox']
// input[type='color']
// input[type='date']
// input[type='datetime-local']
// input[type='email']
// input[type='file']
// input[type='hidden']
// input[type='image']
// input[type='month']
// input[type='number']
// input[type='password']
// input[type='radio']
// input[type='range']
// input[type='reset']
// input[type='search']
// input[type='submit']
// input[type='tel']
// input[type='text']
// input[type='time']
// input[type='url']
// input[type='week']
// =======================================
