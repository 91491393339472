.carousel-media {
  .image {
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    width: 100%;

    img {
      object-fit: cover;
    }
  }

  .video {
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
    width: 100%;
  }

  .graphic {
    align-items: center;
    display: flex;
    height: 0;
    justify-content: center;
    padding-bottom: 56.25%;
    position: relative;
    width: 100%;

    > * {
      bottom: 0;
      left: 0;
      margin: 0 auto;
      max-height: 100%;
      max-width: 100%;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .highcharts {
    align-items: center;
    display: flex;
    height: 0;
    justify-content: center;
    padding-bottom: 56.25%;
    position: relative;
    width: 100%;

    > div {
      @include absoluteFull();
      bottom: auto;
      max-height: 100%;
      max-width: 100%;
      right: auto;
    }
  }
}
