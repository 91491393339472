.main-nav-col {
  @include font-size(13, 17);
  height: 100%;

  @include breakpoint($large-desktop) {
    @include font-size(16, 20);
    margin-right: $spacing-unit--l;
  }

  &__content {
    margin: 0 auto;
    max-width: 40rem;
  }

  &__description {
    h2,
    p {
      margin: 0 0 $spacing-unit--md;
    }

    h2 {
      line-height: 1.1;
    }

    a {
      text-decoration: none;

      &:focus,
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__link-list {
    @include list-unstyled();
  }

  &__link {
    color: $blue-dark;
    display: inline-block;
    margin: 0 0 $spacing-unit;

    &:after {
      color: $blue-medium;
    }

    &:hover {
      color: $black;

      > span {
        color: $black;
      }
    }

    p {
      margin: $spacing-unit--half 0 0;
    }

    &--title {
      @extend %arrow-link;
      font-weight: 500;
      margin: 0 0 $spacing-unit--l;
    }

    &--blue {
      @include font-size(12, 18);
      color: $blue-medium;
      font-weight: 700;
      text-transform: uppercase;

      @include breakpoint($large-desktop) {
        @include font-size(14, 20);
      }

      > span {
        color: $blue-medium;
      }

      &:hover {
        color: $blue-dark;

        > span {
          color: $blue-dark;
        }
      }
    }

    &--all {
      margin: $spacing-unit--md 0;
    }
  }

  &__search {
    label {
      display: none;
    }

    ::placeholder {
      color: $blue-medium;
      opacity: 1;
    }

    .search-input {
      margin: 0 0 $spacing-unit--md;
    }

    ul {
      @include list-unstyled();
    }

    p {
      margin-top: 0;
    }
  }

  &__figure {
    margin: 0;

    img {
      margin: 0 0 $spacing-unit--half;
    }

    figcaption {
      @include font-size(14, 20);
      font-style: italic;
    }
  }
}
