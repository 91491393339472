// =======================================
//    #UTILITY - FONTS
// =======================================

// =============================================================================
// Font Face
// =============================================================================
@mixin font-face($name, $path, $weight: null, $style: null, $exts: woff2 woff) {
  $src: null;

  $formats: (
    otf: 'opentype',
    ttf: 'truetype'
  );

  @each $ext in $exts {
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + '.' + $ext)) format(quote($format)), comma);
  }

  @font-face {
    font-display: swap;
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}

// Fonts.com license for Helvetic web fonts
@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=72ea6c2d-4925-4118-8217-5f369f953766&fontids=5664067,5664081,5664085,5664089,5664093,5664098,5664103,5664107,5664111,5664149");

// Usage
// @include font-face($name: 'Roboto', $path: '../fonts/roboto/roboto-regular', $weight: 400, $style: normal);
// or
// @include font-face('Roboto', '../fonts/roboto/roboto-regular', 400, normal);

// Spectral
@include font-face('Spectral', '../fonts/spectral/spectral-extralight', 200, normal);
@include font-face('Spectral', '../fonts/spectral/spectral-extralightitalic', 200, italic);
@include font-face('Spectral', '../fonts/spectral/spectral-light', 300, normal);
@include font-face('Spectral', '../fonts/spectral/spectral-lightitalic', 300, italic);
@include font-face('Spectral', '../fonts/spectral/spectral-regular', 400, normal);
@include font-face('Spectral', '../fonts/spectral/spectral-italic', 400, italic);
@include font-face('Spectral', '../fonts/spectral/spectral-medium', 500, normal);
@include font-face('Spectral', '../fonts/spectral/spectral-mediumitalic', 500, italic);
@include font-face('Spectral', '../fonts/spectral/spectral-semibold', 600, normal);
@include font-face('Spectral', '../fonts/spectral/spectral-semibolditalic', 600, italic);
@include font-face('Spectral', '../fonts/spectral/spectral-bold', 700, normal);
@include font-face('Spectral', '../fonts/spectral/spectral-bolditalic', 700, italic);
@include font-face('Spectral', '../fonts/spectral/spectral-extrabold', 800, normal);
@include font-face('Spectral', '../fonts/spectral/spectral-extrabolditalic', 800, italic);

// Helvetica Neue
@include font-face('Helvetica Neue', '../fonts/neue-helvetica/neue-helvetica-thin', 100, normal);
@include font-face('Helvetica Neue', '../fonts/neue-helvetica/neue-helvetica-thin-italic', 100, italic);
@include font-face('Helvetica Neue', '../fonts/neue-helvetica/neue-helvetica-light', 300, normal);
@include font-face('Helvetica Neue', '../fonts/neue-helvetica/neue-helvetica-light-italic', 300, italic);
@include font-face('Helvetica Neue', '../fonts/neue-helvetica/neue-helvetica-roman', 400, normal);
@include font-face('Helvetica Neue', '../fonts/neue-helvetica/neue-helvetica-italic', 400, italic);
@include font-face('Helvetica Neue', '../fonts/neue-helvetica/neue-helvetica-medium', 500, normal);
@include font-face('Helvetica Neue', '../fonts/neue-helvetica/neue-helvetica-medium-italic', 500, italic);
@include font-face('Helvetica Neue', '../fonts/neue-helvetica/neue-helvetica-bold', 700, normal);
@include font-face('Helvetica Neue', '../fonts/neue-helvetica/neue-helvetica-bold-italic', 700, italic);

// System Font stack
// https://css-tricks.com/snippets/css/system-font-stack/
$systemFont: sans-serif;
// https://css-tricks.com/snippets/css/system-font-stack/#comment-1606328
$systemSerif: serif;

$font-body: 'Helvetica Neue', Helvetica, Arial, sans-serif;

$font-heading: 'Spectral', serif;


// ========================================================================= //
//  Web Fonts
//
//
//  FOR REFERENCE: Font-weights by numeric value / name.
//  Side note: it is recommended that we to use the numeric value here to help clarify exactly which weight is getting referenced to.
//    100 = thin
//    200 = extra-light
//    300 = light
//    400 = normal, book
//    500 = medium
//    600 = demi-bold / semi-bold
//    700 = bold
//    800 = heavy / extra-bold / heavy
//    900 = black / extra-black / ultra-black
//
// ========================================================================= //
