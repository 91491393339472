.form-options {
  .form-options__list {
    > button {
      margin: $spacing-unit--md 0;

      &:first-of-type {
        margin-top: 0;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__option {
    background-color: transparent;
    border: none;
    color: $blue-dark;
    cursor: pointer;
    display: block;
    margin: $spacing-unit--md 0;
    outline: none;
    width: 100%;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &:focus {
      outline: 2px solid $blue;
    }

    &-inner {
      align-items: center;
      display: flex;
    }

    &-box {
      background-color: $white-alt;
      border: 1px solid $gray-separator;
      display: block;
      height: $spacing-unit;
      margin-right: $spacing-unit;
      transition: all $linkTransition;
      width: $spacing-unit;
    }

    &-text {
      display: block;
      text-align: left;
      width: calc(100% - #{$spacing-unit--l});
    }

    &.checked {
      .form-options__option-box {
        background-color: $blue-dark;
        border-color: $blue-dark;
      }
    }
  }

  &[disabled] {
    opacity: .6;
    pointer-events: none;
  }

  &[data-view='radio'] {
    .form-options__option-box {
      border-radius: 50%;
    }
  }

  &--sm {
    .form-options__list {
      > button {
        margin: $spacing-unit 0;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
