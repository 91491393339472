.article-reference {
  margin: $spacing-unit--ll 0;

  @include breakpoint($desktop) {
    margin: $spacing-unit--xl 0;
  }

  p {
    @include font-size(16, 25);
    font-style: italic;
    margin: 0;

    em {
      font-style: normal;
    }

    @include breakpoint($desktop) {
      @include font-size(18, 30);
    }
  }

  p + a {
    margin: $spacing-unit--md 0 0;

    @include breakpoint($desktop) {
      margin: $spacing-unit--l 0 0;
    }
  }
}
