.divider-alpha {
  $border: 2px;
  border-top: $border solid $yellow;
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 700;
  margin: $spacing-unit--l 0;
  text-transform: uppercase;

  span {
    align-items: center;
    border: $border solid $yellow;
    display: flex;
    height: 40px;
    justify-content: center;
    transform: translateY(-#{$border});
    width: 40px;
  }
}
