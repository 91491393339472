$imageSizeMobile: 100px;

.resource-tile {
  $self: &;
  display: flex;
  justify-content: center;
  margin: 0 auto;

  &__inner {
    text-align: center;
    width: 100%;
  }

  &__image {
    margin: 0 auto $spacing-unit;
    overflow: hidden;
    position: relative;
    width: $imageSizeMobile + 10px;
    height: $imageSizeMobile + 10px;
    backface-visibility: hidden;
    border-radius: 50%;

    &:before {
      background-color: rgba($white, 1);
      opacity: 0;
      border-radius: 50%;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transform: translateZ(0) scale3d(.5, .5, 1);
      transition: .3s ease-in-out;
      width: 100%;
    }

    &-inner {
      height: 75%;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: 75%;
    }
  }

  a {
    &:focus,
    &:hover {
      #{$self}__image:before {
        opacity: 1;
        transform: translateZ(0) scale3d(1, 1, 1);
      }
    }
  }

  &__button {
    font-size: 1.6rem;
    margin: 0 auto $spacing-unit--ss auto;
    max-width: 100%;
    min-width: 170px;
    padding-left: $spacing-unit--ss;
    padding-right: $spacing-unit--ss;
    white-space: normal;

    @include breakpoint($tablet) {
      font-size: 1.4rem;
      margin-bottom: $spacing-unit--ss;
      min-width: 122px;
    }

    @include breakpoint($desktop) {
      display: none;
    }
  }

  &__title {
    @include font-size(18, 22);
    display: none;

    @include breakpoint($desktop) {
      display: block;
    }

    .arrow-link:before {
      display: none;
    }
  }

  &__description {
    @include font-size(18, 23);
    margin: 0;

    @include breakpoint($tablet) {
      @include font-size(13, 20);
    }

    @include breakpoint($desktop) {
      @include font-size(15, 20);
    }
  }
}
