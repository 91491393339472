.condition-overview {
  $self: &;
  margin: $spacing-unit--ll 0;
  position: relative;

  @include breakpoint($desktop) {
    margin: $spacing-unit * 5 0 $spacing-unit--xl;
  }

  a {
    color: $blue-medium;
    text-decoration: none;
  }

  h6 {
    margin: $spacing-unit 0;
  }
}
