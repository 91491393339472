%related-links,
.related-links {
  @include margin-control(false, false, '');
  border-bottom: 1px solid $gray;

  h2 {
    margin: $spacing-unit--ss 0;

    @include breakpoint($tablet) {
      margin: $spacing-unit 0;
    }

    @include breakpoint($desktop) {
      @include font-size(20, 26);
      margin: $spacing-unit--md 0;
    }
  }

  ul {
    @include list-unstyled();
    margin-bottom: $spacing-unit--md;

    @include breakpoint($tablet) {
      margin-bottom: $spacing-unit--l;
    }

    @include breakpoint($desktop) {
      margin-bottom: $spacing-unit--md;
    }

    > li {
      @include font-size(15, 18);
      margin-bottom: $spacing-unit--sm;

      @include breakpoint($desktop) {
        margin-bottom: $spacing-unit--ss;
      }
    }
  }

  a {
    font-weight: 400;
    text-decoration: none;

    &:active,
    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }
}
