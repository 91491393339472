.featured-treatments {
  $self: &;
  background-color: $gray;
  padding: 2rem 0;

  &__card-container {
    @include breakpoint($large-tablet) {
      display: flex;
      flex-wrap: wrap;
    }

    .spotlight-card--treatment {
      @include breakpoint($large-tablet) {
        flex-grow: 0;
        flex-shrink: 0;
        margin: 0 0 $spacing-unit 0;
        width: calc(50% - .75rem);

        &:nth-child(odd) {
          margin-right: $spacing-unit--half;
        }

        &:nth-child(even) {
          margin-left: $spacing-unit--half;
        }
      }
    }
  }

  &--skin2 {

    #{$self}__card-container.collapsed {

      .spotlight-card--treatment:nth-child(n+5) {
        display: none;
      }
    }

    // Button
    #{$self}__show-button {
      margin: $spacing-unit--l 0;
    }
  }
}
