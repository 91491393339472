.hero {
  $self: &;
  $breadcrumbsHeight: 5.2rem;
  position: relative;

  &__breadcrumbs {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    @include breakpoint($desktop) {
      margin: $spacing-unit 0;
      position: relative;
      width: 100%;
    }
  }

  &__inner {
    padding-top: $breadcrumbsHeight; // Add padding for absolute breadcrumb positioning

    @include breakpoint($desktop) {
      padding-top: 0;
    }
  }

  &--landing,
  &--profile {
    #{$self} {
      &__breadcrumbs {
        .page-trail__link {
          @include breakpoint($desktop) {
            color: $blue-gray;

            &:hover {
              color: $black;
            }
          }
        }
      }
    }
  }

  &--landing {
    #{$self} {
      &__inner {
        display: flex;
        flex-direction: column;

        @include breakpoint($desktop) {
          align-items: center;
          flex-direction: row;
        }
      }

      &__content {
        background-color: $white;
        margin: -2.2rem auto 0;
        order: 1;
        width: calc(100% - 4.4rem);
        z-index: index($globalIndex, heroContent);

        @include breakpoint($tablet) {
          margin: -3rem auto 0;
          width: calc(100% - 6rem);
        }

        @include breakpoint($desktop) {
          background-color: transparent;
          margin: 0;
          order: 0;
          width: 50%;
          z-index: index($globalIndex, heroImage);
        }
      }
      &__image {
        order: 0;
        z-index: index($globalIndex, heroImage);


        @include breakpoint($desktop) {
          margin: 0;
          order: 1;
          padding-left: $spacing-unit;
          width: 50%;
          z-index: index($globalIndex, heroContent);
        }
      }
    }
  }

  &--full-content,
  &--listing {
    #{$self} {
      &__inner {
        @include breakpoint($desktop) {
          padding-top: 0;
        }
      }
      &__content {
        padding: 0 $spacing-unit;
        margin: 0;
        width: 100%;

        @include breakpoint($desktop) {
          padding: 0;
        }
      }
    }
  }

  &--profile {
    @include breakpoint($tablet) {
      padding-top: $spacing-unit--l;
    }

    @include breakpoint($desktop) {
      padding-top: 0;
    }

    #{$self} {
      &__inner {
        @include breakpoint($tablet) {
          display: flex;
          padding-left: $spacing-unit;
          padding-right: $spacing-unit;
        }

        @include breakpoint($desktop) {
          align-items: center;
          padding-left: 0;
          padding-right: 0;
        }
      }

      &__image {
        display: none;

        @include breakpoint($tablet) {
          display: block;
          order: 0;
          width: 21.2rem;
        }

        @include breakpoint($desktop) {
          height: calc(100% - #{$spacing-unit * 4});
          order: 1;
          width: 40%;
        }
      }

      &__content {
        padding: 0 $spacing-unit;

        @include breakpoint($tablet) {
          order: 1;
          padding: 0;
        }

        @include breakpoint($desktop) {
          order: 0;
          width: 60%;
        }
      }
    }
  }
}
