@mixin absoluteFull() {
  bottom: 0;
  height: 100%;
  left: 0;
  margin: auto;
  object-fit: cover;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
