.top-nav {
  $self: &;

  &__items {
    @include list-inline();
    flex-wrap: wrap;

    @include breakpoint($large-tablet) {
      flex-wrap: nowrap;
      justify-content: flex-end;
    }
  }

  &__item {
    margin-bottom: 0;
    position: relative;

    &:last-child {
      .top-nav__dropdown-menu {
        left: auto;
        right: 0;
      }
    }

    .no-js &:hover,
    &.menu-open {
      #{$self} {
        &__dropdown-menu {
          opacity: 1;
          transition: all .25s ease, opacity .1s ease;
          visibility: visible;
        }
        &__dropdown-menu-inner {
          opacity: 1;
          transform: none;
          transition: all .25s ease, opacity .1s ease;
          visibility: visible;
        }
      }
    }
  }

  &__link {
    @include font-size(13, 24);
    color: $blue-dark;
    display: inline-block;
    font-weight: 500;
    letter-spacing: -.2px;
    padding: 1rem $spacing-unit--ss;
    text-decoration: none;

    &:after {
      margin-left: 2px;
    }

    &:hover,
    &:focus,
    &:active {
      color: $blue-medium;
    }

    @include breakpoint($desktop) {
      padding: 1rem $spacing-unit--md;
    }

    .icon {
      margin-left: .5em;
    }
  }

  &__dropdown-menu {
    position: absolute;
    left: 0;
    min-width: 100%;
    opacity: 0;
    top: 100%;
    visibility: hidden;
    will-change: opacity, visibility;
    z-index: index($globalIndex, dropdownMenu);
  }

  &__dropdown-menu-inner {
    @include list-unstyled();
    background-color: $gray;
    opacity: 0;
    padding: 0 $spacing-unit--ss;
    position: relative;
    transform: rotate3d(1, 0, 0, -60deg);
    transform-origin: 50% -50px;
    transition: all .25s ease, visibility 0s .25s;
    visibility: hidden;
    will-change: opacity, transform;

    @include breakpoint($desktop) {
      padding: 0 $spacing-unit--md;
    }


    &:before,
    &:after {
      clear: both;
      content: '';
      display: table;
    }
  }

  &__dropdown-item {
    margin: $spacing-unit 0;
  }

  &__dropdown-link {
    @extend .top-nav__link;
    font-weight: 400;
    padding: 0;
    white-space: nowrap;

    .icon {
      color: $blue-medium;
      justify-self: flex-end;
    }
  }
}
