$imageSizeMobile: 25px;
$imageSizeTablet: 60px;
$imageSpacing: 20px;

@mixin resourceTileList {
  .resource-tile-section {
    &__tile {
      margin: 0;

      &:not(:last-child) {
        .resource-tile {
          &__inner {
            &:after {
              background-color: $gray-alt;
              bottom: 0;
              content: ' ';
              height: 1px;
              position: absolute;
            }
          }
        }
      }
    }
  }
  .resource-tile {
    &__inner {
      position: relative;
      text-align: left;

      &:after {
        display: block !important;
        clear: both !important;
        content: '' !important;
      }
    }

    &__image {
      float: left;
      margin-bottom: 0;
      margin-right: $imageSpacing;
    }

    &__title {
      display: block;
      float: right;
    }

    &__description {
      @include font-size(15, 20);
      float: right;
    }
  }
}

@mixin resourceTileListMobile {
  .resource-tile {
    &__inner {
      padding: $imageSpacing - 5 0;

      &:after {
        left: 0;
        width: 100%;
      }
    }
    &__image {
      width: $imageSizeMobile;
      height: $imageSizeMobile;

      &:before {
        content: none;
      }
    }

    &__image-inner {
      height: 100%;
      width: 100%;
    }

    &__title {
      margin: $spacing-unit--xxs 0;
      width: calc(100% - #{$imageSizeMobile + $imageSpacing});
    }

    &__description {
      display: none;
    }
  }
}

@mixin resourceTileListTablet {
  .resource-tile {
    &__inner {
      flex-flow: row nowrap;
      padding: $imageSpacing 0;

      &:after {
        left: $imageSizeTablet + $imageSpacing;
        width: calc(100% - #{$imageSizeTablet + $imageSpacing});
      }
    }

    &__image {
      height: $imageSizeTablet;
      width: $imageSizeTablet;
    }

    &__title,
    &__description {
      width: calc(100% - #{$imageSizeTablet + $imageSpacing});
    }

    &__title {
      margin: 0 0 $spacing-unit--ss;
    }
  }
}

.resource-tile-section {
  $self: &;
  padding: $spacing-unit--l 0;

  @include breakpoint($tablet) {
    padding: $spacing-unit--lml 0;
  }

  @include breakpoint($desktop) {
    padding: $spacing-unit--ll 0;
  }

  .component-header {
    text-align: left;

    h2 {
      margin-top: 0;
    }

    @include breakpoint($desktop) {
      text-align: center;
    }
  }

  &__tile {
    margin: $spacing-unit 0;
    padding: 0 $spacing-unit--half;

    @include breakpoint($desktop) {
      margin: $spacing-unit--md 0;
    }
  }

  &--3-across {
    @include margin-control(true, false, 'l');
    padding: 0;

    .component-header {
      @include breakpoint($desktop) {
        text-align: left;
      }
    }

    #{$self} {
      &__tiles {
        background-color: $gray;
        display: flex;
        flex-flow: column nowrap;
        padding: $spacing-unit;

        @include breakpoint($tablet) {
          flex-flow: row wrap;
        }

        @include breakpoint($desktop) {
          padding: $spacing-unit--md $spacing-unit;
        }
      }

      &__tile {
        @include breakpoint($tablet) {
          flex: 1 1 33.33333%;
        }

        .resource-tile {
          margin: 0 auto;
          max-width: 252px;

          @include breakpoint($tablet) {
            max-width: 172px;
          }

          @include breakpoint($desktop) {
            max-width: 279px;
          }
        }
      }
    }
  }

  &--5-across {
    background-color: $gray;
    padding-left: $spacing-unit;
    padding-right: $spacing-unit;

    #{$self} {
      &__tiles {
        display: flex;
        flex-flow: column nowrap;

        @include breakpoint($desktop) {
          flex-flow: row wrap;
          justify-content: center;
        }
      }

      &__tile {
        @include breakpoint($desktop) {
          flex: 0 0 20%;
          margin: $spacing-unit--mdl 0;
          max-width: calc(20% - #{$spacing-unit--ss * 2});
        }

        @include breakpoint($large-desktop) {
          margin-left: 0;
          margin-right: 0;
        }

        .resource-tile {
          @include breakpoint($desktop) {
            margin: 0 auto;
            max-width: 224px;
          }
        }
      }
    }
  }

  &--list {
    // Mobile Styles
    @include breakpoint(0 $tablet--max) {
      @include resourceTileListMobile();
    }

    // Tablet Styles
    @include breakpoint($tablet $desktop--max) {
      @include resourceTileListTablet();
    }

    // Mobile & Tablet Styles
    @include breakpoint(0 $desktop--max) {
      @include resourceTileList();
    }
  }

  &--no-icons {
    // Mobile & Tablet Styles
    @include breakpoint(0 $desktop--max) {
      @include resourceTileList();
      @include resourceTileListMobile();

      .resource-tile {
        &__description,
        &__title {
          float: none;
          width: 100%;
        }

        &__description {
          display: block;
        }
      }
    }
  }
}
