form[id*=mktoForm] {
  display: none;

  .mktoButtonRow {
    display: flex;
  }
}

.marketo {
  &--inputs {
    display: flex;
    flex-flow: column;

    label {
      @include hidden;
    }

    input {
      padding: 1.4rem;
    }

    &.success {
      display: none;
    }
  }

  &--signup__success,
  &--signup__error {
    display: none;
    outline: none;
    text-align: center;
    visibility: hidden;

    &.active {
      display: block;
      visibility: visible;
    }
  }

  &__label {
    opacity: 0;
    margin: 0 0 $spacing-unit--half;
    transform: translateY(10px);
    transition: all .2s ease-in;
    will-change: opacity, transform;

    &.active {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
