// =======================================
//    #CORE - BREAKPOINTS
// =======================================
$large-mobile: 400px;
$large-mobile--max: $large-mobile - 1;
$tablet: 600px;
$tablet--max: $tablet - 1;
$large-tablet: 800px;
$large-tablet--max: $large-tablet - 1;
$desktop: 1000px;
$desktop--max: $desktop - 1;
$large-desktop: 1300px;
$large-desktop--max: $large-desktop - 1;


// maxPageWidth is a px value intentionally
// otherwise the .container does not work properly
$maxPageWidth: 1440px;

@include add-breakpoint('large-mobile', $large-mobile);
@include add-breakpoint('tablet', $tablet);
@include add-breakpoint('large-tablet', $large-tablet);
@include add-breakpoint('desktop', $desktop);
@include add-breakpoint('large-desktop', $large-desktop);
$breakpoint-has-widths: ('mobile', 'tablet', 'desktop');
