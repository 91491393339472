// =======================================
//    #CORE/HTML - LINKS
// =======================================

%arrow-link {
  position: relative;
  font-weight: 400;
  text-decoration: none;

  // .icon {
  //   display: inline-block;
  //   font-size: .7em;
  //   padding-left: $spacing-unit--half;
  //   transition: transform .2s cubic-bezier(0, -1, 0, 2);
  // }

  .icon {
    display: none;
  }

  &:before {
    background-color: currentColor;
    bottom: 0;
    content: ' ';
    height: 1px;
    left: 0;
    position: absolute;
    transition: width .2s ease-in;
    width: 0;
    will-change: width;
  }

  &:after {
    @extend %mgh-icons;
    @extend %mgh-icons-angle-right;
    font-size: .7em;
    font-weight: 900;
    padding-left: $spacing-unit--half;
  }

  &:hover {
    &:before {
      width: 100%;
    }
  }

  // &:hover,
  // &:focus {
  //   .icon {
  //     transform: translateX(50%);
  //   }
  // }
}

%no-click-hover {
  @media (hover: hover) {
    &:active {
      pointer-events: none;
    }
  }
}

%link {
  color: $blue-medium;
  transition: $linkTransition;
  transition-property: color, background-color;

  &:hover {
    text-decoration: none;
  }
}

a {
  @extend %link;

  &.no-click-hover {
    @extend %no-click-hover;
  }
}

.arrow-link {
  @extend %arrow-link;
}
