.media-coverage-area {
  margin: $spacing-unit--ll 0;

  @include breakpoint($desktop) {
    margin: $spacing-unit--xll 0 $spacing-unit--l;;
  }

  &__header {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-top: $spacing-unit--md;

    h2,
    a {
      display: block;
      margin: 0 0 $spacing-unit--md;
    }

    h2 {
      margin-right: $spacing-unit--md;
    }
  }

  &__items {
    .listing-item {
      margin: 0 0 $spacing-unit;

      &:last-child {
        margin: 0;

        @include breakpoint(0 $desktop) {
          border-bottom: 0;
        }
      }
    }
  }
}
