.filters-expandable {
  max-width: 800px;

  @include breakpoint($desktop) {
    @include margin-control(false, false, 'll');
    border: 0;
    border-radius: 0;
  }

  summary {
    font-weight: 700;

    @include breakpoint($desktop) {
      display: none;
    }
  }

  .filters-display {
    &__inner {
      padding: $spacing-unit;
    }
  }

  &--hidden-desktop {
    display: block;

    @include breakpoint($desktop) {
      display: none;
    }
  }
}
