// =======================================
//    #UTILITY - LISTS
//
// Mixins for common list styles
// =======================================

// =============================================================================
// Unstyled List
// =============================================================================
@mixin list-unstyled {
  padding-left: 0;
  list-style: none;
  margin: 0;
}

// =============================================================================
// Inline List
// =============================================================================
@mixin list-inline {
  @include list-unstyled;
  display: flex;
}
