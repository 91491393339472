.cta-block {
  $self: &;
  background-color: $blue-lighter;
  padding: $spacing-unit $spacing-unit $spacing-unit--l;

  @include breakpoint($tablet) {
    margin-bottom: $spacing-unit;
    margin-top: $spacing-unit;
    padding-bottom: $spacing-unit--l;
    padding-top: $spacing-unit--l;

    &__inner {
      align-items: center;
      display: flex;
    }
  }

  .cta__title {
    margin: $spacing-unit 0;
    padding-bottom: $spacing-unit--ss;
    position: relative;

    @include breakpoint($tablet) {
      margin: $spacing-unit--md 0;
      padding-bottom: $spacing-unit;
    }

    &:after {
      @include heroGraphic($blue);
      background-size: 61px;
      background-position: top left;
      bottom: 0;
      content: '';
      height: 4px;
      left: 0;
      position: absolute;
      width: $spacing-unit--l;
    }
  }

  .cta__text-container {
    flex: 0 1 75%;
    padding: 0;

    @include breakpoint($tablet) {
      padding-right: $spacing-unit--l;
    }

    @include breakpoint($desktop) {
      padding-right: $spacing-unit--xl;
    }
  }

  .cta-container {
    display: flex;
    flex-flow: column;
    flex: 1;

    .button + .button {
      margin-top: $spacing-unit--half;
    }
  }

  .cta-sidebar & {
    @include margin-control(true, false, 'md');
    background-color: $gray;
    padding: $spacing-unit $spacing-unit--l $spacing-unit--l;
    width: 100%;

    &__inner {
      display: block;
      padding: 0;
    }

    .cta__text-container {
      margin: 0;
      padding: 0;
    }

    .cta__title {
      @include font-size(20, 26);
    }

    .cta__text {
      @include font-size(15, 24);
    }
  }

  input,
  input::placeholder {
    @include font-size(16, 18);
    border: none;
    color: $blue-medium;
    font-weight: 400;
    opacity: 1;
  }

  &__tiles {
    [class*='col'] {
      &:not(:last-child) {
        border-bottom: 1px solid $gray-alt;

        @include breakpoint($tablet) {
          border-bottom: 0;
          border-right: 1px solid $gray-alt;
        }
      }
    }
  }

  &__tile {
    margin: 0 auto;
    max-width: 290px;
    padding: $spacing-unit--l 0 $spacing-unit--md;
    text-align: center;

    @include breakpoint($tablet) {
      padding: $spacing-unit--mdl $spacing-unit--md;
    }

    @include breakpoint($desktop) {
      max-width: 350px;
      padding: $spacing-unit 0 $spacing-unit--md;
    }

    h2 {
      font-size: 2rem;
      font-weight: 200;
      letter-spacing: -.4px;
      line-height: 1;
      margin: 0 0 $spacing-unit--ss;

      @include breakpoint($tablet) {
        font-size: 1.8rem;
      }

      @include breakpoint($desktop) {
        font-size: 2.6rem;
      }

      @include breakpoint($large-desktop) {
        font-size: 3rem;
      }
    }

    p {
      font-weight: 300;
      font-size: 1.6rem;
      line-height: 1.5;
      margin: $spacing-unit--ss 0;

      @include breakpoint($tablet) {
        font-size: 1.3rem;
        margin-bottom: $spacing-unit;
      }

      @include breakpoint($desktop) {
        font-size: 1.8rem;
      }
    }

    a {
      font-size: 1.4rem;

      @include breakpoint($desktop) {
        font-size: 1.6rem;
      }

      .icon {
        display: none;

        @include breakpoint($desktop) {
          display: block;
        }
      }
    }
  }

  &--tiles {
    background-color: $gray;
    margin-bottom: $spacing-unit;
    margin-top: $spacing-unit;

    #{$self} {
      &__inner {
        display: block;
      }
    }
  }
}
