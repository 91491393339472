.condition-intro {

  &__inner {
    margin-bottom: $spacing-unit--xl;

    &.centered {
      text-align: center;
    }
  }

  &__subheading {
    @include font-size(30, 38);
  }
}
