.search-input {
  margin-bottom: $spacing-unit;

  button {
    display: block;
    height: 4.2rem;
    font-size: 1.2rem;
    line-height: 4.2rem;
    min-width: 0;
    padding: 0;
    text-align: center;
    width: 4.2rem;
  }

  input {
    margin: 0;
    width: 100%;
  }

  &--has-addon {
    display: flex;

    input {
      flex: 1;
      margin: 0 .2rem 0 0;
    }

    button {
      flex: 0 0 4.2rem;
    }
  }
}
