.global-header {
  $boxShadowHeight: 2.121px;
  width: 100%;
  z-index: index($globalIndex, globalHeader);

  @mixin logoSize() {
    height: 35px;
    width: 175px;

    @include breakpoint($tablet) {
      height: 45px;
      width: 215px;
    }

    @include breakpoint($large-desktop) {
      height: 50px;
      width: 250px;
    }
  }

  *[class^='col-'] {
    position: initial; // So that the mega menu will span the whole header
  }

  &__logo {
    a {
      display: block;
      margin: $spacing-unit $spacing-unit 0;
    }

    svg,
    img {
      @include logoSize(); // IE 10 bug fix
    }
  }

  &__alert {
    z-index: index($globalIndex, globalAlert);
  }

  &__top {
    background-color: $gray;
    box-shadow: none;
    display: none;
    top: 0;
    width: 100%;
    z-index: index($globalIndex, utilityNav);

    @include breakpoint($desktop) {
      display: block;
    }

    &:after {
      content: '';
      box-shadow: $boxShadowHeight $boxShadowHeight 8px 0px rgba(64, 64, 64, .09);
      opacity: 0;
      transition: opacity .5s ease-out;
    }

    &.fixed {
      &:after {
        opacity: 1;
      }
    }
  }

  &__main {
    background-color: $white;
    box-shadow: $boxShadowHeight $boxShadowHeight 8px 0px rgba(64, 64, 64, .09);
    padding: $spacing-unit 0;
    position: relative;
    width: 100%;
    z-index: index($globalIndex, primaryNav);

    @include breakpoint($desktop) {
      padding: 0;
    }

    // If the user is moused over a menu item and the primary nav disappears due to the sticky header, make sure to hide the active mega menu
    &.scroll-fixed {
      .main-nav__item.menu-open,
      .main-nav__item:hover {
        .main-nav__link {
          &:before {
            opacity: 0;
          }
        }

        .main-nav__mega-menu {
          height: 0;
          opacity: 0;
          visibility: hidden;
        }

        .main-nav__mega-menu-inner {
          opacity: 0;
          transform: translateY(-2px);
        }
      }
    }
  }

  &__centers-nav,
  &__main {
    $transformRotation: -20deg;

    .dropdown-background {
      opacity: 0;
      margin-top: -1px; // IE 10 rendering issue
      perspective: 2000px;
      pointer-events: none;
      position: absolute;
      transition: opacity .25s, visibility 0s .25s;
      will-change: opacity;
      visibility: hidden;
      z-index: index($globalIndex, dropdownMenu);

      > div {
        height: 100%;
        transform: rotate3d(1, 0, 0, #{$transformRotation});
        transform-origin: 50% -50px;
        transition: transform .25s, opacity .2s;
        width: 100%;
      }
    }

    .main-nav__mega-menu,
    .centers-nav-menu__dropdown-menu {
      height: 0;
      opacity: 0;
      perspective: 2000px;
      pointer-events: none;
      transition: all .25s ease, visibility 0s .25s;
      visibility: hidden;
      will-change: opacity, visibility;
    }

    .main-nav__mega-menu-inner,
    .centers-nav-menu__dropdown-menu-inner {
      opacity: 0;
      transform: rotate3d(1, 0, 0, #{$transformRotation});
      transform-origin: 50% -50px;
      transition: all .25s ease, visibility 0s .25s;
      visibility: hidden;
      will-change: opacity, transform, visibility;
    }

    &.dropdown-open {
      .dropdown-background {
        opacity: 1;
        pointer-events: auto;
        transition: none;
        visibility: visible;

        > div {
          transform: none;
        }
      }

      .main-nav__item,
      .centers-nav-menu__item {
        .no-js &:hover,
        &.menu-open {
          .main-nav__mega-menu,
          .centers-nav-menu__dropdown-menu {
            height: auto;
            opacity: 1;
            pointer-events: auto;
            transition: all .25s ease, opacity 0s;
            visibility: visible;
          }

          .main-nav__mega-menu-inner,
          .centers-nav-menu__dropdown-menu-inner {
            opacity: 1;
            transform: none;
            transition: all .25s ease, opacity 0s;
            visibility: visible;
          }
        }
      }
    }

    &.dropdown-active {
      // Only when the dropdown has opened and visibile, transition if it moves
      .dropdown-background {
        transition: height .25s, transform .25s, width .25s;
      }

      // When moving from one menu to another, only transition on the opacity
      .main-nav__item.menu-open .main-nav__mega-menu-inner,
      .centers-nav-menu__item.menu-open .centers-nav-menu__dropdown-menu-inner {
        transition: opacity .1s .3s;
      }
    }
  }

  &__main {
    .dropdown-background > div {
      background-color: $gray;
    }
  }

  &__centers-nav {
    .dropdown-background > div {
      background-color: $blue-gray;
    }
  }

  &__desktop-nav,
  &__centers-nav {
    display: none;

    @include breakpoint($desktop) {
      display: block;
    }
  }

  &__centers-nav {
    width: 100vw;
    z-index: index($globalIndex, centersNav);
  }

  &__mobile-nav {
    align-items: center;
    display: flex;
    justify-content: flex-end;

    @include breakpoint($desktop) {
      display: none;
    }
  }

  &__mobile-menu {
    display: none;
    height: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    transition: all .25s ease, visibility 0s .25s;
    visibility: hidden;
    width: 100%;
    will-change: opacity, visibility;
    z-index: index($globalIndex, mobileMenu);

    &.open {
      display: block;

      &.active {
        opacity: 1;
        pointer-events: auto;
        transition: all .25s ease, opacity .1s ease;
        visibility: visible;

        .global-header__mobile-menu-inner {
          opacity: 1;
          transform: none;
          transition: all .25s ease, opacity .1s ease;
          visibility: visible;
        }
      }
    }
  }

  &__mobile-menu-inner {
    height: 100%;
    opacity: 0;
    position: relative;
    transform: rotate3d(1, 0, 0, -60deg);
    transform-origin: 50% 0;
    transition: all .25s ease, visibility 0s .25s;
    visibility: hidden;
    will-change: opacity, transform, visibility;
  }
}
