.hero-content {
  $self: &;
  position: relative;

  @mixin spanSeparator {
    display: inline-block;
    padding: 0 .5rem;
    position: relative;
    width: auto;

    @include breakpoint($tablet) {
      padding: 0 1rem;
    }

    &:first-child {
      @include breakpoint($tablet) {
        padding-left: 0;
      }
    }

    &:not(:first-child):not(:empty) {
      &:before {
        content: '\00b7';
        position: absolute;
        left: -.3rem;
      }
    }

    &:empty {
      display: none;
    }
  }

  &__inner {
    padding: $spacing-unit--md;

    @include breakpoint($tablet) {
      padding: $spacing-unit--l 0;
    }

    @include breakpoint($desktop) {
      padding: 0 $spacing-unit--xl 0 0;
    }
  }

  &__graphic {
    @include breakpoint($large-desktop) {
      @include heroGraphic();
      height: calc(100% - 6rem);
      min-height: 12rem;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      left: -9rem;
      position: absolute;
      width: 6rem;
      z-index: index($globalIndex, heroGraphic);
    }
  }

  &__eyelash {
    @include font-size(11, 11);
    display: block;
    font-weight: 700;
    letter-spacing: .25rem;
    margin: 0 0 $spacing-unit;
    text-transform: uppercase;

    @include breakpoint($large-desktop) {
      @include font-size(18, 24);
    }
  }

  &__title {
    @include font-size(25, 28);
    font-weight: 300;
    margin: $spacing-unit 0 $spacing-unit--md;

    @include breakpoint($tablet) {
      @include font-size(26, 28);
    }

    @include breakpoint($large-desktop) {
      @include font-size(50, 55);
    }

    &--hidden-tablet {
      display: block;

      @include breakpoint($tablet) {
        display: none;
      }
    }

    &--visibile-tablet {
      display: none;

      @include breakpoint($tablet) {
        display: block;
      }
    }
  }

  &__title-separator {
    @include heroGraphic($blue);
    background-size: 61px;
    height: 4px;
    margin: $spacing-unit--md auto $spacing-unit;
    width: 3.5rem;
  }

  &__description {
    @include font-size(16, 25);
    font-weight: 300;
    margin: $spacing-unit 0 0;

    @include breakpoint($large-desktop) {
      @include font-size(18, 30);
    }

    strong {
      font-weight: 700;
    }
  }

  &__logo {
    margin: auto;
    max-width: 400px;

    @include breakpoint($desktop) {
      margin: 0;
      max-width: 50%;
    }
  }

  &--event {
    &__date {
      background-color: $purple-light;
      color: $white;
      height: 69px;
      margin: 0 auto;
      padding: $spacing-unit--ss;
      position: relative;
      text-align: center;
      width: 69px;

      &:after {
        @include heroGraphic($white);
        bottom: 0;
        content: '';
        height: 7px;
        left: 0;
        margin: 0 auto;
        position: absolute;
        right: 0;
        width: 50%;

        @include breakpoint($tablet) {
          height: 10px;
        }

        @include breakpoint($desktop) {
          height: 14px;
        }
      }

      @include breakpoint($tablet) {
        flex: 0 0 96px;
        height: 96px;
        margin-right: $spacing-unit--l;
      }

      @include breakpoint($desktop) {
        flex: 0 0 122px;
        height: 122px;
        margin-right: $spacing-unit--ll;
      }

      .day,
      .month {
        display: block;
        font-weight: 700;
        text-transform: uppercase;
      }

      .day {
        font-size: 3.4rem;
        line-height: 1;

        @include breakpoint($tablet) {
          font-size: 4.6rem;
        }

        @include breakpoint($desktop) {
          font-size: 6rem;
        }
      }

      .month {
        font-size: 1.1rem;

        @include breakpoint($tablet) {
          font-size: 1.5rem;
        }

        @include breakpoint($desktop) {
          font-size: 2rem;
        }
      }
    }

    &__content {
      @include breakpoint($tablet) {
        flex: 1 1 auto;
        padding-right: 10%;
      }
    }

    #{$self} {
      &__inner {
        @include breakpoint($tablet) {
          align-items: center;
          display: flex;
        }
      }
    }
  }

  &.no-description {
    #{$self} {
      &__title {
        margin-bottom: $spacing-unit--sm;
      }

      &__title-separator {
        margin-top: $spacing-unit--sm;
      }
    }
  }

  &.has-eyelash-separators {
    #{$self} {
      &__eyelash {
        span {
          @include spanSeparator();

          &.category {
            width: 100%;

            @include breakpoint($tablet) {
              width: auto;
            }

            // &:after {
            //   content: none;

            //   @include breakpoint($tablet) {
            //     content: '\00b7';
            //   }
            // }
          }
        }
      }
    }
  }

  &.has-description-separators {
    #{$self} {
      &__description {
        span {
          @include spanSeparator();
          display: block;

          @include breakpoint($tablet) {
            display: inline-block;
          }
        }
      }
    }
  }
}
