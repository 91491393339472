.profile-detail {
  $self: &;
  margin: $spacing-unit--md 0;

  @include breakpoint($tablet) {
    margin: $spacing-unit 0 $spacing-unit--md;
  }

  @include breakpoint($desktop) {
    margin: $spacing-unit--l 0 $spacing-unit--xxl;
  }

  hr {
    &.divider {
      border-color: $gray;
    }
  }

  header {
    h2 {
      margin-bottom: $spacing-unit;
    }
  }

  &__video {
    .video-link {
      display: none;

      @include breakpoint($desktop) {
        display: inline-block;
      }
    }

    .video-button {
      display: inline-flex;
      max-width: 280px;
      width: 100%;

      @include breakpoint($desktop) {
        display: none;
      }

      .icon {
        display: block;
      }
    }
  }

  &__details {
    border: 1px solid $gray;
    margin: $spacing-unit--l 0;

    @include breakpoint($desktop) {
      margin: $spacing-unit--ll 0 $spacing-unit--lml -#{$spacing-unit--xl};
    }

    &--awards {
      border: none;
      margin-bottom: $spacing-unit--l;
      margin-top: $spacing-unit--l;

      @include breakpoint($desktop) {
        margin-bottom: $spacing-unit--lml;
      }
    }
  }

  &__section {
    padding: $spacing-unit--xs $spacing-unit $spacing-unit--md;

    @include breakpoint($desktop) {
      padding: $spacing-unit--xs 0 $spacing-unit--md $spacing-unit--xl;
    }

    @include breakpoint($tablet) {
      display: flex;
    }

    &:nth-child(even) {
      background-color: $gray;

      .read-more__toggle-wrap {
        background: linear-gradient(to bottom, rgba($gray, .5), $gray 100%);
      }

      .read-more__toggle {
        background-color: $gray;
      }
    }

    &--awards {
      background-color: $gray;
      display: block;
      padding-bottom: $spacing-unit--md;
      padding-top: $spacing-unit--ss;

      @include breakpoint($desktop) {
        padding-top: $spacing-unit--md;
      }
    }
  }

  &__section-column {
    flex: 1;

    &:first-child:nth-last-child(1),
    &:first-child:nth-last-child(1) ~ & {
      flex: 1;
    }

    &:first-child:nth-last-child(2),
    &:first-child:nth-last-child(2) ~ & {
      &:nth-child(1) {
        @include breakpoint($tablet) {
          flex: 1;
          padding-right: $spacing-unit--l;
        }
      }

      &:nth-child(2) {
        @include breakpoint($tablet) {
          border-left: 1px solid $gray;
          flex: 2;
          padding-left: $spacing-unit--l;
        }
      }
    }
  }

  &__block {
    margin: $spacing-unit--md 0 $spacing-unit--l;

    ul {
      @include list-unstyled();

      li {
        margin-bottom: $spacing-unit--xs;
      }
    }
  }

  &__title {
    font-weight: 500;

    a {
      text-decoration: none;

      &:focus,
      &:hover {
        text-decoration: underline;
      }
    }

    .icon {
      margin-left: .5em;
    }
  }

  .links-columns {
    margin: 0 0 $spacing-unit;
    padding-left: 1em;

    @include breakpoint($tablet) {
      display: flex;
      flex-flow: row wrap;
    }

    > li {
      @include breakpoint($tablet) {
        flex: 0 0 33.333333%;
        line-height: 1.3;
        max-width: 33.333333%;
        padding-right: $spacing-unit--l;
      }
    }

    // only one or two items
    li:first-child:nth-last-child(1),
    li:first-child:nth-last-child(1) ~ li,
    li:first-child:nth-last-child(2),
    li:first-child:nth-last-child(2) ~ li {
      @include breakpoint($tablet) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    ul {
      font-size: .9em;
      margin: .75rem 0;
      padding-left: $spacing-unit;
    }

    a {
      text-decoration: none;

      &:focus,
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .note {
    font-style: italic;

    em {
      font-style: normal;
    }
  }

  &__locations {
    .listing-items {
      margin: 0;

      @include breakpoint($tablet) {
        display: flex;
        flex-flow: row wrap;
      }
    }

    .listing-item {
      border-bottom: 1px solid $gray;

      @include breakpoint($tablet) {
        flex: 0 1 50%;
        max-width: 50%;
        padding-right: $spacing-unit;
        padding-top: $spacing-unit--ll;
        // padding-top: 0;
      }

      @include breakpoint($large-tablet) {
        padding-right: $spacing-unit--l;
      }

      &__title {
        line-height: 1.2;
        margin: 0 0 $spacing-unit 0;
      }

      &--location {
        &__content {
          padding: $spacing-unit--md 0;

          @include breakpoint($desktop) {
            padding: 0;
          }
        }

        &__contact-info {
          margin: 0 0 $spacing-unit--xs;
        }
      }
    }
  }

  &__research {
    .read-more {
      margin-bottom: $spacing-unit--l;

      @include breakpoint($desktop) {
        margin-bottom: $spacing-unit--lml;
      }

      > h2 {
        margin-bottom: $spacing-unit;
      }

      &__content {
        :first-child {
          margin-top: 0;
        }
      }
    }

    > .button {
      min-width: 140px;

      @include breakpoint($desktop) {
        margin-top: $spacing-unit--l;
        min-width: 190px;
      }
    }

    .listing-items {
      ul {
        padding-left: $spacing-unit--md;

        @include breakpoint($desktop) {
          padding-left: $spacing-unit--l;
        }
      }
    }
  }
}
