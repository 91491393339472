.card {
  margin: 0 auto $spacing-unit--ss;
  max-width: 340px;

  a {
    color: inherit;
    text-decoration: none;

    &:focus,
    &:hover {
      .card__title {
        text-decoration: underline;
      }
    }
  }

  &__image {
    padding-bottom: 56.25%;
    position: relative;
  }

  &__overlay {
    @extend .absolute-full;
    align-items: center;
    background-color: rgba($black, .2);
    display: flex;
    justify-content: center;
  }

  &__video-trigger {
    border-radius: 50%;
    box-shadow: 0 4.5px 9px 1px rgba($black, .2);
    color: $white;
    font-size: 4rem;
    height: 1em;
    line-height: 1;
    width: 1em;
    transition-property: box-shadow;

    &:hover,
    &:focus {
      box-shadow: 0 4.5px 9px 3px rgba($black, .4);
    }
  }

  &__body {
    padding: $spacing-unit--md 0;
  }

  &__title {
    @include font-size(17, 20);
    color: $blue-medium;
    margin: 0 0 $spacing-unit--ss;

    @include breakpoint($desktop) {
      @include font-size(18, 22);
    }
  }

  &__description {
    @include font-size(15, 22);
    margin: 0;
    text-decoration: none;

    @include breakpoint($desktop) {
      @include font-size(15, 20);
    }
  }
}

