.related-content {
  background-color: $gray;
  margin: $spacing-unit 0;
  position: relative;

  &__inner {
    padding: $spacing-unit--md 0 $spacing-unit--xl;

    @include breakpoint($tablet) {
      padding: $spacing-unit--md 0 $spacing-unit--l;
    }

    @include breakpoint($desktop) {
      padding: $spacing-unit 0 $spacing-unit--xl;
    }
  }

  .swiper-slide:not(:only-of-type) {
    .article-component {
      @include breakpoint($desktop) {
        padding-top: $spacing-unit--md;
      }
    }
  }

  .article-component {
    border-top: none;
  }
}
