.news-events-area {
  margin: $spacing-unit 0 $spacing-unit--ll;

  @include breakpoint($desktop) {
    margin: $spacing-unit 0 $spacing-unit--xll;
  }

  &__inner {
    @include breakpoint($desktop) {
      display: flex;
    }
  }

  &__box {
    overflow: hidden;
    width: 100%;

    @include breakpoint($desktop) {
      flex: 1 0 50%;
      width: 50%;
    }

    &-inner {
      padding: $spacing-unit--md $spacing-unit 0;

      @include breakpoint($tablet) {
        padding: $spacing-unit--md $spacing-unit--l 0;
      }

      @include breakpoint($desktop) {
        padding: $spacing-unit--md $spacing-unit--l 0;
      }
    }

    &--gray {
      background-color: $gray;
      padding-bottom: $spacing-unit--ll;
    }
  }

  &__box-title {
    margin-bottom: $spacing-unit--md;
  }

  &__box-cta {
    .button {
      min-width: 122px;
    }
  }

  .swiper-container {
    padding-bottom: 4.2rem;

    @include breakpoint($desktop) {
      padding-bottom: 4.8rem; // Height of the carousel controls
    }
  }

  .swiper-slide {
    height: calc(100% - 4.2rem);

    @include breakpoint($desktop) {
      height: calc(100% - 4.8rem); // Height of the carousel controls
    }
  }

  .profile-block {
    &__name {
      margin: 0 0 $spacing-unit--ss;
    }

    &__text {
      margin: $spacing-unit--ss 0 0;
    }
  }

  .article-component {
    margin-bottom: 0;
    margin-top: 0;
    padding-top: $spacing-unit;

    &:last-child {
      margin-bottom: $spacing-unit;
    }

    &__title {
      font-size: 1.6rem;

      @include breakpoint($desktop) {
        font-size: 1.8rem;
      }

      .fonts-loaded & {
        font-weight: 200;
      }
    }
  }
}
