.two-column-slide {
  $self: &;

  &__inner {
    display: flex;
    flex-direction: column;

    @include breakpoint($tablet) {
      flex-direction: row;
    }
  }

  &__left {
    width: 100%;

    @include breakpoint($tablet) {
      margin-right: 2%;
      width: 48%;
    }
  }

  &__right {
    width: 100%;

    @include breakpoint($tablet) {
      margin-left: 2%;
      width: 48%;
    }
  }

  &__media-col {
    @include breakpoint(max-width $tablet) {
      order: -1;
    }
  }

  &__content-col {
    @include breakpoint(max-width $tablet) {
      margin-top: $spacing-unit--l;
    }
  }

  .carousel-media {
    &__inner {
      height: 100%;
    }
  }

  &--regular {
    #{$self} {
      &__inner {
        align-items: flex-start;
      }
    }
  }

  &--cd {
    #{$self} {
      &__content-col {
        // On the expanded view align the inner content vertically within the column
        @include breakpoint($tablet) {
          align-items: center;
          display: flex;
        }
      }
    }
  }
}
