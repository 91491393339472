.social-follow {
  background-color: $blue-lighter;
  margin: $spacing-unit 0;
  padding: $spacing-unit--l;

  @include breakpoint($desktop) {
    padding: $spacing-unit--xl;
  }

  &__inner {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    @include breakpoint($desktop) {
      justify-content: flex-start;
    }
  }

  &__text {
    text-align: center;

    h2 {
      margin-top: 0;
    }

    @include breakpoint($desktop) {
      margin-right: $spacing-unit--l;

      h2 {
        margin: 0;
      }
    }
  }

  .sharing {
    display: block;

    &__icon {
      background-color: #fff;
      border: 0;
      font-size: 1em;
    }

    &__services {
      flex-flow: row wrap;

      @include breakpoint($tablet) {
        flex-flow: row nowrap;
      }

      > li {
        margin: $spacing-unit--half $spacing-unit;

        &:last-of-type {
          margin-right: $spacing-unit;
        }
      }
    }
  }
}
