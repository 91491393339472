.full-width-slide {
  &__caption,
  &__caption p {
    @include font-size(12, 16);
    font-style: italic;
    font-weight: 300;

    @include breakpoint($tablet) {
      @include font-size(13, 18);
    }

    @include breakpoint($desktop) {
      @include font-size(14, 20);
    }
  }

  &__caption {
    margin: 0 auto;
    max-width: 90%;
    min-height: 48px;
    text-align: center;

    @include breakpoint($tablet) {
      max-width: none;
      padding-right: 20rem;
      text-align: initial;
    }
  }

  .carousel-media {
    &__inner {
      height: 100%;
    }
  }
}
