.video-article-area {
  @extend .media-coverage-area;
  margin-bottom: $spacing-unit;

  @include breakpoint($desktop) {
    margin-bottom: $spacing-unit;
  }

  &__header {
    margin-bottom: $spacing-unit--l;

    @include breakpoint($tablet) {
      align-items: center;
      display: flex;
      flex-flow: row wrap;
      margin-bottom: $spacing-unit--ll;
    }
    h2 {
      margin-bottom: $spacing-unit;

      @include breakpoint($tablet) {
        margin-bottom: 0;
        margin-right: $spacing-unit;
        margin-top: 0;
      }
    }
  }

  .article-component--image-card {
    padding: 0 0 $spacing-unit--l;

    @include breakpoint($desktop) {
      padding: 0 0 $spacing-unit--ll;
    }
  }
}
