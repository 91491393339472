.listing-items {
  &__header {
    @extend %location-listing-heading;
    margin-bottom: 0;
    margin-left: .5rem;

    @include breakpoint($tablet) {
      margin-left: 0;
    }
  }
  &--profile {
    .listing-item {
      &:first-child {
        margin-top: 0;
        padding-top: 0;
      }
    }
  }
  &--location {
    margin: $spacing-unit--l 0 $spacing-unit--md;

    @include breakpoint($desktop) {
      margin: $spacing-unit 0 $spacing-unit--l;
    }

    @include breakpoint($large-desktop) {
      margin: $spacing-unit 0 $spacing-unit--xl;
    }
  }
}

.listing-results {
  font-size: 1.8rem;
  margin: $spacing-unit--l 0;
}
