.inline-cta {
  background-color: $blue-lighter;
  padding: $spacing-unit;

  @at-root .main-content .content & {
    margin: $spacing-unit--ss 0;

    @include breakpoint($tablet) {
      margin: $spacing-unit 0;
      padding: 0 $spacing-unit--l;
    }

    @include breakpoint($desktop) {
      margin: $spacing-unit--md 0 $spacing-unit--md -#{$spacing-unit--xl};
      padding: 0 $spacing-unit--l 0 $spacing-unit--xl;
    }
  }

  a {
    align-items: center;
    color: $blue-dark;
    display: flex;
    justify-content: space-between;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
      .button {
        background-color: $blue-text;
        border-color: $blue-dark;
        color: $white;
        outline: 0;

        .icon {
          transform: none;
        }
      }

      .inline-cta__text {
        color: $blue-text;
        text-decoration: underline;
      }
    }
  }

  &__text {
    flex: 0 1 90%;
    margin: 0;
    padding: $spacing-unit $spacing-unit--l $spacing-unit 0;

    @include breakpoint($desktop) {
      margin: $spacing-unit 0;
    }
  }

  .cta-container {
    display: flex;
    flex-flow: column wrap;
    margin: $spacing-unit 0;
  }

  .button {
    max-width: 40px;
    padding: $spacing-unit--ss $spacing-unit;

    .text:empty {
      display: none;
    }

    .text:empty + .icon {
      margin: 0;
      width: 100%;
    }
  }

  .button + .button {
    margin-top: $spacing-unit;
  }
}
