blockquote {
  font-family: $systemSerif;
  font-size: 1.9rem;
  font-style: italic;
  font-weight: 400;
  margin: $spacing-unit--l $spacing-unit;
  padding-left: $spacing-unit;
  padding-right: $spacing-unit;
  position: relative;

  @include breakpoint($tablet) {
    font-size: 3rem;
    margin: $spacing-unit--xl 0;
    padding-left: $spacing-unit--xl;
  }

  .fonts-loaded & {
    font-family: $font-heading;
    font-weight: 300;
  }

  > :only-child {
    display: inline-block;
  }

  > :first-child,
  > :last-child {
    display: inline-block;
    position: relative;

    &:before,
    &:after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='37'%3E%3Cpath fill='%2301B9E8' fill-rule='evenodd' d='M13.025 35.959c.698-.398 1.399-1.2 2.1-2.4l1.95-3.3v-1.5l-4.05-2.7c-1.8-1.099-3.052-2.299-3.75-3.6-.701-1.298-1.05-2.7-1.05-4.2 0-3.6 1.35-6.75 4.05-9.45 2.7-2.7 6.499-4.699 11.4-6v-2.7c-5.002 1.102-9.202 2.951-12.6 5.55-3.401 2.602-5.951 5.475-7.65 8.625-1.702 3.15-2.55 6.026-2.55 8.625 0 3.202.6 5.7 1.8 7.5 1.2 1.8 2.899 3.551 5.1 5.25.698.401 1.275.652 1.725.75.45.101.923.15 1.425.15.698 0 1.399-.199 2.1-.6zm26.25 0c.698-.398 1.399-1.2 2.1-2.4l1.95-3.3v-1.5l-4.05-2.7c-1.8-1.099-3.052-2.299-3.75-3.6-.701-1.298-1.05-2.7-1.05-4.2 0-3.6 1.35-6.75 4.05-9.45 2.7-2.7 6.499-4.699 11.4-6v-2.7c-5.002 1.102-9.202 2.951-12.6 5.55-3.401 2.602-5.951 5.475-7.65 8.625-1.702 3.15-2.55 6.026-2.55 8.625 0 3.202.6 5.7 1.8 7.5 1.2 1.8 2.899 3.551 5.1 5.25.698.401 1.275.652 1.725.75.45.101.923.15 1.425.15.698 0 1.399-.199 2.1-.6z'/%3E%3C/svg%3E");
      background-size: contain;
      content: '';
      display: inline-block;
      height: 1.5em;
      pointer-events: none;
      position: absolute;
      width: 1.5em;
    }
  }

  > :first-child {
    margin-top: 0;

    &:before {
      transform: translate3d(-125%, -25%, 0);
    }
    &:after {
      display: none;
    }
  }

  > :last-child {
    margin-bottom: 0;

    &:after {
      transform: translate3d(25%, 25%, 0) rotate(180deg);
    }
    &:before {
      display: none;
    }
  }

  > :only-child {
    &:before,
    &:after {
      display: inline;
    }
  }

  + cite {
    display: block;
    font-style: normal;
    font-weight: 400;
    margin-bottom: $spacing-unit--l;
    margin-top: $spacing-unit--l;
    padding-left: $spacing-unit--l;
    padding-right: $spacing-unit--l;

    @include breakpoint($tablet) {
      margin-top: -#{$spacing-unit--l};
      padding-left: $spacing-unit--xl;
      padding-right: $spacing-unit--xl;
    }
  }
}

.blockquote {
  margin: $spacing-unit 0;
  padding: $spacing-unit;

  &--gray {
    background-color: $gray;
  }

  @include breakpoint($tablet) {
    margin: $spacing-unit 0;

    blockquote {
      margin-left: 0;
      margin-top: $spacing-unit--l;
    }
  }

  &--centered {
    text-align: center;
  }

  &__cite {
    &-name {
      font-size: 1.4rem;

      @include breakpoint($tablet) {
        font-size: 1.9rem;
      }
    }

    &-title {
      font-weight: 300;
    }
  }

  &__cta {
    .button {
      font-size: 1.4rem;

      @include breakpoint($tablet) {
        font-size: 1.6rem;
      }
    }
  }
}

