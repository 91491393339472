.breadcrumbs {
  background-color: $blue-dark;
  padding: 1rem $spacing-unit;

  @include breakpoint($desktop) {
    background-color: transparent;
    padding: 1rem 0;
  }

  &__inner {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__pages {
    overflow: hidden;
    width: calc(100% - 60px);

    @include breakpoint($desktop) {
      width: 100%;
    }
  }

  &__sharing {
    background-color: transparent;
    height: 32px;
    width: 32px;

    @include breakpoint($desktop) {
      display: none;
    }
  }
}
