// =======================================
//    #CORE/HTML - TYPOGRAPHY
// =======================================

// =============================================================================
// Font Smoothing
//
// `@extend %font-smoothing` when using custom fonts over dark backgrounds
// =============================================================================
%font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

strong {
  font-weight: 700;
}

.lede {
  @include font-size(30, 38);
}
