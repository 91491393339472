.location-detail {
  @include margin-control(true, false, 'l');

  @include breakpoint($desktop) {
    margin-top: 0;
  }
  .read-more {
    padding-bottom: 6rem;

    @include breakpoint($tablet) {
      padding-bottom: 3rem;
    }

    &__toggle-wrap {
      &--button {
        height: 10rem;
      }
    }

    &.expanded {
      padding-bottom: 0;
    }
  }

  .about {
    @extend .component-header;
    margin-bottom: $spacing-unit--lml;

    h2 {
      margin-bottom: $spacing-unit--md;
    }

    p {
      margin-top: 0;
    }

    .button {
      min-width: 122px;
    }
  }

  .affiliates {
    margin: $spacing-unit--lml 0 $spacing-unit--l;

    @include breakpoint($desktop) {
      margin: $spacing-unit--xl 0 $spacing-unit--l;
    }

    h5 {
      margin: 0 0 $spacing-unit;

      .fonts-loaded & {
        font-family: $font-body;
        font-weight: 500;
      }
    }

    .lists {
      margin-bottom: $spacing-unit--md;

      @include breakpoint($desktop) {
        display: flex;
      }

      ul {
        margin: 0;
        padding-left: 2rem;

        @include breakpoint($desktop) {
          flex: 1 1 auto;
        }
      }
    }

    .button {
      min-width: 122px;
    }
  }

  .affiliates + .divider {
    @include breakpoint($desktop) {
      margin-bottom: $spacing-unit--l;
      margin-top: $spacing-unit--l;
    }
  }

  .services {
    margin: $spacing-unit--lml 0 $spacing-unit--l;

    > h2 {
      margin: 0 0 $spacing-unit--lml;
    }

    details {
      .button {
        display: inline-block;

        @include breakpoint($desktop) {
          display: none;
        }
      }

      .arrow-link {
        display: none;

        @include breakpoint($desktop) {
          display: inline-block;
        }
      }
    }
  }

  .inline-cta {
    margin: $spacing-unit--l 0 $spacing-unit--lml;
  }

  .directions {
    margin: $spacing-unit--lml 0;

    > h2 {
      margin: 0 0 $spacing-unit--l;
    }

    &__content {
      background-color: $gray;
      padding: $spacing-unit--half;
    }

    &__details {
      padding: 0 $spacing-unit--l;

      ul {
        @include list-unstyled();
        @extend %clearfix;
        padding: .1px 0;

        li {
          margin: $spacing-unit--lml 0;

          @include breakpoint($tablet) {
            margin: $spacing-unit--l 0;
          }
        }
      }
    }

    .detail {
      @include breakpoint($tablet) {
        display: flex;
        width: 100%;
      }

      .icon {
        color: $blue-treatment-info;
        display: block;
        font-size: 4rem;
        line-height: 1;

        @include breakpoint($tablet) {
          font-size: 1.4rem;
          flex: 0 0 15px;
          line-height: 2.2;
          margin-right: $spacing-unit--md;
        }
      }

      .text {
        @include font-size(16, 24);
        color: $blue-dark;

        @include breakpoint($tablet) {
          @include font-size(18, 30);
          display: block;
          flex: 0 1 auto;
          max-width: 100%;
        }
      }

      a {
        text-decoration: none;

        &:focus,
        &:hover {
          text-decoration: underline;
        }
      }

      .phone {
        @extend %no-click-hover;
      }
    }
  }

  .open-text {
    margin: $spacing-unit--lml 0;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1;
      margin-bottom: $spacing-unit;

      .fonts-loaded & {
        font-family: $font-body;
        font-weight: 500;
      }
    }
  }
}
