.pagination {
  @include margin-control(false, false, 'md');

  @include breakpoint($desktop) {
    @include margin-control(false, false, 'l');
  }

  &__inner {
    padding: $spacing-unit 0;
  }

  &__items {
    @include list-unstyled;
    align-content: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0;
  }

  &__page-count {
    @include font-size(14,25);
    align-self: center;
    background-color: $blue-dark;
    color: white;
    display: inline-block;
    font-weight: bold;
    height: 4.8rem;
    padding: 0 2rem;
    text-align: center;
    width: calc(100% / 3);

    @include breakpoint($tablet) {
      width: auto;
    }

    @include breakpoint($desktop) {
      display: none;
    }

    span {
      line-height: 4.8rem;
      vertical-align: middle;
    }
  }

  &__item {
    @include font-size(14, 25);
    background-color: $gray;
    display: inline-block;
    font-weight: bold;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    transition: background-color .3s ease-in-out;

    @include breakpoint($desktop) {
      margin: 0 2px;
    }

    a {
      align-items: center;
      color: $blue-dark;
      display: flex;
      height: 4.8rem;
      justify-content: center;
      text-decoration: none;
      width: 4.8rem;
    }

    &--previous,
    &--next {
      @include font-size(10, 23);
      background-color: white;
      display: inline-block;
      font-weight: bold;
      letter-spacing: .2em;
      position: relative;
      width: calc(100% / 3);

      @include breakpoint($tablet) {
        width: 5.5rem;
      }

      @include breakpoint($desktop) {
        @include font-size(10,25);
        background-color: $blue-dark;
        width: 13.5rem;

        &:hover {
          background-color: $blue-text;
        }
      }

      a {
        color: white;
        width: auto;

        &:focus {
          outline-color: $white;
        }
      }

      span.nav-title {
        display: none;

        @include breakpoint($desktop) {
          display: inline-block;
        }
      }

      svg {
        font-size: 2rem;
        vertical-align: middle;

        @include breakpoint($desktop) {
          font-size: inherit;
          transition: transform .5s .125s cubic-bezier(0, -1, 0, 2);
          vertical-align: -.125em;
          will-change: transform;
        }

        path {
          fill: $blue-dark;

          @include breakpoint($desktop) {
            fill: currentColor;
          }
        }
      }
    }

    &--previous {
      margin-left: 0;

      &:after {
        background-color: $gray-light;
        bottom: .5rem;
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: .5rem;
        width: 1px;

        @include breakpoint($desktop) {
          display: none;
        }
      }

      svg {
        margin-right: 0;

        @include breakpoint($desktop) {
          margin-right: 1rem;
        }
      }
    }

    &--next {
      margin-right: 0;

      svg {
        margin-left: 0;

        @include breakpoint($desktop) {
          margin-left: 1rem;
        }
      }
    }

    &.is-active {
      background: $blue-lightest;
      display: none;

      @include breakpoint($desktop) {
        display: inline-block;
      }

      .hidden {
        display: inline;
        height: auto;
        left: auto;
        position: relative;
        width: auto;

        @include breakpoint($tablet) {
          height: 1px;
          left: -10000px;
          position: absolute;
          top: auto;
          width: 1px;
        }
      }

      &.disabled {
        a {
          color: gray;
          cursor: not-allowed;
          pointer-events: none;
        }
      }
    }

    &--page {
      display: none;

      @include breakpoint($desktop) {
        display: inline-block;

        &:hover {
          background-color: $blue-lightest;
        }

      }
    }

  }
}
