.page-anchors {
  $self: &;
  height: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s ease-in-out;

  &.active {
    height: auto;
    opacity: 1;
    visibility: visible;
  }

  .no-js & {
    display: none;
  }

  .content & {
    margin-bottom: $spacing-unit--xl;
    margin-left: -#{$spacing-unit--xl};
  }

  &__inner {
    background-color: $blue-dark;
    padding: $spacing-unit * 1.06;

    @include breakpoint($tablet) {
      padding: $spacing-unit--l $spacing-unit * 2.22;
    }

    @at-root .content & {
      padding-left: $spacing-unit--xl;
    }
  }

  &__title {
    @include font-size(22, 38);
    color: $white;
    font-weight: 300;
    margin: $spacing-unit 0 $spacing-unit * 1.8;

    @include breakpoint($tablet) {
      @include font-size(30, 38);
      margin: $spacing-unit 0 $spacing-unit * 1.8;
    }
  }

  &__links {
    @include list-unstyled();

    > li {
      @include margin-control(false, '');
    }

    @include breakpoint($tablet) {
      display: flex;
      flex-wrap: wrap;

      > li {
        @include margin-control(false, '', 'half');
        flex: 0 0 calc(100% / 3);
      }
    }
  }

  &__link {
    @extend %arrow-link;

    @include font-size(16, 29);
    color: $white;

    &:after {
      @include font-size(10, 25);
      color: $blue;
      vertical-align: middle;
    }
  }
}
