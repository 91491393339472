.spotlight--3-across {
  $self: &;

  &__inner {
    padding: $spacing-unit--md 0;

    @include breakpoint($desktop) {
      padding: $spacing-unit--l 0;
    }
  }


  .component-header {
    margin: $spacing-unit--l auto;
    max-width: 979px;
    text-align: left;

    @include breakpoint($desktop) {
      margin: $spacing-unit--l + 1 auto;
      text-align: center;
    }

    h2 {
      margin-top: 0;
    }
  }

  &__cards {
    @include breakpoint($tablet) {
      display: flex;
      flex-flow: row wrap;
      margin: 0 -($spacing-unit--half);
    }

    @include breakpoint($large-tablet) {
      flex-wrap: nowrap;
    }

    @include breakpoint($desktop) {
      margin: 0 -($spacing-unit);
    }
  }

  &__card {
    flex: 1;
    margin: 0 0 $spacing-unit;

    @include breakpoint($tablet) {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0 $spacing-unit--half;
    }

    @include breakpoint($large-tablet) {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }

    @include breakpoint($desktop) {
      padding: 0 $spacing-unit;
    }

    .spotlight-card {
      height: 100%;
      margin: 0;

      &__inner {
        height: 100%;
      }

      &__content {
        @include breakpoint($tablet) {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: space-between;
        }
      }

      &__footer {
        @include breakpoint($tablet) {
          margin: 0;
        }
      }

      &--statistics-number {
        .spotlight-card__header {
          @include breakpoint($tablet) {
            height: 73px;
          }
        }

        .spotlight-card__content {
          @include breakpoint($tablet) {
            height: calc(100% - 73px);
          }
        }
      }

      &--basic {
        @include breakpoint($large-tablet) {
          padding: $spacing-unit--ll $spacing-unit $spacing-unit--md;
        }
      }

      &--statistics-percentage {
        .spotlight-card__heading-row {
          @include breakpoint($tablet) {
            display: block;
          }

          @include breakpoint($desktop) {
            display: flex;
          }
        }

        .spotlight-card__percentage {
          max-width: 12rem;
          width: auto;

          @include breakpoint($tablet) {
            margin: 0 auto $spacing-unit;
            max-width: 15rem;
            width: 100%;
          }

          @include breakpoint($desktop) {
            margin: 0 $spacing-unit 0 0;
            width: 40%;
          }
        }

        .spotlight-card__percentage-text {
          @include breakpoint($tablet) {
            font-size: 2.4rem;
          }
        }

        .spotlight-card__heading {
          @include breakpoint($desktop) {
            font-size: 2rem;
          }
        }
      }
    }
  }

  &.gray-bg {
    background-color: $gray;

    #{$self} {
      &__inner {
        padding: $spacing-unit--md 0;

        @include breakpoint($tablet) {
          padding: $spacing-unit--l 0;
        }

        @include breakpoint($desktop) {
          padding: $spacing-unit--lml 0;
        }
      }
    }

    .spotlight-card {
      background-color: $white;
    }
  }
}
