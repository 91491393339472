.hero-location {
  address {
    font-style: normal;
  }

  p,
  time {
    @include font-size(16, 25);
    margin: 0;

    @include breakpoint($large-desktop) {
      @include font-size(18, 30);
    }

    .phone {
      font-weight: 700;
      margin: 0 0 $spacing-unit--half;

      @include breakpoint($desktop) {
        margin: 0;
      }

      a {
        @extend .no-click-hover;
      }
    }
  }

  time {
    display: block;
    font-style: italic;
  }

  a {
    text-decoration: none;
  }
}
