.homepage-articles {
  margin: $spacing-unit 0;

  @include breakpoint($desktop) {
    margin: 0;
  }

  &__inner {
    @include breakpoint($desktop) {
      display: flex;
      flex-flow: row nowrap;
    }
  }

  &__feed {
    padding: 0 $spacing-unit $spacing-unit--lml;
    width: 100%;

    @include breakpoint($tablet) {
      padding: $spacing-unit--lml $spacing-unit--l;
    }

    @include breakpoint($desktop) {
      padding: $spacing-unit--ll;
      width: 50%;
    }

    > h2 {
      font-size: 2rem;
      font-weight: 300;
      margin: 0 0 $spacing-unit--md;
      position: relative;

      @include breakpoint($desktop) {
        font-size: 3rem;
        margin: 0 0 $spacing-unit--l;
      }

      &:before {
        @include breakpoint($desktop) {
          @include heroGraphic();
          content: '';
          height: 6rem;
          height: calc(100% + #{$spacing-unit});
          left: -84px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 6rem;
        }
      }
    }

    > ul {
      @include list-unstyled();

      > li {
        &:not(:last-child) {
          border-bottom: 1px solid $gray-alt;
          margin-bottom: $spacing-unit--md;
        }

        @include breakpoint($tablet) {
          margin-bottom: $spacing-unit--l;
        }
      }
    }

    > .button {
      font-size: 1.4rem;
      line-height: 1.1;
      min-width: 142px;

      @include breakpoint($desktop) {
        font-size: 1.6rem;
        min-width: 182px;
      }
    }
  }

  &__featured {
    margin: 0 -($spacing-unit);
    width: calc(100% + #{$spacing-unit * 2});

    @include breakpoint($tablet) {
      display: flex;
      margin: 0;
      width: 100%;
    }

    @include breakpoint($desktop) {
      display: block;
      position: relative;
      width: 50%;
    }

    .image {
      width: 100%;

      @include breakpoint($tablet) {
        order: 1;
        width: 55%;
      }

      @include breakpoint($desktop) {
        height: 100%;
        width: 100%;
      }
    }

    .image-inner {
      height: 100%;
      padding-bottom: 75%;
      position: relative;

      img {
        @include breakpoint($tablet) {
          object-fit: cover;
        }
      }
    }

    .content {
      background-color: $blue-lightest;
      color: $blue-dark;
      padding: $spacing-unit--lml $spacing-unit--l $spacing-unit--ll;

      @include breakpoint($tablet) {
        order: 0;
        padding: $spacing-unit--ll $spacing-unit--l $spacing-unit--md;
        width: 45%;
      }

      @include breakpoint($desktop) {
        left: 0;
        padding: $spacing-unit--ll $spacing-unit--l;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 50%;
      }

      @include breakpoint($large-desktop) {
        max-width: 320px;
        padding: $spacing-unit--ll $spacing-unit--lml;
        width: 320px;
      }

      h4 {
        font-size: 1.8rem;
        font-weight: 300;
        line-height: 1.2;
        margin: 0 0 $spacing-unit--ss;

        @at-root .fonts-loaded & {
          font-family: $font-heading;
        }

        @include breakpoint($tablet) {
          font-size: 2rem;
          margin: 0 0 $spacing-unit--md;
        }

        @include breakpoint($desktop) {
          font-size: 2.2rem;
        }
      }

      p {
        font-size: 1.6rem;
        margin: 0 0 $spacing-unit;

        @include breakpoint($tablet) {
          margin: 0 0 $spacing-unit--md;
        }

        &.featured {
          font-size: 1.2rem;
          font-weight: 700;
          letter-spacing: 1px;
          margin: 0 0 $spacing-unit--md;

          @include breakpoint($tablet) {
            font-size: 1.4rem;
            margin: 0 0 $spacing-unit--l;
          }
        }
      }

      .button {
        border-width: 2px;
        font-size: 1.4rem;
        min-width: 120px;

        @include breakpoint($tablet) {
          min-width: 155px;
        }

        @include breakpoint($desktop) {
          font-size: 1.6rem;
          margin-left: 0;
        }
      }
    }
  }
}
