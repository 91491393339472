.input {
  input {
    min-height: 4.2rem;
    width: 100%;

    &:focus {
      outline: 2px solid $blue;
    }
  }
}
