.profile-block {
  $self: &;
  display: flex;
  margin-right: $spacing-unit--l;
  max-width: 500px;

  &__image {
    max-width: 60px;
    width: 25%;

    @include breakpoint($tablet) {
      max-width: 190px;
      width: 30%;
    }

    @include breakpoint($tablet) {
      width: 25%;
      max-width: 90px;
    }
  }

  &__image-inner {
    height: 0;
    padding-bottom: 100%;
    position: relative;
  }

  &__body {
    flex: 1 1 65%;
    font-size: 1.5rem;
    margin-left: $spacing-unit;
    margin-top: 0;
  }

  &__name {
    @include font-size(17, 23);
    font-weight: 400;
    margin: 0 0 .5rem;

    .fonts-loaded & {
      font-family: $font-body;
    }

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__text {
    @include list-unstyled();
    line-height: 1.3;
    margin-top: 0;

    li {
      display: inline;

      &:not(:last-child) {
        &:after {
          content: '\2758';
          margin-left: .5rem;
        }
      }

      &:not(:first-child) {
        margin-left: .2rem;
      }
    }
  }

  &--sidebar {
    #{$self} {
      &__image {
        max-width: 66px;
        width: 30%;

        @include breakpoint($tablet) {
          max-width: 61px;
          width: 25%;
        }
      }
      &__image-inner {
        max-height: 66px;
        max-width: 66px;

        @include breakpoint($tablet) {
          max-height: 61px;
          max-width: 61px;
        }
      }
      &__body {
        margin-left: $spacing-unit;
        padding-right: 0;
      }
      &__name {
        @include font-size(16, 20);
        margin-top: -.3rem;
        margin-bottom: $spacing-unit--xs;

        @include breakpoint($tablet) {
          margin-top: -.3rem;
        }

        @include breakpoint($desktop) {
          @include font-size(15, 20);
        }
      }
      &__text {
        @include font-size(15, 21);

        @include breakpoint($desktop) {
          @include font-size(14, 21);
        }
      }
    }
  }
}
