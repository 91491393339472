.hero-area.hero-area--homepage {
  position: relative;
  z-index: 0;

  @include breakpoint($desktop) {
    background-color: $blue-dark;
    display: grid;
    grid-template-columns: 10% 40% 10% 40%;
    grid-template-rows: auto 30vmin auto auto;
    margin-top: 0;
  }

  .hero-content {
    grid-column: 1/3;
    grid-row: 1/2;
  }

  .hero-content__inner {
    @include breakpoint($desktop) {
      padding: 0 0 0 $spacing-unit--l;
    }

    @include breakpoint($large-desktop) {
      margin-left: $spacing-unit--xl;
      padding: 0;
    }
  }

  .proof-point {
    grid-column: 2/3;
    grid-row: 3/4;

    &__content {
      grid-column: 3/5;
      grid-row: 3/5;
    }
  }

  .featured-stories {
    grid-column: 4/4;
    grid-row: 1/2;
  }

  .hero-area__image {
    grid-area: 1/1/-1/-1;
    z-index: -1;

    @include breakpoint($desktop) {
      height: 100%;
      position: absolute;
      width: 100%;

      &:after {
        background-image: radial-gradient(rgba($white, 0) 10%, rgba($black, .5));
        content: '';
        display: block;
        height: 100%;
        left: 0;
        mix-blend-mode: overlay;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }

    .hero-image {
      height: 100%;
      width: 100%;
    }

    .hero-image__image {
      padding-bottom: 0;

      img {
        position: relative;
      }
    }
  }
}

.homepage {
  .headline {
    @include breakpoint($desktop) {
      padding: $spacing-unit--xl 0;
      position: relative;
    }

    .headline__text {
      font-size: 2.8rem;
      font-weight: 300;
      line-height: 1.2;
      position: relative;

      @include breakpoint($desktop) {
        color: $white;
        font-size: 6.5rem;
        margin: 0;
      }

      &:before {
        @include breakpoint($desktop) {
          content: '';
          height: calc(100% + #{$spacing-unit});
          left: -84px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 64px;
        }
      }
    }
  }

  .hero-content .ctas {
    margin-bottom: $spacing-unit;

    @include breakpoint($desktop) {
      display: flex;
      flex-flow: row wrap;
    }

    .button {
      background-color: $white;
      color: $black-light;
      display: flex;
      line-height: 1;
      text-align: left;
      white-space: normal;
      word-break: keep-all;

      &:before,
      .icon {
        background-color: $yellow;
      }

      @include breakpoint($desktop) {
        display: inline-flex;
        margin-bottom: $spacing-unit;
        text-align: center;

        &:not(:last-child) {
          margin-right: $spacing-unit;
        }
      }
    }
  }

  .featured-stories {
    background-color: rgba($white, .9);
    padding: $spacing-unit--l;

    @include breakpoint($desktop) {
      padding: $spacing-unit--ll;
    }

    &__title {
      margin-bottom: $spacing-unit;
      margin-top: 0;
    }

    ul {
      @include list-unstyled();

      > li {
        &:not(:last-child) {
          border-bottom: 1px solid $gray-alt;
          margin-bottom: $spacing-unit--md;
        }

        @include breakpoint($tablet) {
          margin-bottom: $spacing-unit--l;
        }
      }
    }
  }

  .proof-point {
    &__statement {
      background-color: $blue-dark;
      color: $white;
      padding: $spacing-unit--l $spacing-unit;

      @include breakpoint($desktop) {
        padding: $spacing-unit--ll;
      }
    }

    h3 {
      font-size: 2.8rem;
      line-height: 1.1;
      margin: 0;

      @include breakpoint($desktop) {
        font-size: 4rem;
        line-height: 1.3;
      }
    }

    &__content {
      background-color: $gray;
      padding: $spacing-unit--ll $spacing-unit;

      @include breakpoint($desktop) {
        background-color: $white;
        padding: $spacing-unit--ll;
      }

      p {
        margin: 0 0 $spacing-unit--l;

        @include breakpoint($desktop) {
          font-size: 2rem;
        }
      }

      .button {
        margin-bottom: $spacing-unit;
        margin-right: $spacing-unit;
      }
    }
  }
}
