.contact-sidebar {
  // @include margin-control(true, false, 'md');
  margin: 0 auto $spacing-unit--md;

  @extend %font-smoothing;
  background-color: $gray;
  font-size: 1.4rem;
  padding: $spacing-unit;

  @include breakpoint($tablet) {
    padding: $spacing-unit--l;
  }

  &__cta-container {
    @include margin-control($size: 'l');

    .button {
      width: 100%;

      &:hover {
        text-decoration: none;
      }

      + .button {
        margin-top: $spacing-unit;
      }
    }
  }

  &__patients {
    h2 {
      @include font-size(20, 27);
      margin-top: 0;

      .icon {
        padding-right: $spacing-unit--half;
      }

      &.contact-sidebar__accepting {
        .icon {
          padding-right: $spacing-unit--half;

          svg {
            color: darken($blue, 5%);
            vertical-align: middle;
            width: 16px;
          }
        }
      }

      &.contact-sidebar__not-accepting {
        .icon {
          padding-right: $spacing-unit--half;

          svg {
            color: $red;
          }
        }
      }
    }
  }

  &__phone-numbers {
    &:not(:last-child) {
      margin-bottom: $spacing-unit--l;
    }

    span:not(.contact-label) {
      display: block;
    }
  }

  &__section {
    &:not(:only-child):not(:last-child) {
      margin-bottom: $spacing-unit--l;
    }

    h3 {
      .fonts-loaded & {
        font-family: $font-body;
      }

      @include font-size(14, 21);
      margin: 0;

      + p {
        margin-top: 0;
      }
    }
  }

  &__section-title {
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: .25em;
    margin-top: 0;
    text-decoration: none;
    text-transform: uppercase;
  }

  .contact-label {
    font-weight: 400;
  }

  strong {
    font-weight: 500;
  }

  a {
    font-size: 1.5rem;
    font-weight: 700;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
    }
  }

  &--cta {
    .contact-sidebar__section:not(:only-child):not(:last-child) {
      margin-bottom: 0;
    }
  }
}
