.spotlight-card {
  @include margin-control(true, false, 'md');
  $self: &;
  border-bottom-style: solid;
  border-bottom-width: 4px;
  padding: $spacing-unit * 1.66 $spacing-unit;
  width: 100%;

  @include breakpoint($tablet) {
    padding: $spacing-unit--l $spacing-unit $spacing-unit--md;
  }

  @include breakpoint($large-desktop) {
    padding: $spacing-unit--l $spacing-unit--md $spacing-unit--md;
  }

  &__content {
    position: relative;
  }

  &__header {
    align-items: center;
    display: flex;
    height: auto;

    @include breakpoint($tablet) {
      height: auto;
    }

    @include breakpoint($desktop) {
      height: auto;
    }
  }

  &__heading-row {
    @include breakpoint($desktop) {
      align-items: center;
      display: flex;
    }
  }

  &__heading {
    @include font-size(20, 25);
    margin-top: 0;
    margin-bottom: 1em;

    @at-root .fonts-loaded & {
      font-family: $font-heading;
    }

    @include breakpoint($tablet) {
      @include font-size(17, 22);
    }

    @include breakpoint($desktop) {
      @include font-size(25, 32);
    }

    @at-root .sidebar & {
      @include font-size(20, 25);
    }
  }

  &__description {
    @include font-size(16, 26);
  }

  &__footer {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: $spacing-unit--l 0 0;

    @include breakpoint($desktop) {
      display: flex;
      width: 100%;

      > * {
        width: auto;
      }
    }
  }

  &__link {
    > a {
      @include font-size(14, 16);
      text-decoration: none;
      white-space: nowrap;
    }
  }

  &__number {
    max-width: 100%;
    min-height: 4.3rem;
    position: relative;
    width: 26.5rem;
    margin: $spacing-unit 0;

    &:before {
      bottom: 0;
      background-color: $blue-dark;
      content: ' ';
      display: block;
      height: 2.2rem;
      left: 0;
      max-width: 100%;
      position: absolute;
      width: 22rem;
      z-index: 0;
    }
  }

  &__number-inner {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 255.5 28.17'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='none' d='M2.83 0H255.5v25.33H2.83z'/%3E%3C/clipPath%3E%3ClinearGradient id='b' y1='28.17' x2='1' y2='28.17' gradientTransform='matrix(252.67 0 0 -252.67 2.83 7129.45)' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23e4f163'/%3E%3Cstop offset='1' stop-color='%23e4ff63'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cpath fill='%23b1b55d' d='M2.83 0L0 2.83v25.34h252.67l2.83-2.84-2.83-22.5L2.83 0z'/%3E%3Cg clip-path='url(%23a)'%3E%3Cpath fill='url(%23b)' d='M2.83 0H255.5v25.33H2.83z'/%3E%3C/g%3E%3C/svg%3E");
    background-size: cover;
    bottom: .75rem;
    left: .75rem;
    max-height: 100%;
    max-width: 100%;
    min-height: calc(100% - .75rem);
    position: absolute;
    width: 25.5rem;
  }

  &__number-text {
    align-items: center;
    display: flex;
    font-size: 1.3rem;
    font-weight: 700;
    height: 100%;
    justify-content: center;
    letter-spacing: .15rem;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
  }

  &__percentage {
    width: 100%;

    &--mobile {
      display: block;

      @include breakpoint($tablet) {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include breakpoint($tablet) {
        display: block;
      }
    }
  }

  &__percentage-graphic {
    height: auto;
    margin: 0 auto;
    max-width: 100%;
    position: relative;
    width: 15.3rem;

    > svg {
      height: auto;
      width: 100%;
    }

    @include breakpoint($tablet) {
      width: 11rem;
    }
  }

  &__percentage-path {
    opacity: 0;
    stroke-dasharray: 0 100;
    stroke-width: 6px;
    transition: stroke-dasharray .4s ease-in;

    &--under {
      stroke: $yellow-brown;
      transform: translate3d(0, 1px, 0);
    }

    &--over {
      stroke: $yellow;
    }
  }

  &__percentage-text {
    align-items: center;
    color: $white;
    display: flex;
    font-size: 2.8rem;
    height: 100%;
    justify-content: center;
    left: 0;
    line-height: 1;
    position: absolute;
    top: 0;
    width: 100%;

    @include breakpoint($tablet) {
      font-size: 1.6rem;
    }

    @include breakpoint($large-tablet) {
      font-size: 1.8rem;
    }

    @include breakpoint($desktop) {
      font-size: 1.9rem;
    }

    @include breakpoint($large-desktop) {
      font-size: 2.4rem;
    }
  }

  &__percentage-circle {
    fill: $blue-dark;
  }

  &--basic {
    #{$self} {
      &__header {
        margin: 0 0 $spacing-unit;

        @include breakpoint($tablet) {
          margin: 0 0 $spacing-unit--md;
        }

        @include breakpoint($desktop) {
          margin: 0 0 $spacing-unit;
        }
      }
      &__description {
        margin: $spacing-unit--ss 0;

        @include breakpoint($tablet) {
          margin: $spacing-unit 0;
        }

        @include breakpoint($desktop) {
          margin: $spacing-unit 0 $spacing-unit--lml;
        }
      }
      &__footer {
        margin: $spacing-unit--md 0 0;
      }
    }
  }

  &--statistics-number {
    #{$self} {
      &__description {
        @include margin-control(false, false, 'll');
        margin-top: 0;

        @include breakpoint($tablet) {
          @include margin-control(false, false, 'md');
          margin-top: 0;
        }

        @include breakpoint($desktop) {
          @include margin-control(false, false);
          margin-top: 0;
        }
      }
    }
  }

  &--statistics-percentage {
    #{$self} {
      &__inner {
        @include breakpoint($tablet) {
          display: block;
        }
      }

      &__header {
        justify-content: center;
        margin-bottom: $spacing-unit--md;

        @include breakpoint($tablet) {
          margin: 0;
        }
      }

      &__heading-row {
        @include breakpoint($tablet) {
          align-items: center;
          display: flex;
          margin: 0 0 $spacing-unit;
        }

        @include breakpoint($desktop) {
          margin: 0 -#{$spacing-unit--ss} $spacing-unit;
        }

        > *:first-child {
          @include breakpoint($tablet) {
            margin-right: $spacing-unit;
            max-width: 15.1rem;
            width: 30%;
          }

          @include breakpoint($large-tablet) {
            width: 25%;
          }

          @include breakpoint($desktop) {
            width: 35%;
          }

          @include breakpoint($large-desktop) {
            width: 40%;
          }
        }
      }

      &__heading {
        flex: 1;

        @include breakpoint($tablet) {
          margin: 0;
        }

        @include breakpoint($desktop) {
          font-size: 1.7rem;
        }

        @include breakpoint($large-desktop) {
          font-size: 2rem;
        }

      }

      &__description {
        @include margin-control(false, false, 'l');
        margin-top: 0;

        @include breakpoint($tablet) {
          @include margin-control(false, false, 'md');
          margin-top: 0;
        }

        @include breakpoint($desktop) {
          @include margin-control(false, false);
          margin-top: 0;
        }
      }
    }

    &.spotlight-card--full {
      #{$self} {
        // No negative margin on full width spotlight card w/ statistic percentage
        &__heading-row {
          @include breakpoint($desktop) {
            margin-left: 0;
            margin-right: 0;
          }

          > *:first-child {
            @include breakpoint($tablet) {
              max-width: none;
              width: 100%;
            }
          }
        }

        &__inner {
          @include breakpoint($tablet) {
            align-items: flex-start;
            justify-content: flex-start;
          }
        }

        &__header {
          @include breakpoint($desktop) {
            flex: 0 1 15.4rem;
            width: auto;
          }
        }

        &__graphic {
          @include breakpoint($desktop) {
            width: 15.4rem;
          }
        }

        &__heading {
          font-size: 1.7rem;
          margin: $spacing-unit--ss 0 0;

          @include breakpoint($desktop) {
            font-size: 2.1rem;
          }
        }

        &__percentage-text {
          @include breakpoint($tablet) {
            @include font-size(20, 20);
          }
        }
      }
    }
  }

  // Spotlight Card Variations
  &--proof-point {
    background-color: $gray;
    border-bottom-color: $yellow-light;

    #{$self} {
      &__heading {
        color: $blue-dark;
        font-weight: 300;
      }

      &__description {
        color: $blue-dark;
        font-weight: 300;
      }

      &__number {
        &:before {
          background-color: $blue-dark;
        }

        span {
          color: $blue-dark;
        }
      }
    }
  }

  &--basic-treatment {
    background-color: $white;
    border-bottom-color: $yellow-light;

    &.card-is-hidden {
      display: none;
    }

    #{$self} {
      &__department-info {
        @include font-size(10, 21);
        color: $blue-treatment-info;
        font-weight: bold;
        text-transform: uppercase;
        margin-top: 0;
        letter-spacing: .2em;
      }

      &__heading {
        color: $blue-medium;
        margin-bottom: .5em;
      }

      &__description {
        margin: 0;
      }

      &__cta-container {
        display: inline-block;
        justify-self: flex-start;
        margin-top: 0;
      }


      &__link {
        display: block;
        margin: 0;

        @include breakpoint($tablet) {
          display: inline-block;

          &:first-child {
            margin-right: $spacing-unit--half;
          }

          &:last-child:before {
            color: $gray-separator-alt;
            content: '|';
            display: inline-block;
            margin-right: $spacing-unit--half;
          }
        }

        @include breakpoint($desktop) {
          &:first-child {
            margin-right: $spacing-unit--l;
          }

          &:last-child:before {
            margin-right: $spacing-unit--l;
          }
        }

        > a {
          @include font-size(16, 18);
          color: $blue-medium;
        }
      }
    }
  }

  &--featured {
    background-color: $blue-lighter;
    border-bottom-color: $blue;

    #{$self} {
      &__heading {
        color: $blue-dark;
        font-weight: 300;
      }

      &__description {
        color: $blue-dark;
        font-weight: 300;
      }

      &__link {
        > a {
          color: $blue-dark;
        }
      }
    }
  }

  // Sizes
  &--full {
    #{$self} {
      &__inner {
        @include breakpoint($tablet) {
          align-items: center;
          display: flex;
          justify-content: space-around;
        }
      }

      &__header {
        @include breakpoint($tablet) {
          margin-right: $spacing-unit--l;
          width: 25%;
        }
      }

      &__content {
        @include breakpoint($tablet) {
          width: calc(75% - #{$spacing-unit--l});
        }
      }
    }
  }
}
