.featured-profiles {
  $self: &;

  .component-header {
    margin-bottom: $spacing-unit--lml;

    @include breakpoint($tablet) {
      margin-bottom: $spacing-unit--ll;
    }

    @include breakpoint($desktop) {
      margin-bottom: $spacing-unit--xl;
    }
  }

  &__container {
    @include list-unstyled();
    display: flex;
    flex-flow: wrap;

    > li {
      flex-basis: 100%;
      margin-bottom: $spacing-unit--l;

      @include breakpoint($tablet) {
        flex-basis: 50%;
      }
    }
  }

  &.full-width {
    #{$self} {
      &__container {
        > li {
          @include breakpoint($desktop) {
            flex-basis: 33%;
          }
        }
      }
    }
  }

  &--sidebar {
    border-bottom: 1px solid $gray;
    max-width: 350px;
    padding-bottom: $spacing-unit--l;
    width: 100%;

    .component-header {
      margin-bottom: $spacing-unit--md;

      h2 {
        font-size: 2.2rem;
        margin: 0;

        @include breakpoint($desktop) {
          font-size: 2.1rem;
        }
      }
    }

    #{$self} {
      &__container {
        display: block;

        > li {
          margin-bottom: $spacing-unit--mdl;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
