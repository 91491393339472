.wFormContainer {
  max-width: 100% !important;

  br {
    display: none;
  }

  .htmlContent {
    font-size: 1.6rem;
  }

  .inputWrapper {
    position: relative;
  }

  .field-hint,
  .field-hint-inactive {
    display: block;
    margin-bottom: $spacing-unit;
    margin-top: -#{$spacing-unit};
  }

  .field-hint {
    opacity: 1;
  }

  .field-hint-inactive {
    opacity: .7;
  }

  .hint {
    font-size: .7em;
  }

  .label {
    margin-bottom: $spacing-unit;
  }

  .oneChoice .label {
    margin-bottom: 0;
  }

  .lengthIndicator {
    font-size: .7em;
    left: auto !important;
    margin-top: -#{$spacing-unit--half};
    position: absolute;
    right: 0;
  }

  .wForm {
    padding: 0;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    strong,
    b {
      font-weight: inherit;
    }

    input[aria-invalid='true'] {
      border-color: $red;
    }

    .errMsg {
      color: $red;
      font-size: .7em;
      margin-bottom: 1rem;
      margin-top: -1rem;
    }

    .reqMark:after {
      color: $red;
      content: ' *';
      font-size: 1em;
    }

    .inline.group {
      > * {
        padding: 0 0 $spacing-unit;

        @include breakpoint($tablet) {
          display: inline-block;
          vertical-align: top;
        }
      }

      input:not([type='radio']):not([type='checkbox']) {
        display: block;
        width: 100%;
      }
    }

    .choices {
      display: flex;
      flex-flow: row wrap;
    }

    .primaryAction,
    .secondaryAction {
      color: $white;
      padding: .75em 1.5em;
    }
  }
}

.wf-acl-hidden {
  display: none;
}

// .wFormContainer {}
// .wFormFooter {}
// .wFormTitle {}

// .oneField {}
// .oneChoice {}

// -blue
// -green
// -large
// -muted
// -thin
// -top-bar
// actions
// block
// button
// cta
// field-container-D
// field-hint-inactive
// group
// hint
// hintsBelow
// htmlContent
// htmlSection
// inline
// inputWrapper
// label
// labelsAbove
// layout-header
// layout-header__cta
// layout-header__main
// oneChoice
// oneField
// postField
// preField
// reqMark
// required
// section
// supportInfo
// validate-date

