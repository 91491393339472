.component-header {
  $self: &;
  margin-bottom: $spacing-unit--xl;

  &.centered {
    text-align: center;
  }

  &.title-only {
    margin-bottom: 0;
  }

  &.title-only-mobile {
    margin-bottom: 0;

    @include breakpoint($desktop) {
      margin-bottom: $spacing-unit--xl;
    }

    #{$self} {
      &__subheading,
      &__cta {
        display: none;

        @include breakpoint($desktop) {
          display: block;
        }
      }
    }
  }

  &.small-margin {
    margin-bottom: $spacing-unit--l;
  }
}
