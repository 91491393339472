.input-group {
  input[type='text'] {
    background-color: $white;
    border: 1px solid $white;
    box-shadow: none;
    font-size: 1.6rem;
    line-height: 1;
    outline: none;
    padding: $spacing-unit--ss $spacing-unit;

    &:focus {
      border-color: $white;
      outline: none;
    }
  }

  &__checkbox,
  &__radio {
    display: flex;
    margin-bottom: $spacing-unit;

    input + label {
      margin-left: 1em;
    }

    label {
      font-size: 1.4rem;
      font-weight: 400;
      letter-spacing: initial;
      margin-bottom: 0;
      text-transform: none;
    }
  }
}
