// =======================================
//    #CORE/HTML - IMAGES
// =======================================

img {
  border: 0;
  display: block;
  font-style: italic;
  height: auto;
  width: 100%;
}

figure {
  margin: 0;
}

figcaption {
  @include font-size(14, 20);
  font-style: italic;
  margin-top: 1rem;

  em {
    font-style: normal;
  }
}

.align {
  &-left,
  &-right {
    margin: $spacing-unit 0;
  }
}
