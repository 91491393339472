.hero-area {
  $self: &;

  $infoBtnSizeMobile: 2.2rem;
  $infoBtnSizeTablet: 3rem;
  $infoBtnSizeDesktop: 4.4rem;
  // $largeDesktopPull: $spacing-unit--xl;
  $sharingIconsWidth: 6.5rem;

  // $megaHeroSize: $large-desktop + parseInt($largeDesktopPull) * 10 * 2;

  $breadcrumbsHeight: 5.2rem;
  margin-bottom: $spacing-unit;

  @mixin heroMobileOnly() {
    @include breakpoint(0 $tablet--max) {
      @content;
    }
  }

  @mixin heroTabletOnly() {
    @include breakpoint($tablet $desktop) {
      @content;
    }
  }

  @mixin heroTabletDown() {
    @include breakpoint(0 $desktop) {
      @content;
    }
  }

  @mixin heroMd() {
    @include breakpoint($tablet) {
      @content;
    }
  }

  @mixin heroLg() {
    @include breakpoint($desktop) {
      @content;
    }
  }

  @mixin heroXl() {
    @include breakpoint($large-desktop) {
      @content;
    }
  }

  @mixin heroButtonGray() {
    background-color: $gray;
    border-color: $gray;
    color: $blue-medium;

    &:before,
    .icon {
      background-color: $white;
    }

    .text,
    .icon {
      border-color: $gray;
    }
  }

  @mixin heroButtonWhite() {
    background-color: $white;
    color: $blue-medium;
  }

  @mixin heroContentContainLg() {
    margin-left: auto;
    margin-right: auto;
    max-width: calc(100% - #{$sharingIconsWidth * 2});
  }

  @include heroLg {
    margin: $spacing-unit 0;
  }

  &__inner {
    position: relative;
    padding-top: $breadcrumbsHeight; // Add padding for absolute breadcrumb positioning

    @include heroLg {
      padding-top: 0;
    }
  }

  &__breadcrumbs {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: index($globalIndex, heroBreadcrumbs);

    @include heroLg {
      position: relative;

      .breadcrumbs {
        padding: 0;
      }
    }
  }

  &__sharing {
    display: none;

    @include breakpoint($desktop) {
      background-color: $white;
      border-left: 1px solid $gray;
      height: auto;
      opacity: 1;
      position: absolute;
      right: 0;
      top: 0;
      width: 66px;
      z-index: index($globalIndex, socialShare);

      @at-root .at-ready & {
        display: block;
      }
    }

  }

  /* START common */

  &--article,
  &--centers,
  &--event,
  &--general,
  &--location,
  &--profile,
  &--search {
    #{$self} {
      &__inner {
        @include heroLg {
          padding-left: $spacing-unit--md;
          padding-right: $spacing-unit--md;
          position: relative;
        }

        @include heroXl {
          padding-left: $spacing-unit--xl;
          padding-right: $spacing-unit--xl;
        }

        &:before {
          background-color: $gray;
          content: ' ';
          display: block;
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: -1;
        }
      }

      &__breadcrumbs {
        .page-trail__link {
          @include heroLg {
            color: $blue-gray;

            &:hover {
              color: $black;
            }
          }
        }
      }
    }

    &.has-image {
      #{$self} {
        &__inner {
          display: flex;
          flex-direction: column;
          padding-right: 0;

          @include heroLg {
            flex-flow: row nowrap;
            padding-bottom: $spacing-unit--md;
            padding-top: $spacing-unit--md;
          }

          &:before {
            content: none;

            @include heroLg {
              content: ' ';
              width: 75%;
            }
          }
        }

        &__body {
          order: 1;

          @include heroLg {
            display: flex;
            flex-flow: column nowrap;
            margin: 0 $spacing-unit 0 0;
            order: 0;
            width: calc(55% - #{$spacing-unit});
          }

          @include heroXl {
            width: calc(50% - #{$spacing-unit});
          }
        }

        &__image {
          order: 0;

          @include heroLg {
            align-self: center;
            order: 1;
            width: 45%;
          }

          @include heroXl {
            width: 50%;
          }
        }
      }

      .hero-content {
        @include heroLg() {
          justify-self: center;
          margin-bottom: auto;
          margin-top: auto;
          transform: translateY(-($spacing-unit--md)); // move it up ~breadcrumbs height to center it on the image
        }
      }
    }
  }

  /* END common */

  /* START general */

  &--general {
    #{$self} {
      &__inner {
        @include heroLg {
          padding-bottom: $spacing-unit--ll;
          padding-top: $spacing-unit--md;
        }
      }

      &__breadcrumbs {
        @include heroLg {
          margin-bottom: 3rem;
        }

        @include heroXl {
          margin-bottom: 5rem;
        }
      }
    }

    .hero-content {
      text-align: center;
    }

    .hero-buttons {
      justify-content: center;

      .button {
        @include heroButtonWhite();
      }
    }

    .hero-image {
      &__image {
        padding-bottom: 60%;
      }
    }

    &.has-image {
      #{$self} {
        &__body {
          background-color: $white;
          margin: -#{$infoBtnSizeMobile} auto 0;
          width: calc(100% - #{$infoBtnSizeMobile * 2});
          z-index: index($globalIndex, heroBody);

          @include heroMd {
            margin: -#{$infoBtnSizeTablet} auto 0;
            width: calc(100% - #{$infoBtnSizeTablet * 2});
          }

          @include heroLg {
            background-color: transparent;
            margin: 0;
            width: calc(55% - #{$spacing-unit});
          }

          @include heroXl {
            width: calc(50% - #{$spacing-unit});
          }
        }
      }

      .hero-content {
        @include heroLg {
          text-align: left;
        }

        &__title-separator {
          @include heroLg {
            display: none;
          }
        }

        @include breakpoint(max-width $desktop) {
          &__inner {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }

      .hero-buttons {
        @include heroLg {
          justify-content: flex-start;
        }

        .button {
          @include heroButtonGray();

          @include heroLg {
            @include heroButtonWhite();
            font-size: 1.4rem;
          }

          @include heroXl {
            font-size: 1.6rem;
          }
        }
      }
    }

    &:not(.has-image) {
      .hero-content {
        @include heroLg() {
          @include heroContentContainLg();
        }

        &__description {
          margin: 0 auto;
          max-width: 73.7rem;
        }
      }
    }
  }

  /* END general */

  /* START location */

  &--location {
    #{$self} {
      &__inner {
        @include heroLg {
          padding-bottom: $spacing-unit--md;
          padding-top: $spacing-unit--md;
        }
      }
      &__breadcrumbs {
        @include heroLg {
          margin-bottom: $spacing-unit--l;
        }
      }
    }

    .hero-content {
      text-align: center;

      &__eyelash {
        margin-bottom: $spacing-unit--half;
      }

      &__title {
        margin: $spacing-unit--half 0 $spacing-unit--sm;

        @include heroMd {
          margin: $spacing-unit--sm 0;
        }
      }

      &__title-separator {
        margin: $spacing-unit--sm auto $spacing-unit--md;

        @include heroMd {
          margin: $spacing-unit--sm auto;
        }
      }
    }
    &.has-image {
      #{$self} {
        &__breadcrumbs {
          @include heroLg {
            margin-bottom: $spacing-unit * 5.3;
          }
        }

        &__body {
          order: 0;

          @include heroTabletOnly {
            background-color: $white;
            margin: -#{$infoBtnSizeTablet} auto 0;
            order: 1;
            width: calc(100% - #{$infoBtnSizeTablet * 2});
            z-index: index($globalIndex, heroBody);
          }

          @include heroLg {
            background-color: transparent;
            margin: 0;
            order: 0;
          }
        }

        &__image {
          order: 1;

          @include heroMd {
            order: 0;
          }

          @include heroLg {
            order: 1;
          }
        }
      }

      .hero-image {
        &__image {
          padding-bottom: 60%;
        }
      }

      .hero-content {
        @include heroLg {
          text-align: left;
        }

        &__title-separator {
          display: none;
        }
      }

      .hero-location {
        time {
          @include breakpoint($desktop) {
            margin: $spacing-unit--md 0 0;
          }
        }
      }
    }

    &:not(.has-image) {
      .hero-content {
        @include heroLg() {
          @include heroContentContainLg();
        }
      }
    }
  }

  /* END location */

  /* START search */

  &--search {
    #{$self} {
      &__inner {
        padding-bottom: 0;

        @include heroLg {
          padding-top: $spacing-unit--l;
        }

        &:after {
          background-color: $white;
          bottom: 0;
          display: block;
          height: 25%;
          left: 0;
          position: absolute;
          width: 100%;
          z-index: -1;

          @include heroMd {
            content: ' ';
          }
        }
      }

      &__breadcrumbs {
        margin-bottom: $spacing-unit--ll;
      }
    }

    .hero-content {
      text-align: center;

      &__inner {
        padding-left: $spacing-unit;
        padding-right: $spacing-unit;

        @include heroMd {
          padding-bottom: 0;
        }
      }

      &__title {
        margin-bottom: $spacing-unit;
      }

      &__title-separator {
        margin: $spacing-unit auto;

        @include heroMd {
          display: none;
        }
      }
    }

    .hero-search-blocks {
      margin-top: $spacing-unit--mdl;

      @include heroMd {
        margin-top: $spacing-unit--l;
      }

      @include heroLg {
        margin-top: $spacing-unit--ll;
      }
    }
  }

  /* END search */

  /* START listing */

  &--listing {
    margin-bottom: 0;
    margin-left: -($spacing-unit);
    margin-right: -($spacing-unit);

    @include heroLg {
      margin-bottom: $spacing-unit;
    }

    .hero-content {
      text-align: center;

      @include heroLg {
        max-width: 800px;
        text-align: left;
        width: calc(100% - #{$sharingIconsWidth + $spacing-unit});
      }
    }

    .hero-buttons {
      justify-content: center;

      @include heroLg {
        justify-content: flex-start;
      }

      .button {
        @include heroButtonGray();
      }
    }

    #{$self} {
      &__breadcrumbs {
        @include heroLg {
          margin: $spacing-unit--ll 0 $spacing-unit--l;
        }

        @include heroXl {
          margin: $spacing-unit--ll 0;
        }
      }
    }
  }

  /* END listing */

  /* START profile */
  &--profile {
    #{$self} {
      &__inner {
        flex-wrap: wrap;

        @include heroMobileOnly() {
          padding: $breadcrumbsHeight $spacing-unit $spacing-unit--md;
        }

        @include heroTabletOnly() {
          padding: $spacing-unit--ll + $breadcrumbsHeight $spacing-unit $spacing-unit--md;
        }

        @include heroMd {
          flex-wrap: nowrap;
        }
      }

      &__mobile-title {
        display: block;
        flex: 0 0 100%;

        @include heroMd {
          display: none;
        }
      }
    }

    .hero-content {
      &__inner {
        @include heroTabletDown() {
          padding: 0;
        }
      }

      &__title {
        display: none;

        @include heroMd {
          display: block;
        }
      }

      &__title-separator {
        @include heroLg {
          display: none;
        }
      }

      &__description {
        @include heroMobileOnly() {
          margin-top: 0;
        }
      }
    }
    .hero-image {
      &__image {
        padding-bottom: 100%;
      }
    }

    &.has-image {
      #{$self} {
        &__inner {
          flex-direction: row;
        }

        &__image {
          order: 0;

          @include heroTabletDown() {
            width: 30%;
          }

          @include heroLg {
            justify-self: flex-end;
            margin-left: auto;
            margin-right: 7rem;
            max-width: 46rem;
            order: 1;
            width: 30%;
          }

          @include heroXl {
            margin-right: 7rem;
            width: 45%;
          }
        }

        &__body {
          order: 1;

          @include heroMobileOnly() {
            margin-left: $spacing-unit;
            width: calc(70% - #{$spacing-unit});
          }

          @include heroTabletOnly() {
            margin-left: $spacing-unit--l;
            width: calc(70% - #{$spacing-unit--l});
          }

          @include heroLg {
            order: 0;
          }
        }
      }
    }

    &:not(.has-image) {
      .hero-content {
        @include heroLg() {
          @include heroContentContainLg();
        }
      }
    }
  }

  /* END profile */

  /* START article */
  &--article {
    @include breakpoint($desktop) {
      margin-bottom: $spacing-unit--l;
    }

    #{$self} {
      &__inner {
        @include heroLg {
          padding-bottom: $spacing-unit--ll;
          padding-top: $spacing-unit--md;
        }
      }

      &__breadcrumbs {
        @include heroLg {
          margin-bottom: $spacing-unit;
        }
      }
    }

    .hero-content {
      padding: 0 $spacing-unit;
      text-align: center;

      @include heroLg {
        padding: 0;
      }

      &__eyelash {
        @include font-size(11, 18);
        margin: 0 0 $spacing-unit;

        @include heroXl {
          @include font-size(14, 24);
        }
      }

      &__title {
        @include font-size(22, 26);
        margin: $spacing-unit 0 $spacing-unit;

        @include heroXl {
          @include font-size(30, 38);
          margin: $spacing-unit 0 $spacing-unit--md;
        }
      }

      &__description {
        @include font-size(16, 25);
        margin: $spacing-unit 0 0;

        @include heroXl {
          @include font-size(18, 30);
          margin: $spacing-unit--md 0 0;
        }

        span {
          a {
            color: $blue-medium;
            text-decoration: none;

            &:focus,
            &:hover {
              text-decoration: underline;
            }
          }

          &.phone {
            a {
              color: $blue-dark;
            }
          }
        }
      }

      &.has-description-separators {
        .hero-content__description {
          span {
            &:after {
              @include heroMobileOnly() {
                content: none;
              }
            }
          }
        }
      }
    }

    &.has-image,
    &:not(.has-image) {
      .hero-content {
        &__eyelash {
          @include font-size(11, 18);

          @include heroXl {
            @include font-size(14, 24);
          }
        }
        &__title {
          @include font-size(22, 26);

          @include heroXl {
            @include font-size(30, 38);
          }
        }
        &__description {
          @include font-size(16, 25);

          @include heroXl {
            @include font-size(18, 30);
          }
        }
      }
    }

    &.has-image {
      #{$self} {
        &__inner {
          &:before {
            content: '';
          }
        }
        &__breadcrumbs {
          @include heroXl {
            margin-bottom: 4rem;
          }
        }
        &__image {
          display: none;

          @include heroLg {
            display: block;
          }
        }
      }

      .hero-content {
        @include heroLg {
          text-align: left;
        }

        &__title-separator {
          @include heroLg {
            display: none;
          }
        }
      }
    }

    &:not(.has-image) {
      .hero-content {
        @include heroLg() {
          @include heroContentContainLg();
        }
      }
    }
  }

  /* END article */

  /* START event */
  &--event {
    #{$self} {
      &__inner {
        @include heroMd {
          padding-bottom: $spacing-unit--xl;
          padding-top: $breadcrumbsHeight + $spacing-unit--xl;
        }

        @include heroLg {
          padding-bottom: $spacing-unit--ll;
          padding-top: $spacing-unit--l;
        }

        &:before {
          background-color: $white;

          @include heroLg {
            background-color: $gray;
          }
        }
      }

      &__breadcrumbs {
        @include heroLg {
          margin-bottom: 4rem;
        }
      }
    }

    .hero-content {
      padding: 0 $spacing-unit;
      text-align: center;

      @include heroMd {
        padding: 0;
        text-align: left;
      }

      &__inner {
        padding: $spacing-unit--md 0;

        @include breakpoint($tablet) {
          padding: 0;
        }
      }

      &__eyelash {
        @include font-size(11, 18);
        margin: $spacing-unit--md 0 $spacing-unit;

        @include heroMd {
          @include font-size(10, 11);
          margin: 0 0 $spacing-unit--xs;
        }

        @include heroLg {
          @include font-size(14, 24);
          margin: 0 0 $spacing-unit--ss;
        }

        span {
          @include breakpoint($tablet) {
            padding-right: $spacing-unit;
          }
        }
      }

      &__title {
        margin: $spacing-unit 0 0;

        @include heroMd {
          @include font-size(26, 28);
          margin: $spacing-unit--xs 0 0;
        }

        @include heroLg {
          @include font-size(33, 38);
          margin: $spacing-unit--ss 0 0;
        }
      }
    }
  }

  /* END event */

  /* START centers */

  &--centers {
    margin-bottom: $spacing-unit--md;

    @include breakpoint($tablet) {
      margin-bottom: $spacing-unit--lml;
    }

    @include breakpoint($desktop) {
      margin-bottom: $spacing-unit--ll;
    }

    .hero-content {
      background-color: $white;
      margin: -($infoBtnSizeMobile) auto 0;
      max-width: 1080px;
      padding: $spacing-unit--md $spacing-unit;
      text-align: center;
      width: calc(100% - #{$infoBtnSizeMobile * 2});
      z-index: index($globalIndex, heroContent);

      @include heroMd() {
        margin-top: -($infoBtnSizeTablet);
        padding: $spacing-unit--l $spacing-unit $spacing-unit--md;
        width: calc(100% - #{$infoBtnSizeTablet * 2});
      }

      @include heroLg() {
        bottom: 0;
        padding: $spacing-unit--l $spacing-unit--xl $spacing-unit;
        left: 0;
        position: absolute;
        right: 0;
        text-align: left;
        width: calc(100% - #{$infoBtnSizeDesktop * 2});
      }

      &__inner {
        padding: 0;
      }

      &__graphic {
        height: calc(100% - 12rem);
        left: -3rem;
      }

      &__title {
        margin-bottom: 0;
        margin-top: 0;

        @include heroMd {
          margin-bottom: $spacing-unit--md;
        }

        @include heroLg {
          font-size: 5rem;
        }
      }

      &__title-separator {
        margin: $spacing-unit--ss auto $spacing-unit--xs;

        @include heroMd() {
          display: none;
        }
      }

      &__description {
        margin-top: 0;

        @include heroMd() {
          margin-left: auto;
          margin-right: auto;
          margin-top: $spacing-unit--md;
          max-width: 590px;
        }

        @include heroLg() {
          max-width: none;
        }
      }
    }

    .hero-buttons {
      margin-top: $spacing-unit--md;

      @include heroMd() {
        margin-top: $spacing-unit--l;
      }

      @include heroLg() {
        margin-top: $spacing-unit--ll;
      }

      .button {
        @include heroButtonGray();

        @include heroLg {
          @include heroButtonGray();
        }
      }
    }

    #{$self} {
      &__inner {
        position: relative;
      }

      &__breadcrumbs {
        background-color: transparent;

        .page-trail {
          background-color: $blue-dark;

          @include heroLg() {
            left: 0;
            padding: $spacing-unit--md $spacing-unit--lml;
            position: absolute;
            top: 0;
          }

          &__link,
          &__separator,
          &__icon {
            color: $white;
          }
        }
      }
    }

    &.has-image {
      #{$self} {
        &__inner {
          display: flex;
          padding: 0;

          &:before {
            @include heroLg() {
              content: none;
            }
          }

          @include heroLg() {
            display: block;
          }
        }

        &__image {
          padding-top: 52px;
          width: 100%;

          @include breakpoint($desktop) {
            padding-top: 0;
          }

          .hero-image__image {
            padding-bottom: 60%;
          }
        }

        &__body {
          margin: 0;
          width: 100%;
        }
      }

      .hero-content {
        @include heroLg {
          transform: none;
        }
      }
    }
  }

  /* END centers */

  /* START centers-medium */

  &--centers-medium {
    &.has-image {
      .hero-content {
        @include heroLg {
          background-color: $white;
          margin: $spacing-unit--l * 2 0 0;
          padding: $spacing-unit--l 0 $spacing-unit;
          width: 520px;
        }

        @include heroXl {
          margin: $spacing-unit--l 0;
          width: 640px;
        }

        &__inner {
          @include heroLg {
            padding-right: 5%;
          }

          @include heroXl {
            padding-right: 10%;
          }
        }
      }

      .hero-buttons {
        .button {
          @include heroButtonGray();

          @include heroLg {
            @include heroButtonGray();
          }
        }
      }

      #{$self} {
        &__inner {
          &:before {
            content: none;
          }
        }

        &__breadcrumbs {
          @include heroLg {
            margin-bottom: 0;
          }
        }

        &__body {
          @include heroLg {
            width: 35%;
          }

          @include heroXl {
            width: 40%;
          }
        }

        &__image {
          @include heroLg {
            width: 65%;
          }

          @include heroXl {
            width: 60%;
          }
        }
      }
    }
  }

  /* END centers-medium */
}
