.hero-buttons {
  @include list-unstyled();
  margin: $spacing-unit--ll auto 0;
  text-align: left;
  width: calc(100% - 1rem);

  @include breakpoint($tablet) {
    display: flex;
    flex-wrap: wrap;
    margin: $spacing-unit--l 0 0;
    justify-content: center;
    width: 100%;
  }

  @include breakpoint($desktop) {
    justify-content: flex-start;
    margin: $spacing-unit--l 0 0;
  }

  > li {
    &:not(:last-child) {
      margin-bottom: $spacing-unit--ss;

      @include breakpoint($tablet) {
        margin-right: $spacing-unit;
      }
    }
  }

  .button {
    display: flex;
    font-size: 1.7rem;
    line-height: 1.2;

    @include breakpoint($tablet) {
      width: auto;
    }

    @include breakpoint($desktop) {
      line-height: 1.4;
    }

    .icon,
    .text {
      padding: .7em;

      @include breakpoint($large-desktop) {
        padding: .75em;
      }
    }
  }
}
