.button {
  background-color: transparent;
  border-radius: 0;
  border: 1px solid $blue;
  box-shadow: 0px 0px 0px 0px transparent;
  color: $blue-text;
  cursor: pointer;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  min-width: 200px;
  padding: .75em 1.5em;
  text-align: center;
  text-decoration: none;
  transition: .15s ease-in-out;
  transition-property: color, background-color, border-color, box-shadow;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  will-change: background-color, border-color, box-shadow, color, outline;

  &.phone,
  &.tel {
    .icon {
      font-size: .75em;
    }
  }

  &:hover,
  &:focus,
  &:active {
    background-color: $blue-text;
    border-color: $blue-text;
    box-shadow: 0px 3px 6px -3px $gray-dark;
    color: $white;
    outline: 0;
  }

  &:active {
    transform: translate3d(0, 2px, 0);
  }

  &__icon {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    min-width: 0;

    .icon {
      margin-left: .5em;
      pointer-events: none;
      transition: transform .5s .125s cubic-bezier(0, -1, 0, 2);
      will-change: transform;

      @include breakpoint(max-width $large-tablet--max) {
        display: none;
      }
    }

    .text:empty + .icon {
      display: block;
      margin: unset;
    }

    &:hover:not(.button__icon--solid) .icon {
      transform: translateX(50%);
    }
  }

  a {
    text-decoration: none;
  }

  // Card types
  &.filter {
    border-radius: .5em;
  }

  &--solid {
    background-color: $blue-dark;
    border: 0;
    color: $white;
  }

  &--solid-yellow,
  &--solid-yellow[type='submit'] {
    background-color: $yellow-light;
    border: 0;
    color: $black;

    &:hover,
    &:focus,
    &:active {
      background-color: $yellow;
      color: black;
    }
  }

  &--outline-white {
    border-color: $white;
    color: $blue-dark;

    &:hover,
    &:focus,
    &:active {
      border-color: $white;
      background-color: $white;
      color: $blue-dark;
    }
  }

  &--white {
    background-color: $white;
    border: 0;
    color: $blue-text;
  }

  &--inverted {
    border-color: currentColor;
    color: $white;

    &:hover,
    &:focus,
    &:active {
      background-color: $blue-text;
    }
  }

  &--filters-apply,
  &--filters-clear {
    font-weight: 500;
    min-width: 0;
    padding: $spacing-unit--ss $spacing-unit;
    width: 100%;

    .icon {
      font-size: 1.2rem;
    }
  }

  &--filters-clear {
    background-color: transparent;
    border-color: $blue-dark;
    color: $blue-dark;
  }

  &--filters-display {
    @include font-size(14, 18);
    align-items: center;
    border: 1px solid $blue-dark;
    border-radius: 2.5rem;
    color: $blue-dark;
    cursor: pointer;
    display: flex;
    font-weight: 400;
    padding: $spacing-unit--ss $spacing-unit--md;

    span {
      margin: 0 .8rem;
    }

    .icon {
      font-size: 1.2rem;
    }
  }

  &__icon--solid {
    align-items: stretch;
    border-color: transparent;
    justify-content: space-between;
    overflow: hidden;
    padding: 0;
    position: relative;
    text-align: left;
    z-index: 0;

    @include breakpoint($tablet) {
      text-align: center;
    }

    &:before {
      background-color: $white;
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: transform .3s ease-in-out;
      transform: translateX(-100%);
      will-change: transform;
      z-index: -1;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: inherit;
      border-color: $gray;
      color: $blue-dark;

      &:before {
        transform: translateX(0);
      }
    }

    .icon,
    .text {
      border: 2px solid $gray;
      padding: .75em;
      z-index: 1;
    }

    .icon {
      align-items: center;
      background-color: $white;
      border-left: 0;
      color: $blue-dark;
      display: flex;
      justify-content: center;
      margin: 0;
      text-align: center;
      width: 40px;
    }

    .text {
      display: block;
      flex: 1 1 auto;
      padding-left: $spacing-unit;
      padding-right: $spacing-unit;
      width: calc(100% - 40px);
    }

    @include breakpoint($tablet) {
      &:before,
      .icon {
        background-color: $gray;
      }

      .text,
      .icon {
        border-color: $white;
      }
    }

    &.blue {
      border-color: $blue;

      &:before,
      .icon {
        background-color: $blue-light;
      }
    }

    &.purple {
      border-color: $purple;
      color: $purple;

      &:before,
      .icon {
        background-color: $lavender;
      }
    }

    &.gray {
      color: $gray;

      &:before,
      .icon {
        background-color: $white;
      }

      .icon {
        color: $blue-text;
      }
    }
  }
}

.button-link {
  @extend %link;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: inherit;
  padding: 0;

  &:focus,
  &:hover {
    border: none;
    outline: none;
    text-decoration: underline;
  }
}
