.mobile-nav-group {
  $self: &;

  @include list-unstyled();
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  margin: $spacing-unit 0;

  > li {
    margin: .3rem 0;

    > div > a,
    > a {
      align-items: center;
      display: flex;

      span {
        display: block;
      }

      .text {
        flex: 1;
      }

      .icon {
        justify-self: flex-end;
      }
    }
  }

  a {
    display: block;
    line-height: 1.5;
    padding: $spacing-unit--md $spacing-unit $spacing-unit--md $spacing-unit--mdl;
    text-decoration: none;

    &:active,
    &:focus,
    &:hover {
      outline: none;

      > :only-child:not(.text),
      .text {
        transform: scale(1.1);
      }
    }

    .text {
      transform-origin: 0 50%;
      transition: transform .1s ease-in-out;
    }

    .icon {
      cursor: pointer;
      transform: none;

      &:active,
      &:focus,
      &:hover {
        svg {
          animation: wiggle .5s ease-in-out 10 alternate;
        }

        @keyframes wiggle {
          from {
            transform: rotate(-15deg);
          }
          to {
            transform: rotate(15deg);
          }
        }
      }
    }

    span,
    &[href=''] {
      pointer-events: none;
    }
  }

  a.mobile-nav-group__accordion-trigger {
    padding-right: $spacing-unit--xl;
  }

  &__accordion {
    position: relative;

    > a {
      position: relative;

      .icon {
        height: 4.4rem;
        position: absolute;
        right: 0;
        top: calc(50% - 2.2rem);
        transition: transform .2s ease-in-out;
        width: 4.4rem;
      }
    }

    > ul {
      @include list-unstyled;
      left: 0;
      max-height: 0;
      padding: 0 0 0 $spacing-unit--l;
      overflow: hidden;
      transition: all .2s ease-in-out, visibility 0s .2s;
      visibility: hidden;
      will-change: padding-bottom, max-height, visibility;

      > li {
        opacity: 0;
        transform: translate3d(0, 15px, 0);
        transition: all .35s .35s ease;
        will-change: opacity, transform;
      }

      a {
        background-color: transparent;
        padding: $spacing-unit--xs 0;
        transform-origin: 0 50%;
        transform: translateZ(0);
        transition: transform .1s ease-in-out;

        &:active,
        &:focus,
        &:hover {
          outline: none;
          transform: scale(1.1);
        }
      }
    }

    &.open {
      > a {
        .icon {
          transform: rotate(225deg);
        }
      }

      > ul {
        max-height: 1000px;
        padding-bottom: $spacing-unit;
        transition: all .3s ease-in-out, padding-bottom .4s ease;
        visibility: visible;

        > {
          li {
            opacity: 1;
            transform: translate3d(0, 0, 0);
            transition: all .35s ease;
          }
        }
      }
    }
  }

  &--light {
    a {
      background-color: $gray;
      color: $blue-dark;

      .icon {
        color: $blue-medium;
      }

      &:focus {
        color: $black-light;

        .icon {
          color: $blue-dark;
        }
      }
    }

    #{$self} {
      &__accordion {
        > ul {
          background-color: $gray;
        }
      }
    }
  }

  &--dark {
    a {
      background-color: $blue-dark;
      color: $blue-light-2;

      .icon {
        color: $blue-light-2;
      }

      button {
        &:focus {
          color: $white;
        }
      }
    }

    #{$self} {
      &__accordion {
        > ul {
          background-color: $blue-dark;
        }
      }
    }
  }

  &--white {
    a {
      background-color: $white;
      color: $blue-medium;

      .icon {
        color: $blue-medium;
      }
    }

    > li {
      border: 1px solid $gray;

      > div {
        border: 1px solid $gray;
      }
    }

    #{$self} {
      &__accordion {
        > ul {
          background-color: $white;
        }
      }
    }
  }

  &--small {
    a {
      padding: $spacing-unit $spacing-unit $spacing-unit $spacing-unit--mdl;
    }
  }

  &--list-together {
    display: table;
    width: 100%;

    > li {
      display: table-row;
      margin: 0;
      width: 100%;

      > div {
        display: table-cell;
        width: 100%;
      }
    }
  }

  button {
    background-color: transparent;
    border-radius: 50%;
    border: 0;
    margin-right: .75rem;
    outline: 0;
    pointer-events: all;

    &:active,
    &:focus,
    &:hover {
      color: currentColor;
    }
  }
}
