.two-column-slider {
  margin: $spacing-unit--l 0 $spacing-unit--lml;

  @include breakpoint($tablet) {
    margin: $spacing-unit--lml 0;
  }

  .component-header {
    margin-bottom: $spacing-unit--l;
    padding: 0 $spacing-unit;

    @include breakpoint($tablet) {
      padding: 0;
    }
  }
}
