.highlighted-info-block {
  background-color: $white;
  margin: -($spacing-unit--md + $spacing-unit) $spacing-unit--l 1rem;
  padding: $spacing-unit--md;

  @include breakpoint($desktop) {
    border: 2px solid $gray-alt;
    padding: $spacing-unit--md $spacing-unit--mdl $spacing-unit--ll;
    margin: 0 0 $spacing-unit--l;
  }

  h2 {
    margin-bottom: $spacing-unit--md;
  }

  ul {
    @include font-size(16, 25);
    margin: 0;
    padding-left: 2rem;

    @include breakpoint($desktop) {
      @include font-size(18, 30);
    }

    li {
      margin-bottom: $spacing-unit--mdl;
    }
  }
}
