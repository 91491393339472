// =======================================
//    #UTILITY - COLORS
//
// Note: the palette function is included here (vs in the tools directory)
// since both of these needed in order to work properly
//
// =======================================

@function palette($palette, $tone: 'base') {
  @return map-get(map-get(map-get($palettes, colors), $palette), $tone);
}

$palettes: (
  colors: (
    black: (
      light: #19202a,
      base: #000
    ),
    white: (
      base: #fff,
      alt: #f7f7f7
    ),
    gray: (
      light: #d3d3d3,
      base: #f4f4f4,
      alt: #e5e5e5,
      alt2: #fafafa,
      dark: #a9a9a9,
      a11y: #8f8f8f,
      separator: #b7b7b7,
      checkbox: #d2d0cb,
      separatorAlt: #717882
    ),
    blue: (
      base: #01b9e8,
      centers: #1f2a3a,
      dark: #213245,
      darker: #1c2533,
      gray: #313d4e,
      gray-dark: #2b3647,
      light: #86daf3,
      light-2: #8fcde6,
      light-alt: #d0f4fe,
      lighter: #e2f8ff,
      lightest: #cef4ff,
      medium: #095f7e,
      text: #0d6a8d,
      treatmentInfo: #44505e,
      placeholder: #2e6f89,
      menuSep: #00a5ce
    ),
    red: (
      base: #d14124
    ),
    orange: (
      base: #ffa300
    ),
    purple: (
      lavender: #f4eff6,
      base: #592c6d,
      light: #893b67
    ),
    yellow: (
      base: #dbe442,
      light: #e4fe63,
      brown: #b1b55d
    ),
    brown: (
      table: #f3f0eb
    )
  )
);

$white:       palette(white);
$white-alt:   palette(white, alt);

$black:       palette(black);
$black-light: palette(black, light);

// Grays
$gray:          palette(gray);
$gray-dark:     palette(gray, dark);
$gray-light:    palette(gray, light);
$gray-lighter:  palette(gray, lighter);
$gray-alt:      palette(gray, alt);
$gray-alt2:     palette(gray, alt2);
$gray-separator:      palette(gray, separator);
$gray-checkbox:      palette(gray, checkbox);
$gray-separator-alt:      palette(gray, separatorAlt);
$grey: $gray; // just in case!

// Blues
$blue:          palette(blue);
$blue-dark:     palette(blue, dark);
$blue-gray:     palette(blue, gray);
$blue-light:    palette(blue, light);
$blue-light-alt:    palette(blue, light-alt);
$blue-lighter:  palette(blue, lighter);
$blue-lightest: palette(blue, lightest);
$blue-medium:   palette(blue, medium);
$blue-text:     palette(blue, text);
$blue-placeholder:     palette(blue, placeholder);
$blue-menuSep:  palette(blue, menuSep);
$blue-darker:   palette(blue, darker);
$blue-light-2:   palette(blue, light-2);
$blue-centers:   palette(blue, centers);
$blue-gray-dark: palette(blue, gray-dark);
$blue-treatment-info: palette(blue, treatmentInfo);

// Purple
$purple:        palette(purple);
$purple-light:  palette(purple, light);
$lavender:      palette(purple, lavender);

// Yellows
$yellow:        palette(yellow);
$yellow-light:  palette(yellow, light);
$yellow-brown:  palette(yellow, brown);

// Reds
$red: palette(red);

// Oranges
$orange: palette(orange);

// Browns
$brown-table:   palette(brown, table);

/// Ex. Light brown
// p {
//   color: palette(brown, light);
// }
///


// Returns flattened $palettes color map
@function get-flat-palette($map) {
  $colors: ();

  @each $color, $value in map-get($map, colors) {
    @each $name, $tone in $value {
      // key for "base" color is the color name itself, e.g. "gray"
      // key for other tones combines color and tone name, e.g. "gray-light"
      $key: if($name == 'base', $color, $color + '-' + $name);
      $colors: map-merge($colors, ($key: $tone));
    }
  }

  @return $colors;
}

// Save palette as flat map, used by style-guide.scss and variables.scss
$color-map: get-flat-palette($palettes);
