// =======================================
//    #CORE/HTML - PAGE
// =======================================

* {
  box-sizing: border-box; // establishes border-box model for all elements
  word-wrap: normal;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); // Remove default tappable touch color in iOS
}

:root {
  position: relative;
}

html,
body {
  height: 100%;
  min-height: 100vh;
}

html {
  font-size: 62.5%; // Sets up the Base 10 stuff
}

body {
  color: $blue-gray;
  font-family: $systemFont;
  font-size: 1.6rem;
  font-weight: 300;
  letter-spacing: -.01em;
  line-height: 1.66;
  overflow-x: hidden;

  .fonts-loaded & {
    font-family: $font-body;
    letter-spacing: 0;
  }

  @include breakpoint($tablet) {
    font-size: 1.8rem;
  }

  &.mobile-menu-open {
    overflow: hidden;
  }
}

::selection,
mark {
  background-color: $yellow-light !important;
}

:focus {
  outline-color: $blue-darker;
}

.divider {
  border: 1px solid rgba($black, .1);

  @include breakpoint($tablet) {
    margin: $spacing-unit--xl 0 $spacing-unit--xl 0;
  }

  @include breakpoint($desktop) {
    margin-left: -#{$spacing-unit--xl};
  }

  &--small-bottom {
    @include breakpoint($tablet) {
      margin-bottom: $spacing-unit--l;
    }
  }

  &--small {
    @include breakpoint($tablet) {
      margin-bottom: $spacing-unit--l;
      margin-top: $spacing-unit--l;
    }
  }

  &--no-pull {
    @include breakpoint($desktop) {
      margin-left: 0;
    }
  }
}

// Don't do it like this, but use this feature query on a case-by-case basis
// https://css-tricks.com/introduction-reduced-motion-media-query/
// @media (prefers-reduced-motion: reduce) {
//   * {
//     animation: none !important;
//     transition: none !important;
//   }
// }
