.two-column-slide-content {
  $self: &;

  &__description {
    @include font-size(16, 25);
    font-weight: 300;

    @include breakpoint($desktop) {
      @include font-size(18, 30);
    }
  }

  &__title,
  &__description p {
    margin: 0 0 $spacing-unit;
  }

  &__cta {
    margin: 0 0 $spacing-unit--l;

    @include breakpoint($tablet) {
      margin: 0 0 $spacing-unit;
    }

    .button {
      .icon {
        margin-left: $spacing-unit;
      }
    }
  }

  &--regular {
    #{$self} {
      &__title {
        @include font-size(16, 25);
        font-weight: 500;

        @include breakpoint($desktop) {
          @include font-size(20, 30);
        }
      }
    }
  }

  &--cd {
    #{$self} {
      &__title {
        @include font-size(22, 30);
        font-weight: 300;

        @include breakpoint($tablet) {
          @include font-size(16, 30);
          font-weight: 500;

          .fonts-loaded & {
            font-family: $font-body;
          }
        }

        @include breakpoint($desktop) {
          @include font-size(20, 30);
        }
      }
    }
  }
}
