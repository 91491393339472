.centers-nav {
  $self: &;
  position: relative;
  background-color: $blue-centers;

  &__inner {
    display: flex;
    justify-content: space-between;
  }

  &__bg {
    background-color: $blue-centers;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 50%;
    z-index: -1;
  }

  &__title {
    align-items: center;
    background-color: $blue-centers;
    display: flex;
    flex: 1 1 300px;
    justify-content: center;
    max-width: 300px;
    padding-right: $spacing-unit;

    #{$self} {
      &__bg {
        background-color: $blue-centers;
      }
    }

    h3 {
      a {
        @include font-size(23, 27);
        color: $blue-light;
        display: block;
        font-weight: 400;
        text-align: center;
        text-decoration: none;

        &:hover,
        &:focus {
          color: $white;
        }
      }
    }
  }

  &__image {
    display: block;
    margin: 0 $spacing-unit;

    img {
      width: 200px;
    }
  }

  &__menus {
    display: flex;
    flex-flow: column;
    flex: 0 1 80%;
    margin-right: -#{$spacing-unit};
    justify-content: space-between;

    @supports (flex-basis: auto) {
      flex-basis: auto;
    }
  }

  &__menu {
    #{$self} {
      &__bg {
        left: auto;
        right: 0;
      }
    }
    &--top {
      background-color: $blue-centers;
      height: 5rem;

      #{$self} {
        &__bg {
          // Fallback incase javascript crashes or is not loaded
          height: 5rem;
        }
      }
    }

    &--main {
      background-color: $blue-gray;

      #{$self} {
        &__bg {
          background-color: $blue-gray;
          bottom: 0;
          top: auto;
          z-index: -2;
        }
      }
    }
  }
}
