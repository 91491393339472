.centers-nav-menu {
  $self: &;
  position: relative;

  &__items {
    @include list-unstyled();
    display: flex;
    justify-content: flex-end;
  }

  &__item {
    position: relative;
    margin-bottom: 0;
    word-break: normal;

    .no-js &:hover {
      #{$self} {
        &__dropdown-menu {
          opacity: 1;
          transition: none;
          visibility: visible;
        }
        &__dropdown-menu-inner {
          background-color: $blue-gray;
          opacity: 1;
          transform: none;
          transition: none;
          visibility: visible;
        }
      }
    }
  }

  &__link {
    color: $white;
    display: inline-block;
    font-weight: 400;
    text-decoration: none;

    &:before {
      display: none;
    }

    &:hover,
    &:focus {
      color: $blue-light;
      outline-color: $white;
    }
  }

  &__dropdown-menu {
    position: absolute;
    left: 0;
    top: 100%;
    z-index: index($globalIndex, dropdownMenu);
  }

  &__dropdown-menu-inner {
    @include list-unstyled();
    padding: $spacing-unit 0;
    position: relative;

    &:before,
    &:after {
      clear: both;
      content: '';
      display: table;
    }
  }

  &__dropdown-item {
    text-align: left;
  }

  &__dropdown-link {
    color: $white;
    display: block;
    padding: $spacing-unit--half $spacing-unit--l;
    white-space: nowrap;

    .icon {
      color: $blue-light-2;
    }

    &:focus,
    &:hover {
      color: $blue-light-2;
      outline-color: $white;
    }
  }

  &--top {
    font-size: 1.3rem;

    #{$self} {
      &__item {
        margin-right: $spacing-unit--l;
      }

      &__link {
        padding: $spacing-unit 0;
      }
    }
  }

  &--main {
    font-size: 1.4rem;

    @include breakpoint($large-desktop) {
      font-size: 1.6rem;
    }

    #{$self} {
      &__link {
        padding: 2rem $spacing-unit--md;

        @include breakpoint($large-desktop) {
          padding: 2rem $spacing-unit--l;
        }

        &:hover {
          background-color: $blue-gray-dark;
          color: $white;
        }
      }

      &__item {
        &:last-child {
          .centers-nav-menu__dropdown-menu {
            left: auto;
            right: 0;
          }
        }
      }
    }
  }
}
