details {
  border-radius: 2px;
  border: 1px solid $gray;
  margin: $spacing-unit 0;

  &:focus,
  &:focus-within {
    border-color: #8f8f8f;
  }

  .details-content {
    padding: $spacing-unit;
    padding-right: $spacing-unit--xl;

    :first-child {
      margin-top: 0;
    }

    :last-child {
      margin-bottom: 0;
    }
  }

  > summary {
    color: $blue-text;
    font-size: 2rem;
    font-weight: 400;
    list-style: none;
    outline: none;
    padding: $spacing-unit;
    position: relative;
    vertical-align: top;

    @include breakpoint($desktop) {
      padding: $spacing-unit--half $spacing-unit;
    }

    &:hover {
      cursor: pointer;
    }

    &::-webkit-details-marker {
      display: none;
    }

    &:before {
      content: '';
      display: block;
      font-size: 2rem;
      font-weight: 500;
      height: 1em;
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"%3E%3Cpath fill="#{$blue-text}" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm144 276c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92h-92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z"/%3E%3C/svg%3E');
      position: absolute;
      right: $spacing-unit;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
      width: 1em;
    }
  }

  // Style the summary when details box is open
  &[open] summary {
    background-color: $gray;
    color: $blue-dark;

    &:before {
      content: '';
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#{$blue-dark}" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zM124 296c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h264c6.6 0 12 5.4 12 12v56c0 6.6-5.4 12-12 12H124z"/%3E%3C/svg%3E');
    }
  }

  &.blue {
    summary {
      @include font-size(14, 16);
      background-color: $blue-light-alt;
      color: $blue-dark;
    }

    &:focus,
    &:focus-within {
      border-color: $blue-light-alt;
    }

  }
}
