.testimonial {
  $self: &;
  position: relative;

  @include breakpoint($large-tablet) {
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
    margin-bottom: $spacing-unit--xl;
  }

  @include breakpoint($desktop) {
    align-items: center;
  }

  &__text--container {
    margin: auto;
    margin-top: -12rem;
    padding: $spacing-unit--l;
    position: relative;

    @include breakpoint($large-tablet) {
      height: 100%;
      margin: 0;
      padding: $spacing-unit--xxl 0 0;
      transform: translateY($spacing-unit--xl);
      width: 50%;
    }

    @include breakpoint($desktop) {
      padding: 0;
      transform: translateX(-#{$spacing-unit--xl}) translateY(-0);
    }
  }

  &__text {
    background-color: $white;
    padding: $spacing-unit--l;
    padding-left: $spacing-unit--xl;

    @include breakpoint($desktop) {
      padding-left: 8rem;
    }

    p {
      margin-top: 0;
    }
  }

  &__image {
    position: relative;

    &.large {
      bottom: 0;
      display: none;
      left: 0;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;

      @include breakpoint($large-tablet) {
        display: block;
      }

      @include breakpoint($desktop) {
        display: none;
      }

      img {
        height: auto;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        width: auto;

        @supports (object-fit: cover) {
          height: 100%;
          left: unset;
          object-fit: cover;
          position: relative;
          top: unset;
          transform: translate(0);
          width: 100%;
        }
      }
    }

    &.small {
      @include breakpoint($large-tablet) {
        display: none;
      }

      @include breakpoint($desktop) {
        display: block;
        position: relative;
        width: 50%;
      }
    }
  }

  &__blockquote {
    font-size: 1.6rem;
    margin: $spacing-unit--l 0;
    padding: 0;

    @include breakpoint($tablet) {
      font-size: 2.2rem;
    }

    @include breakpoint($desktop) {
      font-size: 3rem;
    }

    + cite {
      margin: -#{$spacing-unit} 0 $spacing-unit--l;
      padding-left: 0;
      position: relative;

      &:before {
        content: '-';

      }
    }
  }

  &__cta {
    align-items: center;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    margin: $spacing-unit;

    .button,
    .sharing {
      font-size: 1.4rem;
      margin-bottom: $spacing-unit;
    }

    @include breakpoint($tablet) {
      flex-flow: row wrap;
      justify-content: flex-start;
      margin: 0;

      .button,
      .sharing {
        font-size: 1.6rem;
        margin-bottom: $spacing-unit;
        margin-right: $spacing-unit;
      }
    }
  }

  &--homepage {
    @include breakpoint($large-tablet) {
      align-items: flex-end;
      margin-bottom: $spacing-unit;
    }

    #{$self} {
      &__image.large {
        @include breakpoint($large-tablet) {
          display: block;
          position: absolute;
          width: 100%;
        }
      }

      &__image.small {
        @include breakpoint($large-tablet) {
          display: none;
        }
      }

      &__text--container {
        @include breakpoint($desktop) {
          padding-top: 20vmin;
          position: relative;
          transform: translate(0);
        }
      }
    }
  }

  &--homepage-skin-2 {
    @include breakpoint($large-tablet $desktop--max) {
      margin-bottom: $spacing-unit--xl;
    }

    #{$self} {
      &__blockquote {
        @include breakpoint($desktop) {
          font-size: 2.2rem;
        }

        @include breakpoint($large-desktop) {
          font-size: 3rem;
        }
      }
      &__text--container {
        @include breakpoint($tablet) {
          padding-top: 0;
          position: relative;
          transform: translate(0);
        }

        @include breakpoint($large-tablet) {
          margin: 0;
          padding: $spacing-unit--xxl 0 0;
          transform: translateY($spacing-unit--xl);
          width: 50%;
        }

        @include breakpoint($desktop) {
          padding: 0;
          transform: translateX(-#{$spacing-unit--xl}) translateY(-0);
        }
      }
    }

    .blockquote__cite-name {
      font-weight: 500;

      .fonts-loaded & {
        font-family: $font-heading;
      }
    }
  }
}
