// =======================================
//    #UTILITY - MARGIN CONTROL
//
// Note: this keeps the margins consistent on the text elements between mobile and desktop.
// Adding a breakpoint will create unified control across breakpoints.
// =======================================
$spacing-unit: 1.5rem;
$spacing-unit--xxs: $spacing-unit / 8;
$spacing-unit--xs: $spacing-unit / 4;
$spacing-unit--sm: $spacing-unit / 3;
$spacing-unit--half: $spacing-unit / 2;
$spacing-unit--ss: $spacing-unit / 1.66; // ~ 10px
$spacing-unit--md: $spacing-unit * 1.33; // ~ 20px
$spacing-unit--mdl: $spacing-unit * 1.6; // ~ 24px
$spacing-unit--l: $spacing-unit * 2;
$spacing-unit--lml: $spacing-unit * 2.66; // ~ 40px
$spacing-unit--ll: $spacing-unit * 3;
$spacing-unit--xl: $spacing-unit * 4;
$spacing-unit--xll: $spacing-unit * 5; // 75px
$spacing-unit--xxl: $spacing-unit * 8;
$spacing-unit--xxxl: $spacing-unit * 10;
$mobileMargin: $spacing-unit;

@mixin margin-control($center: false, $override: false, $size: '', $type: '') {
  @if $type == 'padding' {
    @if $size == 'l' {
      padding-bottom: $spacing-unit--l;
      padding-top: $spacing-unit--l;
    }

    @else if $size == 'xl' {
      padding-bottom: $spacing-unit--xl;
      padding-top: $spacing-unit--xl;
    }

    @else if $size == 'xxl' {
      padding-bottom: $spacing-unit--xxl;
      padding-top: $spacing-unit--xxl;
    }

    @else {
      padding-bottom: $spacing-unit;
      padding-top: $spacing-unit;
    }
  }

  @else {
    @if $override == true {
      margin: 0 !important;
    }

    @else {
      @if $size == 'l' {
        @if $center == true {
          margin: $spacing-unit--l auto;
        }

        @else {
          margin: $spacing-unit--l 0;
        }
      }

      @else if $size == 'll' {
        @if $center == true {
          margin: $spacing-unit--ll auto;
        }

        @else {
          margin: $spacing-unit--ll 0;
        }
      }

      @else if $size == 'xl' {
        @if $center == true {
          margin: $spacing-unit--xl auto;
        }

        @else {
          margin: $spacing-unit--xl 0;
        }
      }

      @else if $size == 'half' {
        @if $center == true {
          margin: $spacing-unit--half auto;
        }

        @else {
          margin: $spacing-unit--half 0;
        }
      }

      @else if $size == 'md' {
        @if $center == true {
          margin: $spacing-unit--md auto;
        }

        @else {
          margin: $spacing-unit--md 0;
        }
      }

      @else if $size == 'sm' {
        @if $center == true {
          margin: $spacing-unit--sm auto;
        }

        @else {
          margin: $spacing-unit--sm 0;
        }
      }

      @else if $size == 'xs' {
        @if $center == true {
          margin: $spacing-unit--xs auto;
        }

        @else {
          margin: $spacing-unit--xs 0;
        }
      }

      @else if $size == 'xxs' {
        @if $center == true {
          margin: $spacing-unit--xxs auto;
        }

        @else {
          margin: $spacing-unit--xxs 0;
        }
      }

      @else {
        @if $center == true {
          margin: $spacing-unit auto;
        }

        @else {
          margin: $spacing-unit 0;
        }
      }
    }
  }
}
